/*
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */

import { Injectable } from '@angular/core';
import { HTTPStatus } from '../../../../../../ketos-lib/src/lib/services/http-status.service';
import html2canvas from 'html2canvas';

@Injectable({
  providedIn: 'root'
})
export class PreloaderService {
  container = document.body;
  element: HTMLElement = null;
  backdropElement: HTMLElement = null;  // Added to track the backdrop element
  trailingCount = 0;
  timeout: NodeJS.Timer = null;
  forceOverlay = false;

  constructor(private httpStatus: HTTPStatus) {
    // Default preloader behavior: No screen capture, with dark backdrop
    httpStatus.filteredCountSubject.subscribe(reqCount => {
      if (reqCount === this.trailingCount) {
        return;
      }
      if (reqCount > 0) {
        this.startPreloader();  // Use default preloader with dark backdrop
      } else {
        this.removeDelayed();
      }
      this.trailingCount = reqCount;
    }, error => {
      this.resetPreloader();
    });

    httpStatus.forceOverlaySubject.subscribe(force => {
      if (force === true) {
        this.forceOverlay = true;
        this.startPreloader();  // Use default preloader with dark backdrop
      } else {
        this.forceOverlay = false;
        this.removeDelayed();
      }
    });
  }

  // Added x and y optional parameters with default values 0
  show(useScreenCapture: boolean = false, x: number = 0, y: number = 0): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        if (useScreenCapture) {
          this.startPreloaderWithScreenCapture(resolve, x, y);  // Only use screen capture if true
        } else {
          this.startPreloader();  // Regular behavior with dark backdrop, no screen capture
          resolve();
        }
      } catch (error) {
        reject(error);
      }
    });
  }

  hide() {
    this.removePreloader();
  }

  private removeDelayed() {
    setTimeout(() => {
      if (!this.forceOverlay && this.trailingCount < 1) {
        this.removePreloader();
      }
    }, 200);
  }

  private resetPreloader() {
    this.removePreloader();
  }

  // Default preloader with dark backdrop (no screen capture)
  private startPreloader() {
    if (!this.element) {
      this.element = document.createElement('div');
      this.element.innerHTML = `
        <div class="wrapper">
          <div class="loader" title="Loading...">
            <div class="gif"></div>
          </div>
        </div>
      `;
      this.element.className = 'preloader';

      // Original styles for default behavior, including dark backdrop
      this.element.style.position = 'fixed';
      this.element.style.top = '0';
      this.element.style.left = '0';
      this.element.style.width = '100%';
      this.element.style.height = '100%';
      this.element.style.zIndex = '999999';  // Increased z-index to ensure it stays on top
      this.element.style.opacity = '1';  // Ensure full opacity

      // Dark backdrop for default behavior
      this.element.style.backgroundColor = 'rgba(0, 0, 0, 0.6)';  // Dark backdrop in the default behavior

      this.container.appendChild(this.element);
    }

    // Clear existing timeout if any
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }

    // Set a timeout to automatically remove the preloader after 100 seconds
    this.timeout = setTimeout(() => {
      this.removePreloader();
    }, 100 * 1000);
  }

  // Screen capture feature only used when show(true) is called
  private startPreloaderWithScreenCapture(resolve: () => void, x: number = 0, y: number = 0) {
    if (!this.element) {
      this.element = document.createElement('div');
      this.element.innerHTML = `
      <div class="wrapper">
        <div class="loader" title="Loading...">
          <div class="gif"></div>
        </div>
      </div>
    `;
      this.element.className = 'preloader';

      // Set position and size to cover the full window
      this.element.style.position = 'fixed';
      this.element.style.top = '0';  // Full window backdrop, start from 0
      this.element.style.left = '0';  // Full window backdrop, start from 0
      this.element.style.width = '100%';  // Cover the entire window width
      this.element.style.height = '100%';  // Cover the entire window height
      this.element.style.zIndex = '999999';  // Increased z-index to ensure it stays on top
      this.element.style.opacity = '1';  // Ensure full opacity
      this.element.style.backgroundColor = 'transparent';  // Transparent background before image

      const pixelRatio = window.devicePixelRatio || 1;  // Get device pixel ratio for Retina displays

      // Capture screen with html2canvas
      html2canvas(document.body, {
        backgroundColor: '#ffffff',  // Ensure solid white background
        scale: pixelRatio,  // Adjust the canvas scale for the device pixel ratio
        useCORS: true,  // Handle external resources like fonts, images, etc.
      }).then(canvas => {
        const trimmedCanvas = document.createElement('canvas');
        const trimmedCtx = trimmedCanvas.getContext('2d');

        // Set the trimmed canvas size, accounting for pixel ratio
        trimmedCanvas.width = canvas.width / pixelRatio;
        trimmedCanvas.height = canvas.height / pixelRatio;

        // Draw the cropped and shifted image into the new canvas
        trimmedCtx.fillStyle = '#ffffff';  // Set a solid white background
        trimmedCtx.fillRect(0, 0, trimmedCanvas.width, trimmedCanvas.height);  // Fill canvas with white

        trimmedCtx.drawImage(canvas, x * pixelRatio, y * pixelRatio, canvas.width - x * pixelRatio, canvas.height - y * pixelRatio, 0, 0, trimmedCanvas.width - x, trimmedCanvas.height - y);

        // Convert the trimmed canvas to an image
        const imgData = trimmedCanvas.toDataURL('image/png', 1.0);  // Full quality, no compression

        // Apply the captured image and dark overlay (matching default backdrop color)
        this.element.style.backgroundImage = `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${imgData})`;

        // Set background size to "contain" to avoid shrinking or margins
        this.element.style.backgroundSize = 'cover';  // Ensure the image fits without margins
        this.element.style.backgroundPosition = `${x}px ${y}px`;  // Position the image at x, y
        this.element.style.backgroundRepeat = 'no-repeat';  // No repeat for the image

        // Add a separate backdrop for the left and top areas that are outside the image
        this.backdropElement = document.createElement('div');
        this.backdropElement.style.position = 'fixed';
        this.backdropElement.style.top = '0';
        this.backdropElement.style.left = '0';
        this.backdropElement.style.width = '100%';
        this.backdropElement.style.height = '100%';
        this.backdropElement.style.backgroundColor = 'rgba(0, 0, 0, 0.6)';
        this.backdropElement.style.zIndex = '999998';  // Increased z-index to ensure it stays behind the element

        this.container.appendChild(this.backdropElement);  // Append the extra backdrop

        this.container.appendChild(this.element);

        resolve();  // Resolve the promise when the screenshot is done
      }).catch(error => {
        console.error('Screenshot capture failed:', error);
        resolve();  // Even if the screenshot fails, resolve to avoid hanging
      });
    }

    // Clear existing timeout if any
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }

    // Set a timeout to automatically remove the preloader after 100 seconds
    this.timeout = setTimeout(() => {
      this.removePreloader();
    }, 100 * 1000);
  }

  private removePreloader() {
    if (this.element) {
      this.container.removeChild(this.element);
      this.element = null;
    }

    // Remove the backdrop element if it exists
    if (this.backdropElement) {
      this.container.removeChild(this.backdropElement);
      this.backdropElement = null;
    }

    // Clear the timeout if the preloader is manually hidden
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
  }
}
