import {Component, Input, OnInit} from '@angular/core';
import {map_styles} from '../../../../../../shared/map-styles';
import {MarkerInterface} from '../../../components/all-sensors-map/all-sensors-map.component';
import {GoogleMapsService} from '../../../../../../web/src/app/core/api/services/google-maps.service';

@Component({
  selector: 'lib-sensor-map',
  templateUrl: './sensor-map.component.html',
  styleUrls: ['./sensor-map.component.scss'],
})
export class SensorMapComponent implements OnInit {

  @Input() markers: MarkerInterface[] = [];
  @Input() initialFit;
  isMapLoaded = false

  constructor(private googleMapsService: GoogleMapsService) { }

  async ngOnInit() {
    await this.googleMapsService.initMap();
    setTimeout(() => { this.isMapLoaded = true; }, 100);
    console.log('this;', this.markers);
  }

  onMapLoad(map: google.maps.Map){
    map.setOptions({ styles: map_styles });
  }
  
  markerClick(marker: MarkerInterface) {
    console.log('marker click', marker);
  }

  loadMarkers(color) {
    return 'assets/modeller/markers/' + color + '/dot_10.png';
  }

}
