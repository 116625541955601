import {MenuItem} from '../../components/left-menu/left-menu.component';
import {DashboardButtonInterface} from '../../components/sensor-tabs/sensor-tabs.component';
import {DashboardShieldGuard} from '../../guards/dashboard-shield.guard';

export const dashboardMenuWave: MenuItem[] = [
  {
    title: 'Real Time Data',
    icon: 'real-time-data.svg',
    route: '/dashboard/real-time-data',
    visibility: 'true'
  },
  {
    title: 'Graphical View',
    icon: 'graphical-dashboard.svg',
    route: '/dashboard/graphical-view',
    visibility: 'true'
  },
  {
    title: 'Alerts',
    icon: 'notifications-outline.svg',
    route: '/dashboard/alerts',
    visibility: 'true'
  },
  {
    title: 'Predictive Maintenance',
    icon: 'pred-maintenance.svg',
    route: '/dashboard/predictive-maintenance',
    visibility: 'true'
  },
  {
    title: 'Sustainability',
    icon: 'sustainability.svg',
    route: '/dashboard/sustainability',
    visibility: 'true'
  },
  {
    title: 'Grid Management',
    icon: 'grid.svg',
    route: '/dashboard/grid-management',
    visibility: 'true'
  },
  {
    title: 'Consumer Behavior',
    icon: 'consumer.svg',
    route: '/dashboard/consumer-behavior',
    visibility: 'true'
  },
  {
    title: 'Resource Efficiency',
    icon: 'resource-eff.svg',
    route: '/dashboard/resource-efficiency',
    visibility: 'true'
  },
  {
    title: 'Usage Billing',
    icon: 'report-dashboard.svg',
    route: '/dashboard/usage-billing',
    visibility: 'true'
  },
  {
    title: 'Reports',
    icon: 'report-dashboard.svg',
    route: '/dashboard/reporting',
    visibility: 'true'
  }
];

export const dashboardMenuIceberg: MenuItem[] = [
  {
    title: 'Real Time Data',
    icon: 'real-time-data.svg',
    route: '/dashboard/real-time-data',
    visibility: 'true'
  },
  {
    title: 'Graphical View',
    icon: 'bar-chart-outline.svg',
    route: '/dashboard/graphical-view',
    visibility: 'true'
  },
  {
    title: 'Data Correlation',
    icon: 'chart-double.svg',
    route: '/dashboard/dispense-rate',
    visibility: 'true'
  },
  {
    title: 'Alerts',
    icon: 'notifications-outline.svg',
    route: '/dashboard/alerts',
    visibility: 'true'

  },
  {
    title: 'Sensors Health',
    icon: 'pressure.svg',
    route: '/dashboard/sensors-health',
    visibility: 'true',
    disabled: true
  },
  {
    title: 'Map',
    icon: 'map.svg',
    route: '/dashboard/map',
    visibility: 'true'
  },
  {
    title: 'Behavioral Analytics',
    icon: 'consumer.svg',
    route: '/dashboard/behavioral-analytics',
    visibility: 'true'
  },
  {
    title: 'Savings',
    icon: 'savings.svg',
    route: '/dashboard/savings',
    visibility: 'true',
    // disabled: true
  },
  {
    title: 'Reports',
    icon: 'report-dashboard.svg',
    route: '/dashboard/reporting',
    visibility: 'true',
    disabled: true
  }
];

export const dashboardMenuShield = [
  {
    title: 'Insights',
    icon: 'insights.svg',
    route: '/dashboard/insights',
    visibility: 'true'
  },
  {
    title: 'Real Time Data',
    icon: 'real-time-data.svg',
    route: '/dashboard/real-time-data',
    visibility: 'true'
  },
  {
    title: 'Graphical View',
    icon: 'graphical-dashboard.svg',
    route: '/dashboard/graphical-view',
    visibility: 'true',
    isPremium: true
  },
  {
    title: 'External Lab Data',
    icon: 'flask-outline.svg',
    route: '/dashboard/lab-data',
    visibility: 'true',
    disabled: true,
  },
  {
    title: 'Data Correlation',
    icon: 'chart-double.svg',
    route: '/dashboard/data-correlation',
    visibility: 'true'
  },
  {
    title: 'Alerts',
    icon: 'notifications-outline.svg',
    route: '/dashboard/alerts',
    visibility: 'true'
  },
  {
    title: 'Start/Schedule Test',
    icon: 'play-circle-outline.svg',
    route: '/dashboard/start-schedule-test',
    visibility: 'true'
  },
  // {
  //   title: 'System Data',
  //   icon: 'system-data-icon',
  //   route: '/dashboard/system-data',
  //   visibility:'true'
  // },
  {
    title: 'Regional Water Safety',
    icon: 'planet-earth.svg',
    route: '/dashboard/water-safety',
    visibility: 'true'
  },
  {
    title: 'Savings',
    icon: 'savings.svg',
    route: '/dashboard/savings',
    visibility: 'true',

  },
  // {
  //   title: 'Early Warning',
  //   icon: 'maintenance-icon',
  //   route: '/dashboard/early-warning',
  //   disabled: true
  // },
  {
    title: 'Reports',
    icon: 'report-dashboard.svg',
    route: '/dashboard/reporting',
    visibility: 'true'
  }
];


// mobile left menu
export const mobileLeftMenu = [
  {
    title: 'Summary',
    canActivate: [DashboardShieldGuard],
    url: '/dashboard/summary',
    visibility: true,
    icon: 'home'
  },
  {
    title: 'Real Time Data',
    canActivate: [DashboardShieldGuard],
    url: '/dashboard/real-time-data',
    visibility: true,
    icon: 'pulse'
  },
  {
    title: 'Graphical View',
    canActivate: [DashboardShieldGuard],
    url: '/dashboard/graphical-view',
    visibility: true,
    icon: 'stats-chart'
  },
  {
    title: 'Start/Schedule Test',
    canActivate: [DashboardShieldGuard],
    url: '/dashboard/start-schedule-test',
    visibility: true,
    icon: 'play-circle'
  },
  {
    title: 'External Lab Data',
    canActivate: [DashboardShieldGuard],
    url: '/dashboard/lab-data',
    icon: 'pulse'
  },
  {
    title: 'Data Correlation',
    canActivate: [DashboardShieldGuard],
    url: '/dashboard/data-correlation',
    visibility: true,
    icon: 'stats-chart'
  },
  {
    title: 'Regional Water Safety',
    canActivate: [DashboardShieldGuard],
    url: '/dashboard/water-safety',
    visibility: true,
    icon: 'water'
  },
  {
    title: 'Savings',
    canActivate: [DashboardShieldGuard],
    url: '/dashboard/savings',
    visibility: true,
    icon: 'cash'
  },
  {
    title: 'Reports',
    canActivate: [DashboardShieldGuard],
    url: '/dashboard/reporting',
    visibility: true,
    icon: 'copy'
  },
];

// mobile right menu
export const mobileRighttMenu = [
  {
    title: 'Profile',
    url: '/profile',
    visibility: true,
    icon: 'person'
  },
  {
    title: 'Manage Users',
    url: '/users',
    visibility: false,
    icon: 'people'
  },
  {
    title: 'Unit Settings',
    url: '/unit-settings',
    visibility: true,
    icon: 'briefcase'
  },
  {
    title: 'About',
    url: '/about',
    visibility: true,
    icon: 'bookmark'
  },
  {
    title: 'Support',
    url: '/support',
    visibility: true,
    icon: 'call'
  }
];

export const segmentButtons: DashboardButtonInterface[] = [
  {icon: 'clipboard', name: 'summary', title: 'Summary', pullDown: false},
  {icon: 'pulse', name: 'real-time-data', title: 'Real Time Data', pullDown: false},
  {icon: 'stats-chart', name: 'graphical-view', title: 'Graphical View', pullDown: false},
  {icon: 'play-circle', name: 'start-schedule-test', title: 'Start/Schedule Test', pullDown: false},
  {icon: 'pulse', name: 'lab-data', title: 'External Lab Data', pullDown: false},
  // {icon: 'information', name: 'system-data', title: 'System Data', pullDown: false},
  {icon: 'stats-chart', name: 'data-correlation', title: 'Data Correlation', pullDown: false},
  {icon: 'water-outline', name: 'water-safety', title: 'Regional Water Safety', pullDown: false},
  {icon: 'cash-outline', name: 'savings', title: 'Savings', pullDown: false}, // document
  {icon: 'copy', name: 'reporting', title: 'Reports', pullDown: false}, // document
];
