/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiInternalConfiguration as __Configuration } from '../api-internal-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Versioned_experiment_configuration_simple } from '../models/versioned-_experiment-_configuration-_simple';
import { Versioned_experiment_configuration } from '../models/versioned-_experiment-_configuration';

/**
 * Exp values
 */
@Injectable({
  providedIn: 'root',
})
class ExpValuesService extends __BaseService {
  static readonly getCalculateExperimentTimesPath = '/exp_values/calculate_experiment_times/{sensor_id}';
  static readonly getClearExperimentTimesPath = '/exp_values/clear_experiment_times';
  static readonly getGetExperimentTimesPath = '/exp_values/get_experiment_times/{sensor_id}';
  static readonly getExpValuesPath = '/exp_values/{sensor_id}/{exp_number}/{version}';
  static readonly postExpValuesPath = '/exp_values/{sensor_id}/{exp_number}/{version}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param sensor_id undefined
   */
  getCalculateExperimentTimesResponse(sensorId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/exp_values/calculate_experiment_times/${encodeURIComponent(String(sensorId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param sensor_id undefined
   */
  getCalculateExperimentTimes(sensorId: string): __Observable<null> {
    return this.getCalculateExperimentTimesResponse(sensorId).pipe(
      __map(_r => _r.body as null)
    );
  }
  getClearExperimentTimesResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/exp_values/clear_experiment_times`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  getClearExperimentTimes(): __Observable<null> {
    return this.getClearExperimentTimesResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param sensor_id undefined
   */
  getGetExperimentTimesResponse(sensorId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/exp_values/get_experiment_times/${encodeURIComponent(String(sensorId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param sensor_id undefined
   */
  getGetExperimentTimes(sensorId: string): __Observable<null> {
    return this.getGetExperimentTimesResponse(sensorId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ExpValuesService.GetExpValuesParams` containing the following parameters:
   *
   * - `sensor_id`:
   *
   * - `exp_number`:
   *
   * - `version`:
   *
   * @return Success
   */
  getExpValuesResponse(params: ExpValuesService.GetExpValuesParams): __Observable<__StrictHttpResponse<Versioned_experiment_configuration_simple>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/exp_values/${encodeURIComponent(String(params.sensorId))}/${encodeURIComponent(String(params.expNumber))}/${encodeURIComponent(String(params.version))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Versioned_experiment_configuration_simple>;
      })
    );
  }
  /**
   * @param params The `ExpValuesService.GetExpValuesParams` containing the following parameters:
   *
   * - `sensor_id`:
   *
   * - `exp_number`:
   *
   * - `version`:
   *
   * @return Success
   */
  getExpValues(params: ExpValuesService.GetExpValuesParams): __Observable<Versioned_experiment_configuration_simple> {
    return this.getExpValuesResponse(params).pipe(
      __map(_r => _r.body as Versioned_experiment_configuration_simple)
    );
  }

  /**
   * @param params The `ExpValuesService.PostExpValuesParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `sensor_id`:
   *
   * - `exp_number`:
   *
   * - `version`:
   */
  postExpValuesResponse(params: ExpValuesService.PostExpValuesParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;



    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/exp_values/${encodeURIComponent(String(params.sensorId))}/${encodeURIComponent(String(params.expNumber))}/${encodeURIComponent(String(params.version))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ExpValuesService.PostExpValuesParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `sensor_id`:
   *
   * - `exp_number`:
   *
   * - `version`:
   */
  postExpValues(params: ExpValuesService.PostExpValuesParams): __Observable<null> {
    return this.postExpValuesResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ExpValuesService {

  /**
   * Parameters for getExpValues
   */
  export interface GetExpValuesParams {
    sensorId: string;
    expNumber: string;
    version: string;
  }

  /**
   * Parameters for postExpValues
   */
  export interface PostExpValuesParams {
    payload: Versioned_experiment_configuration;
    sensorId: string;
    expNumber: string;
    version: string;
  }
}

export { ExpValuesService }
