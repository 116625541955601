import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {KetosGraphBase, KetosGraphDataInterface, KetosGraphSeriesSettingsInterface} from '../ketos-graph-base';
import {AppStateService, TimeIntervalInterface} from '../../../services/app-state.service';
import * as Highcharts from 'highcharts';
import * as moment from 'moment';
import * as cloneDeep from 'lodash/cloneDeep';
import {ExperimentConfigurationsService} from '../../../services/experiment-configurations.service';
import {ResultsService} from '../../../../../../api/src/lib/services/results.service';
import { SeriesLollipopOptions, SeriesSplineOptions, XAxisPlotBandsOptions } from 'highcharts';

import {formatNumber} from '@angular/common';

interface OptionsInterface extends Omit<Highcharts.Options, 'series'> {
  series: Array<SeriesLollipopOptions> | Array<SeriesSplineOptions>;
}

const HighchartsMore = require('highcharts/highcharts-more.src');
HighchartsMore(Highcharts);
const HC_solid_gauge = require('highcharts/modules/solid-gauge.src');
HC_solid_gauge(Highcharts);

const HighChartsDumbbell = require('highcharts/modules/dumbbell');
HighChartsDumbbell(Highcharts);


const HighChartsLollipop = require('highcharts/modules/lollipop');
HighChartsLollipop(Highcharts);

const NoDataDisplay = require('highcharts/modules/no-data-to-display');
NoDataDisplay(Highcharts);

import HC_exporting from 'highcharts/modules/exporting';
import HC_exportData from 'highcharts/modules/export-data';
import {OrganizationSettingsService} from '../../../services/organization-settings.service';
import {UnitConverter} from '../../../utils/UnitConverter';
import {UiPlatformService} from '../../../services/ui-platform.service';
import {FormatResultValuePipe} from '../../../pipes/format-result-value.pipe';
import { FormatDisplayNumberPipe } from '../../../pipes/format-display-number.pipe';
import {UserSettingsCachedService} from '../../../services/user-settings-cached.service';
import {WaterSourcesCachedService} from '../../../services/water-sources-cached.service';
import { GraphOptionsInterface } from '../ketos-graph.page';
import {KetosPopupService} from "../../../services/ketos-popup.service";
import {Aggregate_results} from "../../../../../../api/src/lib/models/aggregate-_results";
import { Subscription } from 'rxjs';
HC_exporting(Highcharts);
HC_exportData(Highcharts);

Highcharts['seriesTypes'].lollipop.prototype.drawLegendSymbol =
  Highcharts['seriesTypes'].line.prototype.drawLegendSymbol;
Highcharts.addEvent(Highcharts.Series, 'afterInit', function () {
  this['symbolUnicode'] = {
    circle: '●',
    diamond: '♦',
    square: '■',
    triangle: '▲',
    'triangle-down': '▼'
  }[this['symbol']] || '●';
});

@Component({
  selector: 'lib-ketos-all-data-chart',
  templateUrl: './ketos-all-data-chart.component.html',
  styleUrls: ['./ketos-all-data-chart.component.scss'],
})
export class KetosAllDataChartComponent extends KetosGraphBase implements OnInit {
  @ViewChild('chart', {static: false}) chart;
  chartInstance: Highcharts.Chart;

  @Input() mobileDesign: boolean;
  @Input() allExperiments: string[];

  private _multiGraph: boolean;
  @Input() set multiGraph(value: boolean) {
    this._multiGraph = value;
    if (this.chartOptions) {
      this.chartOptions.exporting.filename = `Ketos-Shield-Data-` + moment().utc(false).format('YYYY-MM-DD');
      this.updateChartFlag = true;
    }
  }

  get multiGraph(): boolean {
    return this._multiGraph;
  }

  _selectedGraphOptions: GraphOptionsInterface;
  @Input() set selectedGraphOptions(value: GraphOptionsInterface) {
    if (value?.live !== this._selectedGraphOptions?.live) {
        super.setLiveUpdate();
        this.updateChartFlag = true;
    }
    if (this.chartOptions) {
      if (value && this._ketosGraphData && this._selectedGraphOptions &&
        value.seperateYaxis !== this._selectedGraphOptions.seperateYaxis ||
        value?.pointSize !== this._selectedGraphOptions?.pointSize ||
        value.connectingLine !== this._selectedGraphOptions.connectingLine ||
        value.title !== this._selectedGraphOptions.title
      ) {
        this._selectedGraphOptions = cloneDeep(value);
        this.ketosGraphData = this._ketosGraphData;
      } else {
        if (value && value.zoomDisabled !== this._selectedGraphOptions?.zoomDisabled) {
          super.setGraphZoom(this.chartOptions, this.multiGraph, value);
          this.updateChartFlag = true;
        }

       // if(value?.title){
         this.setTitle(value?.title)
         this.updateChartFlag=true;
        //}
        if (Array.isArray(this.chartOptions.yAxis) && this.setYaxisMinMaxFromOptions(value, this.chartOptions.yAxis[0])) {
          this.updateChartFlag = true;
        }
        if (value.ymax !== this._selectedGraphOptions.ymax) {
          this.chartInstance.yAxis[0].setExtremes(value.ymin, value.ymax, true);
        }
      }
    }
    if (!value) {
      return;
    }
    this._selectedGraphOptions = cloneDeep(value);
  }

  get selectedGraphOptions(): GraphOptionsInterface {
    return this._selectedGraphOptions;
  }

  // this.chartInstance?.setSize(value, this._height || 600, false);

  @Input() set widthChanged(value: boolean) {
    this.chartInstance?.reflow();
  }

  @Input() set heightChanged(value: boolean) {
    this.chartInstance?.reflow();
  }

  @Input() set reflowGraph(value: boolean) {
    this.chartInstance?.reflow();
  }

  _ketosGraphData: KetosGraphDataInterface;
  @Input() set ketosGraphData(value: KetosGraphDataInterface) {
    this._ketosGraphData = value;

     // console.log('set time series data:', value, this.chartInstance);
    this.setResultsData(value);
    this.showSeriesThresholdsBds(value);
    if(!this.selectedGraphOptions?.emitSvgOnRedraw) {
      this.setXaxisDate(value.dateInterval);
    }
    super.setNoDataText(value.noDataText);
    this.updateChartFlag = true;

  }

  get ketosGraphData(): KetosGraphDataInterface {
    return this._ketosGraphData;
  }

  _showZoomButton: boolean;
  @Input() set showZoomButton(value: boolean) {
    this._showZoomButton = value;
    if (value === true) {
      // setTimeout((test) => {
      //   // this.chart?.chart?.showResetZoom();
      // }, 1000);
    }
  }

  get showZoomButton(): boolean {
    return this._showZoomButton;
  }
  @Output() liveUpdate: EventEmitter<TimeIntervalInterface> = new EventEmitter<TimeIntervalInterface>();

  @Output() zoomed: EventEmitter<TimeIntervalInterface> = new EventEmitter<TimeIntervalInterface>();

  @Output() chartSVG: EventEmitter<Object> = new EventEmitter<Object>();
  ///////////////////////////////////////////////////////////
  hideYAxis = false;

  initDone = false;
  highcharts = Highcharts;
  chartOptions: OptionsInterface;
  updateChartFlag=false;
  private hasResized: boolean = false; // A flag to track if resize has occurred
  private subscriptions: Subscription = new Subscription();
  zoomedMin;
  zoomedMax;
  constructor(public appStateService: AppStateService,
              public experimentConfigurationsService: ExperimentConfigurationsService,
              public resultsService: ResultsService,
              public orgSettingsService: OrganizationSettingsService,
              public unitSettingsService: UserSettingsCachedService,
              public uiPlatformService: UiPlatformService,
              public formatResultValuePipe: FormatResultValuePipe,
              public formatDisplayNumberPipe: FormatDisplayNumberPipe,
              public waterSourcesCachedService: WaterSourcesCachedService,
              public ketosPopupService: KetosPopupService,
  ) {
    super();
  }

  ngOnInit() {
    this.createChart('initial', this.multiGraph, this.selectedGraphOptions);

    // Subscribe to the menuToggle$ observable
    const menuToggleSubscription = this.appStateService.menuToggle$.subscribe(() => {
      setTimeout(() => {
        if (this.chartInstance && Object.keys(this.chartInstance).length > 0) {
          this.chartInstance?.reflow(); // This is a hack to force the chart to redraw
        }
      }, 500);
    });

    // Add the subscription to the subscriptions object
    this.subscriptions.add(menuToggleSubscription);
  
    document.addEventListener('visibilitychange', this.visibilityChangeHandler, false);
  }

  visibilityChangeHandler = () => {
    if (document.visibilityState === 'visible' && !this.hasResized) {
      this.resizeChart();
      this.hasResized = true;
      document.removeEventListener('visibilitychange', this.visibilityChangeHandler);
    }
  };

  resizeChart() {
    setTimeout(() => {
      Highcharts.charts.forEach(chart => {
        if (chart) {
          chart.reflow(); // Reflow forces the chart to resize based on the container
        }
      });
    }, 100); // Slight delay to ensure DOM elements are fully rendered
  }

  setTitle(val){
    this.chartOptions.title.text = val;
    if(this.selectedGraphOptions?.leftAlignedTitle) {
      this.chartOptions.title.align = 'left';
      this.chartOptions.title.style.color = '#ffffff';
      this.chartOptions.title.style.fontSize = '26px';
      this.chartOptions.title.style.fontWeight = '300';
      this.chartOptions.title.x = 60;
      this.chartOptions.title.y = 28;
      this.chartOptions.title.margin = 0;
    }
  }
  setXaxisDate(dateInterval: any,checkZoomed:boolean=false){
    if(dateInterval && !this.zoomedMin && !this.zoomedMax){
    this.chartOptions.xAxis[0].title.text = `Date, ${moment(dateInterval.min.getTime()).format('MM/DD/YYYY')} - ${moment(dateInterval.max.getTime()).format('MM/DD/YYYY')} `
    }else{
      this.chartOptions.xAxis[0].title.text = `Date, ${moment(this.zoomedMin).format('MM/DD/YYYY')} - ${moment(this.zoomedMax).format('MM/DD/YYYY')} `

    }
    if(checkZoomed){
      this.updateChartFlag=true;
    }
  }

  setResultsData(timeSeriesData: KetosGraphDataInterface) {
    if (!this.chartOptions) {
      this.createChart(timeSeriesData.seriesSettings?.map(se => {
        se.locationName;
      }).join('_'), this.multiGraph, this.selectedGraphOptions);
    } else {
      super.setGraphZoom(this.chartOptions, this.multiGraph, this.selectedGraphOptions);
    }

    if (this.chartOptions ) {
      this.chartOptions.title.text = this.selectedGraphOptions?.title || '';
    }
    if (this.selectedGraphOptions?.seperateYaxis === true) {
      this.chartOptions.yAxis = [];
    } else {
      this.chartOptions.yAxis = [this.createYAxis(!this.hideYAxis)];
      this.chartOptions.yAxis[0].plotLines = [];
      this.chartOptions.yAxis[0].title.text = '';
    }
    const resultsByWaterSourceIdAndExperimentDict: { [key: string]: any[] } = {};
    const waterSourcesIdsDict: { [key: number]: boolean } = {};
    const methodsByWaterSourceIdAndExperimentDict: { [key: string]: string[] } = {};
    timeSeriesData.seriesSettings?.map(se => {
      resultsByWaterSourceIdAndExperimentDict[se.waterSourceId.toString() + se.experimentKey] = [];
      methodsByWaterSourceIdAndExperimentDict[se.waterSourceId.toString() + se.experimentKey] = [];
      waterSourcesIdsDict[se.waterSourceId] = true;
    });
    let hardYMin = this._selectedGraphOptions?.ymin || 0;
    let hardYMax = this._selectedGraphOptions?.ymax || 0;
    if (timeSeriesData.results) {
      timeSeriesData.results.items.map(result => {
        let value = UnitConverter.convertFromConfDefaultToUnitSettingsDictUnit(result.value, this.experimentConfigurationsService.getConfByExperimentMethodBitmap(result.experiment, result.method), this.unitSettingsService.selectedUnitsDict);
        const wsIdExpKey = result.water_source_id.toString() + result.experiment;
        const resultObj = {
          x: moment.utc(result.timestamp).toDate(),
          y: value,
          title: result.shield_test_schedule?.title
        };
        if (value < hardYMin) {
          hardYMin = value;
        }
        if (value > hardYMax) {
          hardYMax = value;
        }

        resultsByWaterSourceIdAndExperimentDict[wsIdExpKey]?.push(resultObj);
        if (methodsByWaterSourceIdAndExperimentDict[wsIdExpKey]?.indexOf(result.method) === -1) {
          methodsByWaterSourceIdAndExperimentDict[wsIdExpKey].push(result.method);
        }
      });
    } else if (timeSeriesData.histograms) {
      const bucketLengthInSeconds = this.getHistogramsBucketLengthInSeconds(timeSeriesData.histograms);

      for (const wsId of Object.keys(timeSeriesData.histograms)){
        for (const histogram of timeSeriesData.histograms[wsId]) {
          const wsIdExpKey = wsId.toString() + histogram.experiment;
          let lastIsNull = false;
          histogram.items.map( aggr => {
            const time = moment.utc(parseInt(aggr.key)).local().toDate();
            if (aggr.doc_count > 0) {
              let value = UnitConverter.convertFromConfDefaultToUnitSettingsDictUnit(aggr.avg_value.value, this.experimentConfigurationsService.getConfByExperimentMethodBitmap(histogram.experiment), this.unitSettingsService.selectedUnitsDict);
              if (aggr.doc_count === 1) {
                // const resultObj = {x: moment.utc(aggr.key_as_string).toDate(), y: value};
                resultsByWaterSourceIdAndExperimentDict[wsIdExpKey]?.push({x: time, y: value});
                // if (methodsByWaterSourceIdAndExperimentDict[wsIdExpKey]?.indexOf(result.method) === -1) {
                //   methodsByWaterSourceIdAndExperimentDict[wsIdExpKey].push(result.method);
                // }

                if (value < hardYMin) {
                  hardYMin = value;
                }
                if (value > hardYMax) {
                  hardYMax = value;
                }

              } else {
                let minVal = UnitConverter.convertFromConfDefaultToUnitSettingsDictUnit(aggr.min_value.value, this.experimentConfigurationsService.getConfByExperimentMethodBitmap(histogram.experiment), this.unitSettingsService.selectedUnitsDict);
                let maxVal = UnitConverter.convertFromConfDefaultToUnitSettingsDictUnit(aggr.max_value.value, this.experimentConfigurationsService.getConfByExperimentMethodBitmap(histogram.experiment), this.unitSettingsService.selectedUnitsDict);

                resultsByWaterSourceIdAndExperimentDict[wsIdExpKey]?.push({x: time, y: value, custom: {min: minVal, max: maxVal, count: aggr.doc_count, seconds: bucketLengthInSeconds}});
                resultsByWaterSourceIdAndExperimentDict[wsIdExpKey]?.push({x: time, y: maxVal, marker: {enabled: false}});
                resultsByWaterSourceIdAndExperimentDict[wsIdExpKey]?.push({x: time, y: minVal, marker: {enabled: false}});
                resultsByWaterSourceIdAndExperimentDict[wsIdExpKey]?.push({x: time, y: value, marker: {enabled: false}});

                if (minVal < hardYMin) {
                  hardYMin = minVal;
                }
                if (maxVal > hardYMax) {
                  hardYMax = maxVal;
                }
              }
              lastIsNull = false;
            } else {
              if (!lastIsNull) {
                resultsByWaterSourceIdAndExperimentDict[wsIdExpKey]?.push({x: time, y: null});
                lastIsNull = true;
              }
            }
          });
        }
      }
    }

    const showSensorName = Object.keys(waterSourcesIdsDict).length > 1;
    this.chartOptions.xAxis[1].visible = false;
    this.chartOptions.xAxis[0].visible = true;

    // set data or hide
    this.chartOptions.series = [];
    // We decided that up to four experiments can be plotted on a chart
    // SW-7879 After each series, check for a forecast
    let forecastSeries = [];
    for (let i = 0; i < 4; i++) {
      if (timeSeriesData.seriesSettings && timeSeriesData.seriesSettings[i]) {
        const seriesSetting = timeSeriesData.seriesSettings[i];

        const conf = this.experimentConfigurationsService.getConfByExperimentMethodBitmap(seriesSetting.experimentKey, null, seriesSetting.sensorBitmap);
        const unit = UnitConverter.getTargetUnitFromConfAndUnitsDict(conf, this.unitSettingsService.selectedUnitsDict);
        // const seriesData = [];
        let marker;
        if (timeSeriesData.histograms) {
            marker = {
              enabled: true,
              radius: this.selectedGraphOptions?.pointSize || 2
            };
        }
        this.chartOptions.series[i] = this.createDatapointSeries(i, 'series' + i, conf.key, seriesSetting.seriesTypeOverride, seriesSetting.sensorBitmap, this.selectedGraphOptions?.connectingLine, marker);
        // this.chartOptions.series[i] = this.createDatapointSeries(i, 'series' + i, conf.key, 'line', seriesSetting.sensorBitmap);
        this.chartOptions.series[i].custom.methods = methodsByWaterSourceIdAndExperimentDict[seriesSetting.waterSourceId.toString() + seriesSetting.experimentKey];
        this.chartOptions.series[i].custom.hardYMin = hardYMin;
        this.chartOptions.series[i].custom.hardYMax = hardYMax;
        this.chartOptions.series[i].data = resultsByWaterSourceIdAndExperimentDict[seriesSetting.waterSourceId.toString() + seriesSetting.experimentKey];

        // This is the title for the legend and the tooltips
        let seriesName = conf?.title;
        if (showSensorName) {
          seriesName = seriesSetting.locationName + ' ' + seriesName;
        }
        if (this.selectedGraphOptions?.leftAlignedTitle){
          seriesName = seriesSetting.locationName;
        }

        // SW-7879 Add forecast arearange series
        this.chartOptions.xAxis[0].plotLines = [];  // Clear the plotLines in case of switching forecast off
        this.chartOptions.xAxis[0].plotBands = [];  // Clear the plotBands in case of switching forecast off

        if (this.ketosGraphData.forecastEnabled) {
          // SW-7879 Get matching forecast for experiment above (Use seriesSetting.experimentKey)
          let matchingForecast;
          if (this._ketosGraphData?.forecast?.items) {
            for (const item of this._ketosGraphData?.forecast?.items) {
              if (item.experiment === seriesSetting.experimentKey && item.water_source_id === seriesSetting.waterSourceId) {
                matchingForecast = item;
              }
            }
          }

          // SW-7879 Skip forecast if not found
          if (matchingForecast) {
            // SW-7879 Create dual series for area range with averages
            const ranges = [];
            const averages = [];
            for (const item of matchingForecast.items) {
              // SW-7879 Get name for title from key and mention forecast
              ranges.push({
                x: moment.utc(item.timestamp).toDate(),
                low: item.forecast_lower,
                high: item.forecast_upper
              });
              averages.push({
                x: moment.utc(item.timestamp).toDate(),
                y: item.forecast_mean
              });
            }

            const areaRangeSeries = this.createAreaRangeDatapointSeries(i, 'series' + i, conf.key, seriesSetting.sensorBitmap, averages, ranges, `${seriesName} forecast`);
            forecastSeries = [...forecastSeries, ...areaRangeSeries];
          }
        } else {
          // console.log('SW-7879 - forecast is not enabled');
        }

        this.chartOptions.series[i].name = seriesName;
        this.chartOptions.series[i].tooltip.valueSuffix = ' ' + unit;

        if (resultsByWaterSourceIdAndExperimentDict[seriesSetting.waterSourceId.toString() + seriesSetting.experimentKey]) {
          if (this.selectedGraphOptions?.seperateYaxis === true) {
            console.log(this.chartOptions.yAxis, '---yAxisLollipop');

            this.chartOptions.yAxis.push(this.createYAxis(!this.hideYAxis, i % 2 === 0));
            this.chartOptions.yAxis[i].title.text = unit;
            this.chartOptions.series[i].yAxis = i;
          } else {
            if (!this.chartOptions.yAxis[0].title.text.includes(unit)) {
              this.chartOptions.yAxis[0].title.text += unit + ', ';
            }
          }
        }
      } else {
        if (this.chartOptions.series[i]) {
          this.chartOptions.series[i].name = '';
          this.chartOptions.series[i].data = [];
          // this.chartOptions.series[i + 1].data = [];
          this.chartOptions.series[i].showInLegend = false;
        }
      }
    }
    this.chartOptions.series = [...this.chartOptions.series, ...forecastSeries];
    // console.log('SW-7879 - this.chartOptions.series', this.chartOptions.series);

    // Add plotBand/plotLine only if forecast toggle is enabled
    if (this.ketosGraphData.forecastEnabled) {
      // Now that we have all the series, let's add the plotLines/plotBands
      const now = new Date().getTime();

      // SW-7879 - This splits the data from the forecast data
      this.chartOptions.xAxis[0].plotLines = [{
        color: 'rgba(255, 255, 255, 0.1)',
        width: 2, // Start from current date/time
        value: now, // Current date/time in local time zone
        dashStyle: 'Dash'
      }];

      const plotBand: XAxisPlotBandsOptions = {
        from: now,
        to: this._ketosGraphData.dateInterval?.max?.getTime(),
        color: 'rgba(255, 255, 255, 0.05)'
      };

      if (forecastSeries.length === 0) {
        plotBand.label = {
          text: 'Not enough data<br>to forecast',  // The text you want to display
          align: 'center',
          verticalAlign: 'middle',
          style: {
            // fontWeight: 'bold',
            fontSize: '16px',
            color: '#ffffff',
            fontFamily: 'RobotoLight',
          }
        };
      }

      this.chartOptions.xAxis[0].plotBands = [plotBand];
      this.setXaxisDate(this._ketosGraphData.dateInterval);
    }

    if (this.selectedGraphOptions?.seperateYaxis !== true) {
      if (this.chartOptions.yAxis[0].title.text.length > 0) {
        this.chartOptions.yAxis[0].title.text = this.chartOptions.yAxis[0].title.text.substring(0, this.chartOptions.yAxis[0].title.text.length - 2);
      }
    }
    this.setYaxisMinMaxFromOptions(this.selectedGraphOptions, this.chartOptions.yAxis[0]);
    // this.chartOptions.series = this.chartOptions.series.slice(0, experiments.length);
   // if(this.ketosGraphData){
    //}
    this.updateChartFlag = true;
    setTimeout( () => {
      //console.log(super.getSvgOnRedraw)

      if (this.selectedGraphOptions?.emitSvgOnRedraw && this.selectedGraphOptions?.leftAlignedTitle && this.chartInstance?.getSVG()) {
        this.setTitle(this.selectedGraphOptions.title);
        this.chartOptions.exporting.enabled = false;
        this.chartOptions.xAxis[0].title.text = '';
        this.updateChartFlag = true;
        // console.log('setResultsData', this.chartOptions.series[0].data.length, this.selectedGraphOptions.title)
        this.chartSVG.emit({
          name: this.chartOptions.series[0].name,
          svg: this.chartInstance.getSVG(),
          seriesData: this.chartOptions.series[0].data.length > 0,
          width: this.chartInstance.chartWidth,
          height: this.chartInstance.chartHeight,
          exp: this.selectedGraphOptions.exp,
          bit: this.selectedGraphOptions.bit  });
      }
    }, 1000);
  }

  getHistogramsBucketLengthInSeconds(histogramsDict: {[key: number]: Aggregate_results[]}): number {
    Object.keys(histogramsDict).map(key => histogramsDict[key])
      .map(histograms => {
        histograms.map(histogram => {
          if (histogram?.items.length > 1) {
            return moment(histogram.items[1].key_as_string).diff(moment(histogram.items[0].key_as_string), 'second', true);
          }
        });
      });
    return null;
  }

  showSeriesThresholdsBds(timeSeriesData: KetosGraphDataInterface, totalResultCount?: number) {
    this.showThreshBdlAdl(timeSeriesData, totalResultCount);

    // hide legends if all values empty
    // TODO Some of the series may be forecasts or other visuals so this
    //   is not the best way to do this
    let graphHasData = false;
    for (const serie of this.chartOptions.series) {
      if (serie?.data?.length > 0) {
        graphHasData = true;
        break;
      }
    }
    timeSeriesData.seriesSettings?.map((setting, index) => {
      if (this.chartOptions.series[index] && this.chartOptions.series[index].custom.primary === true) {
        this.chartOptions.series[index].showInLegend = graphHasData;
      }
    });
    this.chartOptions.exporting.enabled = graphHasData && timeSeriesData.disableDownloading !== true;

    // reimplement somewhere else?
    // console.log(timeSeriesData?.dateInterval)
    if (this.chartOptions.xAxis[0] && timeSeriesData?.dateInterval) {
      this.chartOptions.xAxis[0].max = timeSeriesData.dateInterval.max.getTime();
      this.chartOptions.xAxis[0].min = timeSeriesData.dateInterval.min.getTime();
    }
  }

  showThreshBdlAdl(graphData: KetosGraphDataInterface, totalResultCount?: number) {
    this.showThresholdLines(graphData);
    for (let i = 0; i < graphData.seriesSettings?.length; i++) {
      this.setBdlAdlColorZones(graphData.seriesSettings[i].experimentKey, i, i, graphData.seriesSettings[i].sensorBitmap);
    }
  }
  zoomSelection(chart, event): boolean {

    if(event.resetSelection  ){
      this._showZoomButton=false;
      this.zoomed.emit();
     // setTimeout(() => {
        this.zoomedMax=null;
        this.zoomedMin=null;
      //}, 500);
      return true;

    }

    if(event.xAxis && event.xAxis[0]){
      this.zoomedMin = event.xAxis[0].min
        this.zoomedMax = event.xAxis[0].max
        console.log('zoomed', moment(this.zoomedMax).format(), moment(this.zoomedMin).format())
        this.setXaxisDate({min: this.zoomedMin, max: this.zoomedMax},true)
      this.zoomed.emit({min: moment(this.zoomedMin).toDate(), max: moment(this.zoomedMax).toDate()});
      //
      return true;
    }
    return false;
  }
  setLiveUpdate(){
    this.liveUpdate.emit();
    this.updateChartFlag = true;
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    if(this.subscriptions){
    this.subscriptions.unsubscribe();
    }

     // Cleanup the listener just in case
    document.addEventListener('visibilitychange', this.visibilityChangeHandler, false);
  }
}
