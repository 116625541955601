/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Assign_sensor_group_object } from '../models/assign-_sensor-_group-_object';
import { Delete_sensor_group_object } from '../models/delete-_sensor-_group-_object';
import { Sensor_group_objects } from '../models/sensor-_group-_objects';

/**
 * sensor_group related operations
 */
@Injectable({
  providedIn: 'root',
})
class SensorGroupsService extends __BaseService {
  static readonly CreateASensorGroupPath = '/sensor_groups/';
  static readonly UpdateSensorGroupPath = '/sensor_groups/';
  static readonly DeleteASensorGroupPath = '/sensor_groups/';
  static readonly GetSensorGroupDetailsPath = '/sensor_groups/{sensor_type}/{organization_id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param payload undefined
   */
  CreateASensorGroupResponse(payload: Assign_sensor_group_object): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/sensor_groups/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param payload undefined
   */
  CreateASensorGroup(payload: Assign_sensor_group_object): __Observable<null> {
    return this.CreateASensorGroupResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param payload undefined
   */
  UpdateSensorGroupResponse(payload: Assign_sensor_group_object): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/sensor_groups/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param payload undefined
   */
  UpdateSensorGroup(payload: Assign_sensor_group_object): __Observable<null> {
    return this.UpdateSensorGroupResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param payload undefined
   */
  DeleteASensorGroupResponse(payload: Delete_sensor_group_object): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/sensor_groups/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param payload undefined
   */
  DeleteASensorGroup(payload: Delete_sensor_group_object): __Observable<null> {
    return this.DeleteASensorGroupResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `SensorGroupsService.GetSensorGroupDetailsParams` containing the following parameters:
   *
   * - `sensor_type`: shield/wave/iceberg
   *
   * - `organization_id`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetSensorGroupDetailsResponse(params: SensorGroupsService.GetSensorGroupDetailsParams): __Observable<__StrictHttpResponse<Sensor_group_objects>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/sensor_groups/${encodeURIComponent(String(params.sensorType))}/${encodeURIComponent(String(params.organizationId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Sensor_group_objects>;
      })
    );
  }
  /**
   * @param params The `SensorGroupsService.GetSensorGroupDetailsParams` containing the following parameters:
   *
   * - `sensor_type`: shield/wave/iceberg
   *
   * - `organization_id`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetSensorGroupDetails(params: SensorGroupsService.GetSensorGroupDetailsParams): __Observable<Sensor_group_objects> {
    return this.GetSensorGroupDetailsResponse(params).pipe(
      __map(_r => _r.body as Sensor_group_objects)
    );
  }
}

module SensorGroupsService {

  /**
   * Parameters for GetSensorGroupDetails
   */
  export interface GetSensorGroupDetailsParams {

    /**
     * shield/wave/iceberg
     */
    sensorType: number;
    organizationId: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }
}

export { SensorGroupsService }
