/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiInternalConfiguration as __Configuration } from '../api-internal-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Insights } from '../models/insights';

/**
 * Customer insights report/page related operations
 */
@Injectable({
  providedIn: 'root',
})
class InsightsService extends __BaseService {
  static readonly getInsightsPath = '/insights';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `InsightsService.GetInsightsParams` containing the following parameters:
   *
   * - `water_source_ids`: Water source ids
   *
   * - `period`: Interval
   *
   * - `start_datetime`: start date/time (inclusive) resolved date (ISO 8601)
   *
   * - `timezone`: Time zone as a named zone (e.g., "UTC", "America/New_York") or an offset (e.g., "+00:00", "+3")
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getInsightsResponse(params: InsightsService.GetInsightsParams): __Observable<__StrictHttpResponse<Insights>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.waterSourceIds || []).forEach(val => {if (val != null) __params = __params.append('water_source_ids', val.toString())});
    if (params.period != null) __params = __params.set('period', params.period.toString());
    if (params.startDatetime != null) __params = __params.set('start_datetime', params.startDatetime.toString());
    if (params.timezone != null) __params = __params.set('timezone', params.timezone.toString());
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/insights`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Insights>;
      })
    );
  }
  /**
   * @param params The `InsightsService.GetInsightsParams` containing the following parameters:
   *
   * - `water_source_ids`: Water source ids
   *
   * - `period`: Interval
   *
   * - `start_datetime`: start date/time (inclusive) resolved date (ISO 8601)
   *
   * - `timezone`: Time zone as a named zone (e.g., "UTC", "America/New_York") or an offset (e.g., "+00:00", "+3")
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getInsights(params: InsightsService.GetInsightsParams): __Observable<Insights> {
    return this.getInsightsResponse(params).pipe(
      __map(_r => _r.body as Insights)
    );
  }
}

module InsightsService {

  /**
   * Parameters for getInsights
   */
  export interface GetInsightsParams {

    /**
     * Water source ids
     */
    waterSourceIds: Array<number>;

    /**
     * Interval
     */
    period: 'week' | 'month' | 'quarter' | 'year';

    /**
     * start date/time (inclusive) resolved date (ISO 8601)
     */
    startDatetime: string;

    /**
     * Time zone as a named zone (e.g., "UTC", "America/New_York") or an offset (e.g., "+00:00", "+3")
     */
    timezone?: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }
}

export { InsightsService }
