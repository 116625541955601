/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Device_calibrations } from '../models/device-_calibrations';
import { Device_calibration } from '../models/device-_calibration';
import { Device_calibration_input } from '../models/device-_calibration-_input';
import { Calibration_schema } from '../models/calibration-_schema';

/**
 * Device calibration operations
 */
@Injectable({
  providedIn: 'root',
})
class DeviceCalibrationsService extends __BaseService {
  static readonly getDeviceCalibrationsPath = '/device_calibrations';
  static readonly postDeviceCalibrationsPath = '/device_calibrations';
  static readonly getCalibrationSchemaPath = '/device_calibrations/schema/{ketos_pn}';
  static readonly getDeviceCalibrationByIdPath = '/device_calibrations/{calibration_id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get list of device calibrations
   * @param params The `DeviceCalibrationsService.GetDeviceCalibrationsParams` containing the following parameters:
   *
   * - `device_ids`: List of device IDs to filter by
   *
   * - `device_types`: List of device types to filter by
   *
   * - `start_created_at`: Filter calibrations from this date (ISO 8601 format)
   *
   * - `end_created_at`: Filter calibrations until this date (ISO 8601 format)
   *
   * - `created_by`: Filter calibrations by creator email
   *
   * - `page`: Page number for pagination
   *
   * - `per_page`: Number of items per page
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getDeviceCalibrationsResponse(params: DeviceCalibrationsService.GetDeviceCalibrationsParams): __Observable<__StrictHttpResponse<Device_calibrations>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.deviceIds || []).forEach(val => {if (val != null) __params = __params.append('device_ids', val.toString())});
    (params.deviceTypes || []).forEach(val => {if (val != null) __params = __params.append('device_types', val.toString())});
    if (params.startCreatedAt != null) __params = __params.set('start_created_at', params.startCreatedAt.toString());
    if (params.endCreatedAt != null) __params = __params.set('end_created_at', params.endCreatedAt.toString());
    if (params.createdBy != null) __params = __params.set('created_by', params.createdBy.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.perPage != null) __params = __params.set('per_page', params.perPage.toString());
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/device_calibrations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Device_calibrations>;
      })
    );
  }
  /**
   * Get list of device calibrations
   * @param params The `DeviceCalibrationsService.GetDeviceCalibrationsParams` containing the following parameters:
   *
   * - `device_ids`: List of device IDs to filter by
   *
   * - `device_types`: List of device types to filter by
   *
   * - `start_created_at`: Filter calibrations from this date (ISO 8601 format)
   *
   * - `end_created_at`: Filter calibrations until this date (ISO 8601 format)
   *
   * - `created_by`: Filter calibrations by creator email
   *
   * - `page`: Page number for pagination
   *
   * - `per_page`: Number of items per page
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getDeviceCalibrations(params: DeviceCalibrationsService.GetDeviceCalibrationsParams): __Observable<Device_calibrations> {
    return this.getDeviceCalibrationsResponse(params).pipe(
      __map(_r => _r.body as Device_calibrations)
    );
  }

  /**
   * Create a new device calibration
   * @param params The `DeviceCalibrationsService.PostDeviceCalibrationsParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  postDeviceCalibrationsResponse(params: DeviceCalibrationsService.PostDeviceCalibrationsParams): __Observable<__StrictHttpResponse<Device_calibration>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/device_calibrations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Device_calibration>;
      })
    );
  }
  /**
   * Create a new device calibration
   * @param params The `DeviceCalibrationsService.PostDeviceCalibrationsParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  postDeviceCalibrations(params: DeviceCalibrationsService.PostDeviceCalibrationsParams): __Observable<Device_calibration> {
    return this.postDeviceCalibrationsResponse(params).pipe(
      __map(_r => _r.body as Device_calibration)
    );
  }

  /**
   * Get the calibration schema for a specific KETOS part number
   * @param params The `DeviceCalibrationsService.GetCalibrationSchemaParams` containing the following parameters:
   *
   * - `ketos_pn`: The KETOS part number
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getCalibrationSchemaResponse(params: DeviceCalibrationsService.GetCalibrationSchemaParams): __Observable<__StrictHttpResponse<Calibration_schema>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/device_calibrations/schema/${encodeURIComponent(String(params.ketosPn))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Calibration_schema>;
      })
    );
  }
  /**
   * Get the calibration schema for a specific KETOS part number
   * @param params The `DeviceCalibrationsService.GetCalibrationSchemaParams` containing the following parameters:
   *
   * - `ketos_pn`: The KETOS part number
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getCalibrationSchema(params: DeviceCalibrationsService.GetCalibrationSchemaParams): __Observable<Calibration_schema> {
    return this.getCalibrationSchemaResponse(params).pipe(
      __map(_r => _r.body as Calibration_schema)
    );
  }

  /**
   * Get a specific device calibration
   * @param params The `DeviceCalibrationsService.GetDeviceCalibrationByIdParams` containing the following parameters:
   *
   * - `calibration_id`: The calibration identifier
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getDeviceCalibrationByIdResponse(params: DeviceCalibrationsService.GetDeviceCalibrationByIdParams): __Observable<__StrictHttpResponse<Device_calibration>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/device_calibrations/${encodeURIComponent(String(params.calibrationId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Device_calibration>;
      })
    );
  }
  /**
   * Get a specific device calibration
   * @param params The `DeviceCalibrationsService.GetDeviceCalibrationByIdParams` containing the following parameters:
   *
   * - `calibration_id`: The calibration identifier
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getDeviceCalibrationById(params: DeviceCalibrationsService.GetDeviceCalibrationByIdParams): __Observable<Device_calibration> {
    return this.getDeviceCalibrationByIdResponse(params).pipe(
      __map(_r => _r.body as Device_calibration)
    );
  }
}

module DeviceCalibrationsService {

  /**
   * Parameters for getDeviceCalibrations
   */
  export interface GetDeviceCalibrationsParams {

    /**
     * List of device IDs to filter by
     */
    deviceIds?: Array<string>;

    /**
     * List of device types to filter by
     */
    deviceTypes?: Array<string>;

    /**
     * Filter calibrations from this date (ISO 8601 format)
     */
    startCreatedAt?: string;

    /**
     * Filter calibrations until this date (ISO 8601 format)
     */
    endCreatedAt?: string;

    /**
     * Filter calibrations by creator email
     */
    createdBy?: string;

    /**
     * Page number for pagination
     */
    page?: number;

    /**
     * Number of items per page
     */
    perPage?: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for postDeviceCalibrations
   */
  export interface PostDeviceCalibrationsParams {
    payload: Device_calibration_input;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for getCalibrationSchema
   */
  export interface GetCalibrationSchemaParams {

    /**
     * The KETOS part number
     */
    ketosPn: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for getDeviceCalibrationById
   */
  export interface GetDeviceCalibrationByIdParams {

    /**
     * The calibration identifier
     */
    calibrationId: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }
}

export { DeviceCalibrationsService }
