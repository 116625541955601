/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiInternalConfiguration as __Configuration } from '../api-internal-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Mux_devices } from '../models/mux-_devices';
import { Mux_device } from '../models/mux-_device';
import { Mux_device_input } from '../models/mux-_device-_input';

/**
 * MUX device related operations
 */
@Injectable({
  providedIn: 'root',
})
class MuxDevicesService extends __BaseService {
  static readonly getMuxDevicesPath = '/mux_devices';
  static readonly postMuxDevicesPath = '/mux_devices';
  static readonly putMuxDeviceMuxDevicesStringMuxDeviceIdPath = '/mux_devices/{mux_device_id}';
  static readonly deleteMuxDeviceMuxDevicesStringMuxDeviceIdPath = '/mux_devices/{mux_device_id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get Devices
   * @param params The `MuxDevicesService.GetMuxDevicesParams` containing the following parameters:
   *
   * - `ids`: List of MUX device ids (Serial Number)
   *
   * - `sensor_ids`: List of sensor ids
   *
   * - `shield_device_ids`: List of shield device serial numbers
   *
   * - `assigned`: Include devices with (True)/without (False) parent device
   *
   * - `include_deleted`: Include deleted
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getMuxDevicesResponse(params: MuxDevicesService.GetMuxDevicesParams): __Observable<__StrictHttpResponse<Mux_devices>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    (params.sensorIds || []).forEach(val => {if (val != null) __params = __params.append('sensor_ids', val.toString())});
    (params.shieldDeviceIds || []).forEach(val => {if (val != null) __params = __params.append('shield_device_ids', val.toString())});
    if (params.assigned != null) __params = __params.set('assigned', params.assigned.toString());
    if (params.includeDeleted != null) __params = __params.set('include_deleted', params.includeDeleted.toString());
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mux_devices`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Mux_devices>;
      })
    );
  }
  /**
   * Get Devices
   * @param params The `MuxDevicesService.GetMuxDevicesParams` containing the following parameters:
   *
   * - `ids`: List of MUX device ids (Serial Number)
   *
   * - `sensor_ids`: List of sensor ids
   *
   * - `shield_device_ids`: List of shield device serial numbers
   *
   * - `assigned`: Include devices with (True)/without (False) parent device
   *
   * - `include_deleted`: Include deleted
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getMuxDevices(params: MuxDevicesService.GetMuxDevicesParams): __Observable<Mux_devices> {
    return this.getMuxDevicesResponse(params).pipe(
      __map(_r => _r.body as Mux_devices)
    );
  }

  /**
   * Create Device
   * @param params The `MuxDevicesService.PostMuxDevicesParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  postMuxDevicesResponse(params: MuxDevicesService.PostMuxDevicesParams): __Observable<__StrictHttpResponse<Mux_device>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mux_devices`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Mux_device>;
      })
    );
  }
  /**
   * Create Device
   * @param params The `MuxDevicesService.PostMuxDevicesParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  postMuxDevices(params: MuxDevicesService.PostMuxDevicesParams): __Observable<Mux_device> {
    return this.postMuxDevicesResponse(params).pipe(
      __map(_r => _r.body as Mux_device)
    );
  }

  /**
   * Update Device
   * @param params The `MuxDevicesService.PutMuxDeviceMuxDevicesStringMuxDeviceIdParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `mux_device_id`: Device Serial Number
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  putMuxDeviceMuxDevicesStringMuxDeviceIdResponse(params: MuxDevicesService.PutMuxDeviceMuxDevicesStringMuxDeviceIdParams): __Observable<__StrictHttpResponse<Mux_device>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/mux_devices/${encodeURIComponent(String(params.muxDeviceId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Mux_device>;
      })
    );
  }
  /**
   * Update Device
   * @param params The `MuxDevicesService.PutMuxDeviceMuxDevicesStringMuxDeviceIdParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `mux_device_id`: Device Serial Number
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  putMuxDeviceMuxDevicesStringMuxDeviceId(params: MuxDevicesService.PutMuxDeviceMuxDevicesStringMuxDeviceIdParams): __Observable<Mux_device> {
    return this.putMuxDeviceMuxDevicesStringMuxDeviceIdResponse(params).pipe(
      __map(_r => _r.body as Mux_device)
    );
  }

  /**
   * Delete Device
   * @param params The `MuxDevicesService.DeleteMuxDeviceMuxDevicesStringMuxDeviceIdParams` containing the following parameters:
   *
   * - `mux_device_id`: Device Serial Number
   *
   * - `reason`: Reason for deleting the device
   */
  deleteMuxDeviceMuxDevicesStringMuxDeviceIdResponse(params: MuxDevicesService.DeleteMuxDeviceMuxDevicesStringMuxDeviceIdParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.reason != null) __params = __params.set('reason', params.reason.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/mux_devices/${encodeURIComponent(String(params.muxDeviceId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete Device
   * @param params The `MuxDevicesService.DeleteMuxDeviceMuxDevicesStringMuxDeviceIdParams` containing the following parameters:
   *
   * - `mux_device_id`: Device Serial Number
   *
   * - `reason`: Reason for deleting the device
   */
  deleteMuxDeviceMuxDevicesStringMuxDeviceId(params: MuxDevicesService.DeleteMuxDeviceMuxDevicesStringMuxDeviceIdParams): __Observable<null> {
    return this.deleteMuxDeviceMuxDevicesStringMuxDeviceIdResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module MuxDevicesService {

  /**
   * Parameters for getMuxDevices
   */
  export interface GetMuxDevicesParams {

    /**
     * List of MUX device ids (Serial Number)
     */
    ids?: Array<string>;

    /**
     * List of sensor ids
     */
    sensorIds?: Array<number>;

    /**
     * List of shield device serial numbers
     */
    shieldDeviceIds?: Array<string>;

    /**
     * Include devices with (True)/without (False) parent device
     */
    assigned?: boolean;

    /**
     * Include deleted
     */
    includeDeleted?: boolean;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for postMuxDevices
   */
  export interface PostMuxDevicesParams {
    payload: Mux_device;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for putMuxDeviceMuxDevicesStringMuxDeviceId
   */
  export interface PutMuxDeviceMuxDevicesStringMuxDeviceIdParams {
    payload: Mux_device_input;

    /**
     * Device Serial Number
     */
    muxDeviceId: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for deleteMuxDeviceMuxDevicesStringMuxDeviceId
   */
  export interface DeleteMuxDeviceMuxDevicesStringMuxDeviceIdParams {

    /**
     * Device Serial Number
     */
    muxDeviceId: string;

    /**
     * Reason for deleting the device
     */
    reason?: string;
  }
}

export { MuxDevicesService }
