import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {UsersService} from '../../../../api/src/lib/services/users.service';
import {WaterSourcesCachedService} from './water-sources-cached.service';
import {ChartSettingsInterface} from '../pages/ketos-graph/ketos-graph.page';
import {CloneDeep} from '../utils/utils';


@Injectable({
  providedIn: 'root'
})
export class GraphModelService {
  public graphModels: ChartSettingsInterface[] = [];
  public sensors = [];
  public isforecaseEnabled = false;

  constructor(private authService: AuthService,
              private waterSourcesService: WaterSourcesCachedService,
              private usersService: UsersService) {
  }

  public loadGraphicalViewModels(): Promise<any> {
    if (this.authService.getUser().roleName === 'SuperAdmin') {
      return Promise.resolve(this.graphModels);
    }
    const models = [];
    return this.usersService.GetUserSpecificSettings().toPromise()
      .then(data => {
        if (Array.isArray(data)) {
          for (const val of data as any[]) {
            if (val.key === 'shieldGraphs') {
              let shieldModels = JSON.parse(val.value);
              shieldModels = shieldModels.filter(graphModel => !graphModel.id || this.waterSourcesService.dictById[graphModel.id]);

              if (shieldModels) {
                for (const gr of shieldModels) {
                  const dateRange = gr.dateRange && !gr.forecastEnabled ? gr.dateRange : gr.originalDateRange;
                  if (dateRange) {
                    gr.dateRange.min = new Date(dateRange.min);
                    gr.dateRange.max = new Date(dateRange.max);
                  }
                  this.verifyShieldGraphModel(gr);
                }
                models.push(...shieldModels);
              }
            }
          }
        }
        this.graphModels = models;
        return models;
      });
  }

  public saveGraphicalViewModels() {
    // Check if the user is a SuperAdmin, if so, return early
    if (this.authService.getUser().roleName === 'SuperAdmin') {
      return;
    }
    // Deep clone the graph models to avoid mutating the original data
    const graphModels = CloneDeep(this.graphModels);

    // Update the date ranges based on the forecast setting
    graphModels.forEach(gr => {
      const dateRange = this.isforecaseEnabled ? gr.originalDateRange : gr.dateRange;
      gr.dateRange.min = new Date(dateRange.min);
      gr.dateRange.max = new Date(dateRange.max);
    });

    // Prepare the settings payload
    const settingsPayload = {
      items: [
        {
          key: 'shieldGraphs',
          value: JSON.stringify(graphModels)
        }
      ]
    };

    // Update user-specific settings
    this.usersService.UpdateUserSpecificSettings(settingsPayload).subscribe(() => {
    });
  }

  public verifyShieldGraphModel(model: ChartSettingsInterface): ChartSettingsInterface {
    if (!model.dateRange || !model.dateRange.min || !model.dateRange.max) {
      model.dateRange = {
        max: new Date(),
        min: new Date(),
        defaultValue: true
      };
      model.dateRange.min.setDate(model.dateRange.max.getDate() - 7);
    }
    return model;
  }
}
