/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Sensor } from '../models/sensor';
import { Sensor_input_create } from '../models/sensor-_input-_create';
import { Sensors_with_organization } from '../models/sensors-_with-_organization';
import { Sensor_settings } from '../models/sensor-_settings';
import { Shield_device_request } from '../models/shield-_device-_request';
import { Shield_sensors_aggregates } from '../models/shield-_sensors-_aggregates';
import { Shield_heartbeat } from '../models/shield-_heartbeat';
import { Shield_sensor } from '../models/shield-_sensor';
import { Sensors_paginated } from '../models/sensors-_paginated';
import { Sensor_input } from '../models/sensor-_input';
import { Flow_rate_input } from '../models/flow-_rate-_input';
import { Sensor_setting_inputs } from '../models/sensor-_setting-_inputs';
import { Sensor_temperature } from '../models/sensor-_temperature';

/**
 * Sensor related operations
 */
@Injectable({
  providedIn: 'root',
})
class SensorsService extends __BaseService {
  static readonly postSensorsPath = '/sensors';
  static readonly getSensorsPath = '/sensors';
  static readonly getSettingsPath = '/sensors/settings';
  static readonly ProvisionShieldDevicePath = '/sensors/shield/';
  static readonly GetAllShieldSensorsAggregatesPath = '/sensors/shield/aggregates';
  static readonly UpdateShieldHeartbeatPath = '/sensors/shield/heartbeat/';
  static readonly GetOrgNameNumberPath = '/sensors/shield/org_name/{sensor_id}/';
  static readonly UpdatePumpStatusWithSensorIdPath = '/sensors/shield/pump_status/{sensor_id}/{status}/';
  static readonly GetShieldSolutionOverviewPDFPath = '/sensors/shield/solution_pdf/';
  static readonly ReturnSensorListInTheRequestedStatusOnlineOfflinePath = '/sensors/shield/status/{status}/';
  static readonly GetSensorSerialNumberPath = '/sensors/shield/{sensor_id}/';
  static readonly GetSensorsPaginatedPath = '/sensors/{page}/{per_page}';
  static readonly putSensorPath = '/sensors/{sensor_id}';
  static readonly putProcessAmbientTemperaturePath = '/sensors/{sensor_id}/ambient_temperature/{ambient_temperature}';
  static readonly putFlowRatePath = '/sensors/{sensor_id}/flow_rate';
  static readonly deleteSensorSettingsPath = '/sensors/{sensor_id}/settings';
  static readonly putSensorSettingsPath = '/sensors/{sensor_id}/settings';
  static readonly getSensorSettingsPath = '/sensors/{sensor_id}/settings';
  static readonly deleteShieldSensorDeviceDecommissionSensorsIntSensorIdShieldDevicePath = '/sensors/{sensor_id}/shield_device';
  static readonly postShieldSensorDeviceCommissionSensorsIntSensorIdShieldDeviceStringSensorSnPath = '/sensors/{sensor_id}/shield_device/{sensor_sn}';
  static readonly getSensorTemperatureSensorsIntSensorIdTemperaturePath = '/sensors/{sensor_id}/temperature';
  static readonly GetSensorsPath = '/sensors/{sensor_type}/{page}/{per_page}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `SensorsService.PostSensorsParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  postSensorsResponse(params: SensorsService.PostSensorsParams): __Observable<__StrictHttpResponse<Sensor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/sensors`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Sensor>;
      })
    );
  }
  /**
   * @param params The `SensorsService.PostSensorsParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  postSensors(params: SensorsService.PostSensorsParams): __Observable<Sensor> {
    return this.postSensorsResponse(params).pipe(
      __map(_r => _r.body as Sensor)
    );
  }

  /**
   * @param params The `SensorsService.GetSensorsParams` containing the following parameters:
   *
   * - `ids`: List of sensor ids
   *
   * - `sensor_sns`: List of sensor serial numbers
   *
   * - `sensor_types`: Sensor types (1=Wave, 2=Shield, 3=Iceberg)
   *
   * - `data_sources`: Data sources (1=Internal/KETOS device, 2=External device/Lab)
   *
   * - `organization_ids`: Organization IDs
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getSensorsResponse(params: SensorsService.GetSensorsParams): __Observable<__StrictHttpResponse<Sensors_with_organization>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    (params.sensorSns || []).forEach(val => {if (val != null) __params = __params.append('sensor_sns', val.toString())});
    (params.sensorTypes || []).forEach(val => {if (val != null) __params = __params.append('sensor_types', val.toString())});
    (params.dataSources || []).forEach(val => {if (val != null) __params = __params.append('data_sources', val.toString())});
    (params.organizationIds || []).forEach(val => {if (val != null) __params = __params.append('organization_ids', val.toString())});
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/sensors`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Sensors_with_organization>;
      })
    );
  }
  /**
   * @param params The `SensorsService.GetSensorsParams` containing the following parameters:
   *
   * - `ids`: List of sensor ids
   *
   * - `sensor_sns`: List of sensor serial numbers
   *
   * - `sensor_types`: Sensor types (1=Wave, 2=Shield, 3=Iceberg)
   *
   * - `data_sources`: Data sources (1=Internal/KETOS device, 2=External device/Lab)
   *
   * - `organization_ids`: Organization IDs
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getSensors(params: SensorsService.GetSensorsParams): __Observable<Sensors_with_organization> {
    return this.getSensorsResponse(params).pipe(
      __map(_r => _r.body as Sensors_with_organization)
    );
  }

  /**
   * Bulk endpoint for customer portal only
   * @param X-Fields An optional fields mask
   * @return Success
   */
  getSettingsResponse(XFields?: string): __Observable<__StrictHttpResponse<Sensor_settings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (XFields != null) __headers = __headers.set('X-Fields', XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/sensors/settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Sensor_settings>;
      })
    );
  }
  /**
   * Bulk endpoint for customer portal only
   * @param X-Fields An optional fields mask
   * @return Success
   */
  getSettings(XFields?: string): __Observable<Sensor_settings> {
    return this.getSettingsResponse(XFields).pipe(
      __map(_r => _r.body as Sensor_settings)
    );
  }

  /**
   * Commission a shield device to a sensor (location)
   * @param params The `SensorsService.ProvisionShieldDeviceParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `X-Fields`: An optional fields mask
   */
  ProvisionShieldDeviceResponse(params: SensorsService.ProvisionShieldDeviceParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/sensors/shield/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Commission a shield device to a sensor (location)
   * @param params The `SensorsService.ProvisionShieldDeviceParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `X-Fields`: An optional fields mask
   */
  ProvisionShieldDevice(params: SensorsService.ProvisionShieldDeviceParams): __Observable<null> {
    return this.ProvisionShieldDeviceResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param X-Fields An optional fields mask
   * @return Success
   */
  GetAllShieldSensorsAggregatesResponse(XFields?: string): __Observable<__StrictHttpResponse<Shield_sensors_aggregates>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (XFields != null) __headers = __headers.set('X-Fields', XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/sensors/shield/aggregates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Shield_sensors_aggregates>;
      })
    );
  }
  /**
   * @param X-Fields An optional fields mask
   * @return Success
   */
  GetAllShieldSensorsAggregates(XFields?: string): __Observable<Shield_sensors_aggregates> {
    return this.GetAllShieldSensorsAggregatesResponse(XFields).pipe(
      __map(_r => _r.body as Shield_sensors_aggregates)
    );
  }

  /**
   * Used by Shield Device
   * @param payload undefined
   */
  UpdateShieldHeartbeatResponse(payload: Shield_heartbeat): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/sensors/shield/heartbeat/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Used by Shield Device
   * @param payload undefined
   */
  UpdateShieldHeartbeat(payload: Shield_heartbeat): __Observable<null> {
    return this.UpdateShieldHeartbeatResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Used by Lambda
   * @param sensor_id undefined
   */
  GetOrgNameNumberResponse(sensorId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/sensors/shield/org_name/${encodeURIComponent(String(sensorId))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Used by Lambda
   * @param sensor_id undefined
   */
  GetOrgNameNumber(sensorId: string): __Observable<null> {
    return this.GetOrgNameNumberResponse(sensorId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Used by Lambda
   * @param params The `SensorsService.UpdatePumpStatusWithSensorIdParams` containing the following parameters:
   *
   * - `sensor_id`:
   *
   * - `status`:
   */
  UpdatePumpStatusWithSensorIdResponse(params: SensorsService.UpdatePumpStatusWithSensorIdParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/sensors/shield/pump_status/${encodeURIComponent(String(params.sensorId))}/${encodeURIComponent(String(params.status))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Used by Lambda
   * @param params The `SensorsService.UpdatePumpStatusWithSensorIdParams` containing the following parameters:
   *
   * - `sensor_id`:
   *
   * - `status`:
   */
  UpdatePumpStatusWithSensorId(params: SensorsService.UpdatePumpStatusWithSensorIdParams): __Observable<null> {
    return this.UpdatePumpStatusWithSensorIdResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
  GetShieldSolutionOverviewPDFResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/sensors/shield/solution_pdf/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  GetShieldSolutionOverviewPDF(): __Observable<null> {
    return this.GetShieldSolutionOverviewPDFResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Used by Lambda
   * @param params The `SensorsService.ReturnSensorListInTheRequestedStatusOnlineOfflineParams` containing the following parameters:
   *
   * - `status`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  ReturnSensorListInTheRequestedStatusOnlineOfflineResponse(params: SensorsService.ReturnSensorListInTheRequestedStatusOnlineOfflineParams): __Observable<__StrictHttpResponse<Shield_sensor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/sensors/shield/status/${encodeURIComponent(String(params.status))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Shield_sensor>;
      })
    );
  }
  /**
   * Used by Lambda
   * @param params The `SensorsService.ReturnSensorListInTheRequestedStatusOnlineOfflineParams` containing the following parameters:
   *
   * - `status`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  ReturnSensorListInTheRequestedStatusOnlineOffline(params: SensorsService.ReturnSensorListInTheRequestedStatusOnlineOfflineParams): __Observable<Shield_sensor> {
    return this.ReturnSensorListInTheRequestedStatusOnlineOfflineResponse(params).pipe(
      __map(_r => _r.body as Shield_sensor)
    );
  }

  /**
   * Used by Lambda
   * @param sensor_id undefined
   */
  GetSensorSerialNumberResponse(sensorId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/sensors/shield/${encodeURIComponent(String(sensorId))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Used by Lambda
   * @param sensor_id undefined
   */
  GetSensorSerialNumber(sensorId: string): __Observable<null> {
    return this.GetSensorSerialNumberResponse(sensorId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `SensorsService.GetSensorsPaginatedParams` containing the following parameters:
   *
   * - `page`: Page number
   *
   * - `per_page`: Results per page
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetSensorsPaginatedResponse(params: SensorsService.GetSensorsPaginatedParams): __Observable<__StrictHttpResponse<Sensors_paginated>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/sensors/${encodeURIComponent(String(params.page))}/${encodeURIComponent(String(params.perPage))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Sensors_paginated>;
      })
    );
  }
  /**
   * @param params The `SensorsService.GetSensorsPaginatedParams` containing the following parameters:
   *
   * - `page`: Page number
   *
   * - `per_page`: Results per page
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetSensorsPaginated(params: SensorsService.GetSensorsPaginatedParams): __Observable<Sensors_paginated> {
    return this.GetSensorsPaginatedResponse(params).pipe(
      __map(_r => _r.body as Sensors_paginated)
    );
  }

  /**
   * @param params The `SensorsService.PutSensorParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `sensor_id`:
   */
  putSensorResponse(params: SensorsService.PutSensorParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/sensors/${encodeURIComponent(String(params.sensorId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `SensorsService.PutSensorParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `sensor_id`:
   */
  putSensor(params: SensorsService.PutSensorParams): __Observable<null> {
    return this.putSensorResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * CALLED FROM LAMBDA Check temperature for possible freeze condition
   * @param params The `SensorsService.PutProcessAmbientTemperatureParams` containing the following parameters:
   *
   * - `sensor_id`:
   *
   * - `ambient_temperature`:
   */
  putProcessAmbientTemperatureResponse(params: SensorsService.PutProcessAmbientTemperatureParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/sensors/${encodeURIComponent(String(params.sensorId))}/ambient_temperature/${encodeURIComponent(String(params.ambientTemperature))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * CALLED FROM LAMBDA Check temperature for possible freeze condition
   * @param params The `SensorsService.PutProcessAmbientTemperatureParams` containing the following parameters:
   *
   * - `sensor_id`:
   *
   * - `ambient_temperature`:
   */
  putProcessAmbientTemperature(params: SensorsService.PutProcessAmbientTemperatureParams): __Observable<null> {
    return this.putProcessAmbientTemperatureResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Sets flow rate for all water sources under sensor
   * @param params The `SensorsService.PutFlowRateParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `sensor_id`:
   */
  putFlowRateResponse(params: SensorsService.PutFlowRateParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/sensors/${encodeURIComponent(String(params.sensorId))}/flow_rate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Sets flow rate for all water sources under sensor
   * @param params The `SensorsService.PutFlowRateParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `sensor_id`:
   */
  putFlowRate(params: SensorsService.PutFlowRateParams): __Observable<null> {
    return this.putFlowRateResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `SensorsService.DeleteSensorSettingsParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `sensor_id`:
   */
  deleteSensorSettingsResponse(params: SensorsService.DeleteSensorSettingsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/sensors/${encodeURIComponent(String(params.sensorId))}/settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `SensorsService.DeleteSensorSettingsParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `sensor_id`:
   */
  deleteSensorSettings(params: SensorsService.DeleteSensorSettingsParams): __Observable<null> {
    return this.deleteSensorSettingsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `SensorsService.PutSensorSettingsParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `sensor_id`:
   */
  putSensorSettingsResponse(params: SensorsService.PutSensorSettingsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/sensors/${encodeURIComponent(String(params.sensorId))}/settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `SensorsService.PutSensorSettingsParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `sensor_id`:
   */
  putSensorSettings(params: SensorsService.PutSensorSettingsParams): __Observable<null> {
    return this.putSensorSettingsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `SensorsService.GetSensorSettingsParams` containing the following parameters:
   *
   * - `sensor_id`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getSensorSettingsResponse(params: SensorsService.GetSensorSettingsParams): __Observable<__StrictHttpResponse<Sensor_settings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/sensors/${encodeURIComponent(String(params.sensorId))}/settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Sensor_settings>;
      })
    );
  }
  /**
   * @param params The `SensorsService.GetSensorSettingsParams` containing the following parameters:
   *
   * - `sensor_id`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getSensorSettings(params: SensorsService.GetSensorSettingsParams): __Observable<Sensor_settings> {
    return this.getSensorSettingsResponse(params).pipe(
      __map(_r => _r.body as Sensor_settings)
    );
  }

  /**
   * Decommission (Remove) a shield device from a sensor (location)
   *
   * Remove shield device from sensor (location)
   * @param sensor_id Sensor ID
   */
  deleteShieldSensorDeviceDecommissionSensorsIntSensorIdShieldDeviceResponse(sensorId: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/sensors/${encodeURIComponent(String(sensorId))}/shield_device`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Decommission (Remove) a shield device from a sensor (location)
   *
   * Remove shield device from sensor (location)
   * @param sensor_id Sensor ID
   */
  deleteShieldSensorDeviceDecommissionSensorsIntSensorIdShieldDevice(sensorId: number): __Observable<null> {
    return this.deleteShieldSensorDeviceDecommissionSensorsIntSensorIdShieldDeviceResponse(sensorId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Commission (Assign) a shield device to a sensor (location)
   *
   * Add shield device to sensor (location)
   * @param params The `SensorsService.PostShieldSensorDeviceCommissionSensorsIntSensorIdShieldDeviceStringSensorSnParams` containing the following parameters:
   *
   * - `sensor_id`: Sensor ID
   *
   * - `sensor_sn`: Sensor SN (ShieldDevice.id)
   */
  postShieldSensorDeviceCommissionSensorsIntSensorIdShieldDeviceStringSensorSnResponse(params: SensorsService.PostShieldSensorDeviceCommissionSensorsIntSensorIdShieldDeviceStringSensorSnParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/sensors/${encodeURIComponent(String(params.sensorId))}/shield_device/${encodeURIComponent(String(params.sensorSn))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Commission (Assign) a shield device to a sensor (location)
   *
   * Add shield device to sensor (location)
   * @param params The `SensorsService.PostShieldSensorDeviceCommissionSensorsIntSensorIdShieldDeviceStringSensorSnParams` containing the following parameters:
   *
   * - `sensor_id`: Sensor ID
   *
   * - `sensor_sn`: Sensor SN (ShieldDevice.id)
   */
  postShieldSensorDeviceCommissionSensorsIntSensorIdShieldDeviceStringSensorSn(params: SensorsService.PostShieldSensorDeviceCommissionSensorsIntSensorIdShieldDeviceStringSensorSnParams): __Observable<null> {
    return this.postShieldSensorDeviceCommissionSensorsIntSensorIdShieldDeviceStringSensorSnResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get temperature at sensor location
   * @param params The `SensorsService.GetSensorTemperatureSensorsIntSensorIdTemperatureParams` containing the following parameters:
   *
   * - `sensor_id`: Sensor ID
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getSensorTemperatureSensorsIntSensorIdTemperatureResponse(params: SensorsService.GetSensorTemperatureSensorsIntSensorIdTemperatureParams): __Observable<__StrictHttpResponse<Sensor_temperature>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/sensors/${encodeURIComponent(String(params.sensorId))}/temperature`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Sensor_temperature>;
      })
    );
  }
  /**
   * Get temperature at sensor location
   * @param params The `SensorsService.GetSensorTemperatureSensorsIntSensorIdTemperatureParams` containing the following parameters:
   *
   * - `sensor_id`: Sensor ID
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getSensorTemperatureSensorsIntSensorIdTemperature(params: SensorsService.GetSensorTemperatureSensorsIntSensorIdTemperatureParams): __Observable<Sensor_temperature> {
    return this.getSensorTemperatureSensorsIntSensorIdTemperatureResponse(params).pipe(
      __map(_r => _r.body as Sensor_temperature)
    );
  }

  /**
   * @param params The `SensorsService.GetSensorsParams` containing the following parameters:
   *
   * - `sensor_type`: Sensor Type 1=Wave, 2=Shield, 3=Iceberg
   *
   * - `page`: Page number
   *
   * - `per_page`: Results per page
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetSensorsResponse(params: SensorsService.GetSensorsParams): __Observable<__StrictHttpResponse<Sensors_paginated>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/sensors/${encodeURIComponent(String(params.sensorType))}/${encodeURIComponent(String(params.page))}/${encodeURIComponent(String(params.perPage))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Sensors_paginated>;
      })
    );
  }
  /**
   * @param params The `SensorsService.GetSensorsParams` containing the following parameters:
   *
   * - `sensor_type`: Sensor Type 1=Wave, 2=Shield, 3=Iceberg
   *
   * - `page`: Page number
   *
   * - `per_page`: Results per page
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetSensors(params: SensorsService.GetSensorsParams): __Observable<Sensors_paginated> {
    return this.GetSensorsResponse(params).pipe(
      __map(_r => _r.body as Sensors_paginated)
    );
  }
}

module SensorsService {

  /**
   * Parameters for postSensors
   */
  export interface PostSensorsParams {
    payload: Sensor_input_create;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for getSensors
   */
  export interface GetSensorsParams {

    /**
     * List of sensor ids
     */
    ids?: Array<string>;

    /**
     * List of sensor serial numbers
     */
    sensorSns?: Array<string>;

    /**
     * Sensor types (1=Wave, 2=Shield, 3=Iceberg)
     */
    sensorTypes?: Array<number>;

    /**
     * Data sources (1=Internal/KETOS device, 2=External device/Lab)
     */
    dataSources?: Array<number>;

    /**
     * Organization IDs
     */
    organizationIds?: Array<number>;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for ProvisionShieldDevice
   */
  export interface ProvisionShieldDeviceParams {
    payload: Shield_device_request;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for UpdatePumpStatusWithSensorId
   */
  export interface UpdatePumpStatusWithSensorIdParams {
    sensorId: number;
    status: string;
  }

  /**
   * Parameters for ReturnSensorListInTheRequestedStatusOnlineOffline
   */
  export interface ReturnSensorListInTheRequestedStatusOnlineOfflineParams {
    status: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for GetSensorsPaginated
   */
  export interface GetSensorsPaginatedParams {

    /**
     * Page number
     */
    page: number;

    /**
     * Results per page
     */
    perPage: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for putSensor
   */
  export interface PutSensorParams {
    payload: Sensor_input;
    sensorId: number;
  }

  /**
   * Parameters for putProcessAmbientTemperature
   */
  export interface PutProcessAmbientTemperatureParams {
    sensorId: number;
    ambientTemperature: string;
  }

  /**
   * Parameters for putFlowRate
   */
  export interface PutFlowRateParams {
    payload: Flow_rate_input;
    sensorId: number;
  }

  /**
   * Parameters for deleteSensorSettings
   */
  export interface DeleteSensorSettingsParams {
    payload: Sensor_setting_inputs;
    sensorId: number;
  }

  /**
   * Parameters for putSensorSettings
   */
  export interface PutSensorSettingsParams {
    payload: Sensor_setting_inputs;
    sensorId: number;
  }

  /**
   * Parameters for getSensorSettings
   */
  export interface GetSensorSettingsParams {
    sensorId: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for postShieldSensorDeviceCommissionSensorsIntSensorIdShieldDeviceStringSensorSn
   */
  export interface PostShieldSensorDeviceCommissionSensorsIntSensorIdShieldDeviceStringSensorSnParams {

    /**
     * Sensor ID
     */
    sensorId: number;

    /**
     * Sensor SN (ShieldDevice.id)
     */
    sensorSn: string;
  }

  /**
   * Parameters for getSensorTemperatureSensorsIntSensorIdTemperature
   */
  export interface GetSensorTemperatureSensorsIntSensorIdTemperatureParams {

    /**
     * Sensor ID
     */
    sensorId: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for GetSensors
   */
  export interface GetSensorsParams {

    /**
     * Sensor Type 1=Wave, 2=Shield, 3=Iceberg
     */
    sensorType: number;

    /**
     * Page number
     */
    page: number;

    /**
     * Results per page
     */
    perPage: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }
}

export { SensorsService }
