/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiInternalConfiguration as __Configuration } from '../api-internal-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Pm_summaries } from '../models/pm-_summaries';
import { Probe_pm_defaults } from '../models/probe-_pm-_defaults';
import { Pm_summary } from '../models/pm-_summary';
import { Pm_summary_mfg_overrides_input } from '../models/pm-_summary-_mfg-_overrides-_input';

/**
 * Planned Maintenance summary related operations
 */
@Injectable({
  providedIn: 'root',
})
class PmSummaryService extends __BaseService {
  static readonly putPmSummariesPath = '/pm_summary';
  static readonly getPmSummariesPath = '/pm_summary';
  static readonly getProbePmDefaultsPath = '/pm_summary/probe_pm_defaults';
  static readonly putPmSummaryPath = '/pm_summary/{sensor_id}';
  static readonly putCalculatePmSummaryPath = '/pm_summary/{sensor_id}/generate';
  static readonly putPmSummaryMfgOverridePath = '/pm_summary/{sensor_id}/mfg_override';
  static readonly putSendPmSummaryPath = '/pm_summary/{sensor_id}/send';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Replace a list of PM summaries for a sensor (id)
   * @param payload undefined
   */
  putPmSummariesResponse(payload: Pm_summaries): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/pm_summary`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Replace a list of PM summaries for a sensor (id)
   * @param payload undefined
   */
  putPmSummaries(payload: Pm_summaries): __Observable<null> {
    return this.putPmSummariesResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get PM Summaries
   * @param X-Fields An optional fields mask
   * @return Success
   */
  getPmSummariesResponse(XFields?: string): __Observable<__StrictHttpResponse<Pm_summaries>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (XFields != null) __headers = __headers.set('X-Fields', XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pm_summary`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Pm_summaries>;
      })
    );
  }
  /**
   * Get PM Summaries
   * @param X-Fields An optional fields mask
   * @return Success
   */
  getPmSummaries(XFields?: string): __Observable<Pm_summaries> {
    return this.getPmSummariesResponse(XFields).pipe(
      __map(_r => _r.body as Pm_summaries)
    );
  }

  /**
   * @param X-Fields An optional fields mask
   * @return Success
   */
  getProbePmDefaultsResponse(XFields?: string): __Observable<__StrictHttpResponse<Probe_pm_defaults>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (XFields != null) __headers = __headers.set('X-Fields', XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pm_summary/probe_pm_defaults`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Probe_pm_defaults>;
      })
    );
  }
  /**
   * @param X-Fields An optional fields mask
   * @return Success
   */
  getProbePmDefaults(XFields?: string): __Observable<Probe_pm_defaults> {
    return this.getProbePmDefaultsResponse(XFields).pipe(
      __map(_r => _r.body as Probe_pm_defaults)
    );
  }

  /**
   * Update PM summary (Mainly for override_pm
   * @param params The `PmSummaryService.PutPmSummaryParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `sensor_id`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  putPmSummaryResponse(params: PmSummaryService.PutPmSummaryParams): __Observable<__StrictHttpResponse<Pm_summary>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/pm_summary/${encodeURIComponent(String(params.sensorId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Pm_summary>;
      })
    );
  }
  /**
   * Update PM summary (Mainly for override_pm
   * @param params The `PmSummaryService.PutPmSummaryParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `sensor_id`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  putPmSummary(params: PmSummaryService.PutPmSummaryParams): __Observable<Pm_summary> {
    return this.putPmSummaryResponse(params).pipe(
      __map(_r => _r.body as Pm_summary)
    );
  }

  /**
   * Generate PM summary for a sensor (id)
   * @param sensor_id undefined
   */
  putCalculatePmSummaryResponse(sensorId: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/pm_summary/${encodeURIComponent(String(sensorId))}/generate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Generate PM summary for a sensor (id)
   * @param sensor_id undefined
   */
  putCalculatePmSummary(sensorId: number): __Observable<null> {
    return this.putCalculatePmSummaryResponse(sensorId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Update PM summary overrides for manufacturer PM interval and EOL
   * @param params The `PmSummaryService.PutPmSummaryMfgOverrideParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `sensor_id`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  putPmSummaryMfgOverrideResponse(params: PmSummaryService.PutPmSummaryMfgOverrideParams): __Observable<__StrictHttpResponse<Pm_summary>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/pm_summary/${encodeURIComponent(String(params.sensorId))}/mfg_override`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Pm_summary>;
      })
    );
  }
  /**
   * Update PM summary overrides for manufacturer PM interval and EOL
   * @param params The `PmSummaryService.PutPmSummaryMfgOverrideParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `sensor_id`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  putPmSummaryMfgOverride(params: PmSummaryService.PutPmSummaryMfgOverrideParams): __Observable<Pm_summary> {
    return this.putPmSummaryMfgOverrideResponse(params).pipe(
      __map(_r => _r.body as Pm_summary)
    );
  }

  /**
   * Send PM summary to slack for a sensor (id)
   * @param sensor_id undefined
   */
  putSendPmSummaryResponse(sensorId: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/pm_summary/${encodeURIComponent(String(sensorId))}/send`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Send PM summary to slack for a sensor (id)
   * @param sensor_id undefined
   */
  putSendPmSummary(sensorId: number): __Observable<null> {
    return this.putSendPmSummaryResponse(sensorId).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module PmSummaryService {

  /**
   * Parameters for putPmSummary
   */
  export interface PutPmSummaryParams {
    payload: Pm_summary;
    sensorId: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for putPmSummaryMfgOverride
   */
  export interface PutPmSummaryMfgOverrideParams {
    payload: Pm_summary_mfg_overrides_input;
    sensorId: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }
}

export { PmSummaryService }
