/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiInternalConfiguration as __Configuration } from '../api-internal-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Cp_users } from '../models/cp-_users';
import { Cp_user_create } from '../models/cp-_user-_create';
import { Cp_user_settings } from '../models/cp-_user-_settings';
import { Cp_user_setting_inputs } from '../models/cp-_user-_setting-_inputs';
import { Cp_user_update } from '../models/cp-_user-_update';

/**
 * Customer portal user related operations
 */
@Injectable({
  providedIn: 'root',
})
class CpUsersService extends __BaseService {
  static readonly getCpUsersCpUsersPath = '/cp_users';
  static readonly postCpUsersCpUsersPath = '/cp_users';
  static readonly getCpUserSettingsPath = '/cp_users/settings';
  static readonly putCpUserSettingsPath = '/cp_users/settings';
  static readonly putCpUserCpUsersStringEmailIdPath = '/cp_users/{email_id}';
  static readonly deleteCpUserCpUsersStringEmailIdPath = '/cp_users/{email_id}';
  static readonly getCpUserSettingPath = '/cp_users/{email_id}/settings';
  static readonly putCpUserSettingPath = '/cp_users/{email_id}/settings';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Customer portal users
   * @param params The `CpUsersService.GetCpUsersCpUsersParams` containing the following parameters:
   *
   * - `email_ids`: List of user email ids (Email Address)
   *
   * - `organization_ids`: List of organization ids
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getCpUsersCpUsersResponse(params: CpUsersService.GetCpUsersCpUsersParams): __Observable<__StrictHttpResponse<Cp_users>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.emailIds || []).forEach(val => {if (val != null) __params = __params.append('email_ids', val.toString())});
    (params.organizationIds || []).forEach(val => {if (val != null) __params = __params.append('organization_ids', val.toString())});
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/cp_users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Cp_users>;
      })
    );
  }
  /**
   * Customer portal users
   * @param params The `CpUsersService.GetCpUsersCpUsersParams` containing the following parameters:
   *
   * - `email_ids`: List of user email ids (Email Address)
   *
   * - `organization_ids`: List of organization ids
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getCpUsersCpUsers(params: CpUsersService.GetCpUsersCpUsersParams): __Observable<Cp_users> {
    return this.getCpUsersCpUsersResponse(params).pipe(
      __map(_r => _r.body as Cp_users)
    );
  }

  /**
   * Customer portal users
   * @param payload undefined
   */
  postCpUsersCpUsersResponse(payload: Cp_user_create): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/cp_users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Customer portal users
   * @param payload undefined
   */
  postCpUsersCpUsers(payload: Cp_user_create): __Observable<null> {
    return this.postCpUsersCpUsersResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `CpUsersService.GetCpUserSettingsParams` containing the following parameters:
   *
   * - `keys`: List of keys
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getCpUserSettingsResponse(params: CpUsersService.GetCpUserSettingsParams): __Observable<__StrictHttpResponse<Cp_user_settings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.keys || []).forEach(val => {if (val != null) __params = __params.append('keys', val.toString())});
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/cp_users/settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Cp_user_settings>;
      })
    );
  }
  /**
   * @param params The `CpUsersService.GetCpUserSettingsParams` containing the following parameters:
   *
   * - `keys`: List of keys
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getCpUserSettings(params: CpUsersService.GetCpUserSettingsParams): __Observable<Cp_user_settings> {
    return this.getCpUserSettingsResponse(params).pipe(
      __map(_r => _r.body as Cp_user_settings)
    );
  }

  /**
   * @param payload undefined
   */
  putCpUserSettingsResponse(payload: Cp_user_setting_inputs): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/cp_users/settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param payload undefined
   */
  putCpUserSettings(payload: Cp_user_setting_inputs): __Observable<null> {
    return this.putCpUserSettingsResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Customer portal user
   * @param params The `CpUsersService.PutCpUserCpUsersStringEmailIdParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `email_id`: Email address of the user to update
   */
  putCpUserCpUsersStringEmailIdResponse(params: CpUsersService.PutCpUserCpUsersStringEmailIdParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/cp_users/${encodeURIComponent(String(params.emailId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Customer portal user
   * @param params The `CpUsersService.PutCpUserCpUsersStringEmailIdParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `email_id`: Email address of the user to update
   */
  putCpUserCpUsersStringEmailId(params: CpUsersService.PutCpUserCpUsersStringEmailIdParams): __Observable<null> {
    return this.putCpUserCpUsersStringEmailIdResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Customer portal user
   * @param email_id Email address of the user to update
   */
  deleteCpUserCpUsersStringEmailIdResponse(emailId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/cp_users/${encodeURIComponent(String(emailId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Customer portal user
   * @param email_id Email address of the user to update
   */
  deleteCpUserCpUsersStringEmailId(emailId: string): __Observable<null> {
    return this.deleteCpUserCpUsersStringEmailIdResponse(emailId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `CpUsersService.GetCpUserSettingParams` containing the following parameters:
   *
   * - `email_id`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getCpUserSettingResponse(params: CpUsersService.GetCpUserSettingParams): __Observable<__StrictHttpResponse<Cp_user_settings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/cp_users/${encodeURIComponent(String(params.emailId))}/settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Cp_user_settings>;
      })
    );
  }
  /**
   * @param params The `CpUsersService.GetCpUserSettingParams` containing the following parameters:
   *
   * - `email_id`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getCpUserSetting(params: CpUsersService.GetCpUserSettingParams): __Observable<Cp_user_settings> {
    return this.getCpUserSettingResponse(params).pipe(
      __map(_r => _r.body as Cp_user_settings)
    );
  }

  /**
   * @param params The `CpUsersService.PutCpUserSettingParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `email_id`:
   */
  putCpUserSettingResponse(params: CpUsersService.PutCpUserSettingParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/cp_users/${encodeURIComponent(String(params.emailId))}/settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `CpUsersService.PutCpUserSettingParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `email_id`:
   */
  putCpUserSetting(params: CpUsersService.PutCpUserSettingParams): __Observable<null> {
    return this.putCpUserSettingResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module CpUsersService {

  /**
   * Parameters for getCpUsersCpUsers
   */
  export interface GetCpUsersCpUsersParams {

    /**
     * List of user email ids (Email Address)
     */
    emailIds?: Array<string>;

    /**
     * List of organization ids
     */
    organizationIds?: Array<number>;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for getCpUserSettings
   */
  export interface GetCpUserSettingsParams {

    /**
     * List of keys
     */
    keys?: Array<string>;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for putCpUserCpUsersStringEmailId
   */
  export interface PutCpUserCpUsersStringEmailIdParams {
    payload: Cp_user_update;

    /**
     * Email address of the user to update
     */
    emailId: string;
  }

  /**
   * Parameters for getCpUserSetting
   */
  export interface GetCpUserSettingParams {
    emailId: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for putCpUserSetting
   */
  export interface PutCpUserSettingParams {
    payload: Cp_user_setting_inputs;
    emailId: string;
  }
}

export { CpUsersService }
