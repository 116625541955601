/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Get_organization_parser } from '../models/get-_organization-_parser';
import { Get_organization_address_parser } from '../models/get-_organization-_address-_parser';
import { Organization_settings } from '../models/organization-_settings';
import { Organization_settings_in } from '../models/organization-_settings-_in';
import { Organization_settings_show_partial_results_out } from '../models/organization-_settings-_show-_partial-_results-_out';

/**
 * Organization related operations
 */
@Injectable({
  providedIn: 'root',
})
class OrganizationService extends __BaseService {
  static readonly getOrganizationPath = '/organization/';
  static readonly ListAllOrganizationAddressPath = '/organization/address/';
  static readonly GetOrganizationSettingsPath = '/organization/settings';
  static readonly GetOrganizationSettingDisplayUnitPpbOrPpmPath = '/organization/settings/display_unit';
  static readonly PutOrganizationSettingDisplayUnitPpbOrPpmPath = '/organization/settings/display_unit';
  static readonly GetOrganizationSettingShowPartialResultsPath = '/organization/settings/show_partial_results';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param X-Fields An optional fields mask
   * @return Success
   */
  getOrganizationResponse(XFields?: string): __Observable<__StrictHttpResponse<Array<Get_organization_parser>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (XFields != null) __headers = __headers.set('X-Fields', XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organization/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Get_organization_parser>>;
      })
    );
  }
  /**
   * @param X-Fields An optional fields mask
   * @return Success
   */
  getOrganization(XFields?: string): __Observable<Array<Get_organization_parser>> {
    return this.getOrganizationResponse(XFields).pipe(
      __map(_r => _r.body as Array<Get_organization_parser>)
    );
  }

  /**
   * @param X-Fields An optional fields mask
   * @return Success
   */
  ListAllOrganizationAddressResponse(XFields?: string): __Observable<__StrictHttpResponse<Array<Get_organization_address_parser>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (XFields != null) __headers = __headers.set('X-Fields', XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organization/address/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Get_organization_address_parser>>;
      })
    );
  }
  /**
   * @param X-Fields An optional fields mask
   * @return Success
   */
  ListAllOrganizationAddress(XFields?: string): __Observable<Array<Get_organization_address_parser>> {
    return this.ListAllOrganizationAddressResponse(XFields).pipe(
      __map(_r => _r.body as Array<Get_organization_address_parser>)
    );
  }

  /**
   * @param X-Fields An optional fields mask
   * @return Success
   */
  GetOrganizationSettingsResponse(XFields?: string): __Observable<__StrictHttpResponse<Array<Organization_settings>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (XFields != null) __headers = __headers.set('X-Fields', XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organization/settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Organization_settings>>;
      })
    );
  }
  /**
   * @param X-Fields An optional fields mask
   * @return Success
   */
  GetOrganizationSettings(XFields?: string): __Observable<Array<Organization_settings>> {
    return this.GetOrganizationSettingsResponse(XFields).pipe(
      __map(_r => _r.body as Array<Organization_settings>)
    );
  }

  /**
   * @param X-Fields An optional fields mask
   * @return Success
   */
  GetOrganizationSettingDisplayUnitPpbOrPpmResponse(XFields?: string): __Observable<__StrictHttpResponse<Array<Organization_settings>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (XFields != null) __headers = __headers.set('X-Fields', XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organization/settings/display_unit`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Organization_settings>>;
      })
    );
  }
  /**
   * @param X-Fields An optional fields mask
   * @return Success
   */
  GetOrganizationSettingDisplayUnitPpbOrPpm(XFields?: string): __Observable<Array<Organization_settings>> {
    return this.GetOrganizationSettingDisplayUnitPpbOrPpmResponse(XFields).pipe(
      __map(_r => _r.body as Array<Organization_settings>)
    );
  }

  /**
   * @param payload undefined
   */
  PutOrganizationSettingDisplayUnitPpbOrPpmResponse(payload: Organization_settings_in): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/organization/settings/display_unit`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param payload undefined
   */
  PutOrganizationSettingDisplayUnitPpbOrPpm(payload: Organization_settings_in): __Observable<null> {
    return this.PutOrganizationSettingDisplayUnitPpbOrPpmResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param X-Fields An optional fields mask
   * @return Success
   */
  GetOrganizationSettingShowPartialResultsResponse(XFields?: string): __Observable<__StrictHttpResponse<Array<Organization_settings_show_partial_results_out>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (XFields != null) __headers = __headers.set('X-Fields', XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organization/settings/show_partial_results`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Organization_settings_show_partial_results_out>>;
      })
    );
  }
  /**
   * @param X-Fields An optional fields mask
   * @return Success
   */
  GetOrganizationSettingShowPartialResults(XFields?: string): __Observable<Array<Organization_settings_show_partial_results_out>> {
    return this.GetOrganizationSettingShowPartialResultsResponse(XFields).pipe(
      __map(_r => _r.body as Array<Organization_settings_show_partial_results_out>)
    );
  }
}

module OrganizationService {
}

export { OrganizationService }
