/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Probe_types_list } from '../models/probe-_types-_list';

/**
 * Probe types configuration
 */
@Injectable({
  providedIn: 'root',
})
class ProbeTypesService extends __BaseService {
  static readonly getProbeTypesListPath = '/probe_types';
  static readonly getProbeTypesByDeviceTypePath = '/probe_types/by_device_type';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get all probe types
   * @param X-Fields An optional fields mask
   * @return Success
   */
  getProbeTypesListResponse(XFields?: string): __Observable<__StrictHttpResponse<Probe_types_list>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (XFields != null) __headers = __headers.set('X-Fields', XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/probe_types`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Probe_types_list>;
      })
    );
  }
  /**
   * Get all probe types
   * @param X-Fields An optional fields mask
   * @return Success
   */
  getProbeTypesList(XFields?: string): __Observable<Probe_types_list> {
    return this.getProbeTypesListResponse(XFields).pipe(
      __map(_r => _r.body as Probe_types_list)
    );
  }

  /**
   * Get probe types filtered by device_type
   * @param params The `ProbeTypesService.GetProbeTypesByDeviceTypeParams` containing the following parameters:
   *
   * - `device_type`: Filter by device type (ph_alk_probe_device, do_probe_device, ec_probe_device, orp_probe_device, ph_probe_device, temperature_probe_device)
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getProbeTypesByDeviceTypeResponse(params: ProbeTypesService.GetProbeTypesByDeviceTypeParams): __Observable<__StrictHttpResponse<Probe_types_list>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.deviceType != null) __params = __params.set('device_type', params.deviceType.toString());
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/probe_types/by_device_type`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Probe_types_list>;
      })
    );
  }
  /**
   * Get probe types filtered by device_type
   * @param params The `ProbeTypesService.GetProbeTypesByDeviceTypeParams` containing the following parameters:
   *
   * - `device_type`: Filter by device type (ph_alk_probe_device, do_probe_device, ec_probe_device, orp_probe_device, ph_probe_device, temperature_probe_device)
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getProbeTypesByDeviceType(params: ProbeTypesService.GetProbeTypesByDeviceTypeParams): __Observable<Probe_types_list> {
    return this.getProbeTypesByDeviceTypeResponse(params).pipe(
      __map(_r => _r.body as Probe_types_list)
    );
  }
}

module ProbeTypesService {

  /**
   * Parameters for getProbeTypesByDeviceType
   */
  export interface GetProbeTypesByDeviceTypeParams {

    /**
     * Filter by device type (ph_alk_probe_device, do_probe_device, ec_probe_device, orp_probe_device, ph_probe_device, temperature_probe_device)
     */
    deviceType?: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }
}

export { ProbeTypesService }
