/*
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */
import { Routes } from '@angular/router';
import {MainPage} from './main.page';
import {AuthGuard} from '../../guards/auth-guard.service';
import {LicenseGuard} from '../../../../../web/src/app/guards/license-guard.service';
import {BlankComponent} from '../blank/blank/blank.component';
import {PlatformAuthGuard} from './platformAuthGuard';
export const MainRoutes: Routes = [
    {
      path: 'insights',
      loadChildren: () => import('../insights/insights.module').then(m => m.InsightsPageModule),
      pathMatch: 'full'
    },
    {
      path: '',
      component: MainPage,
      children: [
        {
          path: '',
          component: MainPage,
          canActivate: [PlatformAuthGuard],
          pathMatch: 'full'
        },
        {
          path: 'home',
          children: [{
            path: '',
            loadChildren: () => import('../home/home.module').then(m => m.HomePageModule),
            canActivate: [AuthGuard]
          }],
        },
        {
          path: 'dashboard',
          loadChildren: () => import('../dashboard/dashboard.module').then(m => m.DashboardPageModule),
          canActivate: [AuthGuard],
        },
        {
          path: 'blank',
          canActivate: [AuthGuard],
          component: BlankComponent,
          data: {
            showSubmenu: false
          }
        },
        {
          path: 'alerts',
          loadChildren: () => import('../alerts/alerts.module').then(m => m.AlertsPageModule),
          canActivate: [AuthGuard],
        },
        {
          path: 'users',
          loadChildren: () => import('../users/users.module').then(m => m.UsersPageModule),
          canActivate: [AuthGuard],
          data: {
            showSubmenu: false
          }
        },
        {
          path: 'unit-settings',
          loadChildren: () => import('../unit-settings/unit-settings.module').then(m => m.UnitSettingsPageModule),
          canActivate: [AuthGuard],
          data: {
            showSubmenu: false
          }
        },
        {
          path: 'sensors',
          loadChildren: () => import('../sensors/sensors.module').then(m => m.SensorsPageModule),
          canActivate: [AuthGuard],
          data: {
            showSubmenu: false
          }
        },
        {
          path: 'locations',
          loadChildren: () => import('../locations/locations.module').then(m => m.LocationsPageModule),
          canActivate: [AuthGuard],
          data: {
            showSubmenu: false
          }
        },
        {
          path: 'predictive-modeller',
          canActivate: [AuthGuard, LicenseGuard],
          loadChildren: () => import('../modeller/modeller.module').then(m => m.ModellerModule),
          data: {
            showSubmenu: false
          }
        },
        {
          path: 'operational-intelligence',
          canActivate: [AuthGuard],
          loadChildren: () => import('../operational-intelligence/operational-intelligence.module').then(m => m.OperationalIntelligenceModule),
          data: {
            showSubmenu: true
          }
        },
        {
          path: 'about',
          loadChildren: () => import('../about/about.module').then(m => m.AboutPageModule),
          canActivate: [AuthGuard],
          data: {
            showSubmenu: false
          }
        },
        {
          path: 'profile',
          loadChildren: () => import('../profile/profile.module').then(m => m.ProfilePageModule),
          canActivate: [AuthGuard],
          data: {
            showSubmenu: false
          }
        },
        {
          path: 'support',
          loadChildren: () => import('../support/support.module').then(m => m.SupportPageModule),
          canActivate: [AuthGuard],
          data: {
            showSubmenu: false
          }
        }
      ]
    }
  ];

