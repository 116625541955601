/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiInternalConfiguration as __Configuration } from '../api-internal-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Ec_devices } from '../models/ec-_devices';
import { Ec_probe_device } from '../models/ec-_probe-_device';
import { Ec_probe_device_input } from '../models/ec-_probe-_device-_input';

/**
 * EC probe device related operations
 */
@Injectable({
  providedIn: 'root',
})
class EcProbeDevicesService extends __BaseService {
  static readonly getEcProbeDevicesPath = '/ec_probe_devices';
  static readonly postEcProbeDevicesPath = '/ec_probe_devices';
  static readonly putEcProbeDeviceEcProbeDevicesStringEcProbeDeviceIdPath = '/ec_probe_devices/{ec_probe_device_id}';
  static readonly deleteEcProbeDeviceEcProbeDevicesStringEcProbeDeviceIdPath = '/ec_probe_devices/{ec_probe_device_id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get Devices
   * @param params The `EcProbeDevicesService.GetEcProbeDevicesParams` containing the following parameters:
   *
   * - `ids`: List of EC device ids (Serial Number)
   *
   * - `sensor_ids`: List of sensor ids
   *
   * - `shield_device_ids`: List of shield device serial numbers
   *
   * - `assigned`: Include devices with (True)/without (False) parent device
   *
   * - `include_deleted`: Include deleted
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getEcProbeDevicesResponse(params: EcProbeDevicesService.GetEcProbeDevicesParams): __Observable<__StrictHttpResponse<Ec_devices>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    (params.sensorIds || []).forEach(val => {if (val != null) __params = __params.append('sensor_ids', val.toString())});
    (params.shieldDeviceIds || []).forEach(val => {if (val != null) __params = __params.append('shield_device_ids', val.toString())});
    if (params.assigned != null) __params = __params.set('assigned', params.assigned.toString());
    if (params.includeDeleted != null) __params = __params.set('include_deleted', params.includeDeleted.toString());
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/ec_probe_devices`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Ec_devices>;
      })
    );
  }
  /**
   * Get Devices
   * @param params The `EcProbeDevicesService.GetEcProbeDevicesParams` containing the following parameters:
   *
   * - `ids`: List of EC device ids (Serial Number)
   *
   * - `sensor_ids`: List of sensor ids
   *
   * - `shield_device_ids`: List of shield device serial numbers
   *
   * - `assigned`: Include devices with (True)/without (False) parent device
   *
   * - `include_deleted`: Include deleted
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getEcProbeDevices(params: EcProbeDevicesService.GetEcProbeDevicesParams): __Observable<Ec_devices> {
    return this.getEcProbeDevicesResponse(params).pipe(
      __map(_r => _r.body as Ec_devices)
    );
  }

  /**
   * Create Device
   * @param params The `EcProbeDevicesService.PostEcProbeDevicesParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  postEcProbeDevicesResponse(params: EcProbeDevicesService.PostEcProbeDevicesParams): __Observable<__StrictHttpResponse<Ec_probe_device>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/ec_probe_devices`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Ec_probe_device>;
      })
    );
  }
  /**
   * Create Device
   * @param params The `EcProbeDevicesService.PostEcProbeDevicesParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  postEcProbeDevices(params: EcProbeDevicesService.PostEcProbeDevicesParams): __Observable<Ec_probe_device> {
    return this.postEcProbeDevicesResponse(params).pipe(
      __map(_r => _r.body as Ec_probe_device)
    );
  }

  /**
   * Update Device
   * @param params The `EcProbeDevicesService.PutEcProbeDeviceEcProbeDevicesStringEcProbeDeviceIdParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `ec_probe_device_id`: Device Serial Number
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  putEcProbeDeviceEcProbeDevicesStringEcProbeDeviceIdResponse(params: EcProbeDevicesService.PutEcProbeDeviceEcProbeDevicesStringEcProbeDeviceIdParams): __Observable<__StrictHttpResponse<Ec_probe_device>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/ec_probe_devices/${encodeURIComponent(String(params.ecProbeDeviceId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Ec_probe_device>;
      })
    );
  }
  /**
   * Update Device
   * @param params The `EcProbeDevicesService.PutEcProbeDeviceEcProbeDevicesStringEcProbeDeviceIdParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `ec_probe_device_id`: Device Serial Number
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  putEcProbeDeviceEcProbeDevicesStringEcProbeDeviceId(params: EcProbeDevicesService.PutEcProbeDeviceEcProbeDevicesStringEcProbeDeviceIdParams): __Observable<Ec_probe_device> {
    return this.putEcProbeDeviceEcProbeDevicesStringEcProbeDeviceIdResponse(params).pipe(
      __map(_r => _r.body as Ec_probe_device)
    );
  }

  /**
   * Delete Device
   * @param params The `EcProbeDevicesService.DeleteEcProbeDeviceEcProbeDevicesStringEcProbeDeviceIdParams` containing the following parameters:
   *
   * - `ec_probe_device_id`: Device Serial Number
   *
   * - `reason`: Reason for deleting the device
   */
  deleteEcProbeDeviceEcProbeDevicesStringEcProbeDeviceIdResponse(params: EcProbeDevicesService.DeleteEcProbeDeviceEcProbeDevicesStringEcProbeDeviceIdParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.reason != null) __params = __params.set('reason', params.reason.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/ec_probe_devices/${encodeURIComponent(String(params.ecProbeDeviceId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete Device
   * @param params The `EcProbeDevicesService.DeleteEcProbeDeviceEcProbeDevicesStringEcProbeDeviceIdParams` containing the following parameters:
   *
   * - `ec_probe_device_id`: Device Serial Number
   *
   * - `reason`: Reason for deleting the device
   */
  deleteEcProbeDeviceEcProbeDevicesStringEcProbeDeviceId(params: EcProbeDevicesService.DeleteEcProbeDeviceEcProbeDevicesStringEcProbeDeviceIdParams): __Observable<null> {
    return this.deleteEcProbeDeviceEcProbeDevicesStringEcProbeDeviceIdResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module EcProbeDevicesService {

  /**
   * Parameters for getEcProbeDevices
   */
  export interface GetEcProbeDevicesParams {

    /**
     * List of EC device ids (Serial Number)
     */
    ids?: Array<string>;

    /**
     * List of sensor ids
     */
    sensorIds?: Array<number>;

    /**
     * List of shield device serial numbers
     */
    shieldDeviceIds?: Array<string>;

    /**
     * Include devices with (True)/without (False) parent device
     */
    assigned?: boolean;

    /**
     * Include deleted
     */
    includeDeleted?: boolean;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for postEcProbeDevices
   */
  export interface PostEcProbeDevicesParams {
    payload: Ec_probe_device;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for putEcProbeDeviceEcProbeDevicesStringEcProbeDeviceId
   */
  export interface PutEcProbeDeviceEcProbeDevicesStringEcProbeDeviceIdParams {
    payload: Ec_probe_device_input;

    /**
     * Device Serial Number
     */
    ecProbeDeviceId: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for deleteEcProbeDeviceEcProbeDevicesStringEcProbeDeviceId
   */
  export interface DeleteEcProbeDeviceEcProbeDevicesStringEcProbeDeviceIdParams {

    /**
     * Device Serial Number
     */
    ecProbeDeviceId: string;

    /**
     * Reason for deleting the device
     */
    reason?: string;
  }
}

export { EcProbeDevicesService }
