import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {OptionInterface} from '../multi-select-popover.component';
import {PopoverController} from '@ionic/angular';
import {GroupedSensorPopoverComponent} from '../grouped-sensor-popover/grouped-sensor-popover.component';
import {WaterSourcesCachedService} from '../../../services/water-sources-cached.service';
import {Group_master} from '../../../../../../api/src/lib/models/group-_master';
import {Water_source} from '../../../../../../api/src/lib/models/water-_source';
import {AuthService} from '../../../services/auth.service';
import {SelectPopoverComponent} from '../../select-popover/select-popover.component';
import {AppStateService} from '../../../services/app-state.service';

@Component({
  selector: 'lib-grouped-sensors-select',
  templateUrl: './grouped-sensors-select.component.html',
  styleUrls: ['./grouped-sensors-select.component.scss'],
})
export class GroupedSensorsSelectComponent implements OnInit {
  @Input() selected: OptionInterface[] = [];
  @Input() selectedWaterSourceIds: number[];
  @Input() maxSelected = Number.MAX_SAFE_INTEGER; // for efficiency use one number smaller
  @Input() showConfirm = false;
  @Input() showSelectAll = false;
  @Input() selectAllOnGroupClick: boolean;
  @Output() selectedChange = new EventEmitter<OptionInterface[]>();
  @Output() selectedWaterSourceIdsChange = new EventEmitter<number[]>();
  @Output() allSelectedChanges = new EventEmitter<OptionInterface[]>();
  @ViewChild('popovercontainer') div;

  selectedGroups: Group_master[];

  constructor(private authService: AuthService,
              private popoverController: PopoverController,
              private waterSourcesService: WaterSourcesCachedService,
              ) {
  }

  ngOnInit() {
  }

  groupsSelected = (groups: Group_master[]) => {
    this.selectedGroups = groups;
  }

  async clicked(event: Event) {
    if (this.authService.getUser().role_id > 2) {
      this.openPopover(event);
    } else {
      this.openGroupedPopover(event);
    }
  }

  async openGroupedPopover(event: Event) {

    const groupsEnabled = this.authService.getUser().role_id < 3;
    const {y} = this.div.nativeElement.getBoundingClientRect();

    const cssClassesArray: string[] = [];
    if (window.devicePixelRatio >= 3 || (window.innerHeight - y) < 500 || window.innerHeight < 600) {
      cssClassesArray.push('unset-top-popover');
    }
    if (groupsEnabled) {
      cssClassesArray.push('grouped-popover');
    } else {
      cssClassesArray.push('dark-popover');
    }

    let selected = this.selected?.map(val => val.value)
    if (Array.isArray(this.selectedWaterSourceIds)) {
      selected = this.selectedWaterSourceIds?.map(id => this.waterSourcesService.dictById[id]);
    }

    let latestSelection: Water_source[];
    const popover = await this.popoverController.create({
      component: GroupedSensorPopoverComponent,
      componentProps: {
        titleKey: 'water_source_name',
        selected: selected,
        maxSelected: this.maxSelected,
        showSelectAll: this.showSelectAll,
        selectAllOnGroupClick: this.selectAllOnGroupClick,
        updateSelected: (selected: Water_source[]) => {
          this.allSelectedChanges.emit(selected.map(sel => {return {title: sel.water_source_name, value: sel}}));
          latestSelection = selected;
        },
        groupsChanged: this.groupsSelected
      },
      cssClass: cssClassesArray.join(' '),
      translucent: true,
      event
    });

    popover.onDidDismiss().then(modalData => {
      if (modalData.role !== 'backdrop') {
        if (Array.isArray(modalData?.data)) {
          this.selectedChange.emit(modalData?.data?.map(ws => {return {title: ws.water_source_name, value: ws }}));
          this.selectedWaterSourceIdsChange.emit(modalData?.data?.map(ws => ws.id));
        }
      }
      if (modalData.role === 'backdrop') {
        if (Array.isArray(latestSelection)) {
          this.selectedChange.emit(latestSelection.length===0?this.waterSourcesService.model.map(ws => { return {title: ws.water_source_name, value: ws}}):latestSelection.map(ws => { return {title: ws.water_source_name, value: ws}}));
          this.selectedWaterSourceIdsChange.emit(latestSelection.map(ws => ws.id));
        } else {
          this.selectedChange.emit(this.selected);
          this.selectedWaterSourceIdsChange.emit(this.selected.map(ws => ws.value.id));
        }
      }
    });

    return await popover.present();
  }

  async openPopover(event: Event) {
    let selected = this.selected?.map(val => val.value)
    if (Array.isArray(this.selectedWaterSourceIds)) {
      selected = this.selectedWaterSourceIds?.map(id => this.waterSourcesService.dictById[id]);
    }

    let latestSelection: Water_source[];
    let selectFired = false;
    const {y} = this.div.nativeElement.getBoundingClientRect();
    const popover = await this.popoverController.create({
      component: SelectPopoverComponent,
      componentProps: {
        titleKey: 'water_source_name',
        values: this.waterSourcesService.model,
        selected: selected,
        maxSelected: this.maxSelected,
        showConfirm: this.showConfirm,
        showSelectAll: this.showSelectAll,
        showSearch: true,
        updateSelected: (selected: Water_source[]) => {
          this.allSelectedChanges.emit(selected.map(sel => {return {title: sel.water_source_name, value: sel}}));
          latestSelection = selected;
        }
      },
      cssClass: window.devicePixelRatio >= 3 || (window.innerHeight - y) < 500 || window.innerHeight < 600 ? 'dark-popover unset-top-popover' : 'dark-popover',
      translucent: true,
      event
    });

    popover.onDidDismiss().then(modalData => {
      if (modalData.role !== 'backdrop') {
        if (Array.isArray(modalData?.data)) {
          this.selectedChange.emit(modalData?.data?.map(ws => {return {title: ws.water_source_name, value: ws }}));
          this.selectedWaterSourceIdsChange.emit(modalData?.data?.map(ws => ws.id));
        }
      }
      if (modalData.role === 'backdrop') {
        if (Array.isArray(latestSelection)) {
          this.selectedChange.emit(latestSelection.map(ws => { return {title: ws.water_source_name, value: ws}}));
          this.selectedWaterSourceIdsChange.emit(latestSelection.map(ws => ws.id));
        } else {
          this.selectedChange.emit(this.selected.length===0?this.waterSourcesService.model.map(ws => { return {title: ws.water_source_name, value: ws}}):this.selected);
          this.selectedWaterSourceIdsChange.emit(this.selected.map(ws => ws.value.id));
        }
      }
    });
    return await popover.present();
  }
}
