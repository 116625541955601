/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Shield_device } from '../models/shield-_device';
import { Shield_devices } from '../models/shield-_devices';
import { Shield_device_input } from '../models/shield-_device-_input';

/**
 * Shield device related operations
 */
@Injectable({
  providedIn: 'root',
})
class ShieldDevicesService extends __BaseService {
  static readonly postShieldDevicesPath = '/shield_devices';
  static readonly getShieldDevicesPath = '/shield_devices';
  static readonly putShieldDevicePath = '/shield_devices/{sensor_sn}';
  static readonly putShieldDeviceFirmwarePath = '/shield_devices/{sensor_sn}/firmware/{firmware_type}/{version}';
  static readonly getValidateShieldDevicePath = '/shield_devices/{sensor_sn}/validate_configuration';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Create Device but do not register
   * @param params The `ShieldDevicesService.PostShieldDevicesParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  postShieldDevicesResponse(params: ShieldDevicesService.PostShieldDevicesParams): __Observable<__StrictHttpResponse<Shield_device>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/shield_devices`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Shield_device>;
      })
    );
  }
  /**
   * Create Device but do not register
   * @param params The `ShieldDevicesService.PostShieldDevicesParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  postShieldDevices(params: ShieldDevicesService.PostShieldDevicesParams): __Observable<Shield_device> {
    return this.postShieldDevicesResponse(params).pipe(
      __map(_r => _r.body as Shield_device)
    );
  }

  /**
   * @param params The `ShieldDevicesService.GetShieldDevicesParams` containing the following parameters:
   *
   * - `ids`: List of shield device ids (Serial Number)
   *
   * - `sensor_ids`: List of sensor ids
   *
   * - `operating_statuses`: List of operating statuses (0=End of line, 1=Operational, 2=Maintenance, 3=Decommissioned, 4=Service Ops)
   *
   * - `organization_ids`: List of organization ids
   *
   * - `sorts`: Examples: "timestamp desc", "timestamp asc"
   *
   * - `include_firmware`: include firmware
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldDevicesResponse(params: ShieldDevicesService.GetShieldDevicesParams): __Observable<__StrictHttpResponse<Shield_devices>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    (params.sensorIds || []).forEach(val => {if (val != null) __params = __params.append('sensor_ids', val.toString())});
    (params.operatingStatuses || []).forEach(val => {if (val != null) __params = __params.append('operating_statuses', val.toString())});
    (params.organizationIds || []).forEach(val => {if (val != null) __params = __params.append('organization_ids', val.toString())});
    (params.sorts || []).forEach(val => {if (val != null) __params = __params.append('sorts', val.toString())});
    if (params.includeFirmware != null) __params = __params.set('include_firmware', params.includeFirmware.toString());
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/shield_devices`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Shield_devices>;
      })
    );
  }
  /**
   * @param params The `ShieldDevicesService.GetShieldDevicesParams` containing the following parameters:
   *
   * - `ids`: List of shield device ids (Serial Number)
   *
   * - `sensor_ids`: List of sensor ids
   *
   * - `operating_statuses`: List of operating statuses (0=End of line, 1=Operational, 2=Maintenance, 3=Decommissioned, 4=Service Ops)
   *
   * - `organization_ids`: List of organization ids
   *
   * - `sorts`: Examples: "timestamp desc", "timestamp asc"
   *
   * - `include_firmware`: include firmware
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldDevices(params: ShieldDevicesService.GetShieldDevicesParams): __Observable<Shield_devices> {
    return this.getShieldDevicesResponse(params).pipe(
      __map(_r => _r.body as Shield_devices)
    );
  }

  /**
   * @param params The `ShieldDevicesService.PutShieldDeviceParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `sensor_sn`:
   */
  putShieldDeviceResponse(params: ShieldDevicesService.PutShieldDeviceParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/shield_devices/${encodeURIComponent(String(params.sensorSn))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ShieldDevicesService.PutShieldDeviceParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `sensor_sn`:
   */
  putShieldDevice(params: ShieldDevicesService.PutShieldDeviceParams): __Observable<null> {
    return this.putShieldDeviceResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Upgrade/Downgrade Shield firmware
   * @param params The `ShieldDevicesService.PutShieldDeviceFirmwareParams` containing the following parameters:
   *
   * - `sensor_sn`: Shield Device Serial Number
   *
   * - `firmware_type`: The type of firmware, currently only "EMBEDDED" is valid
   *
   * - `version`: The firmware version number (see: /firmwares endpoint)
   */
  putShieldDeviceFirmwareResponse(params: ShieldDevicesService.PutShieldDeviceFirmwareParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/shield_devices/${encodeURIComponent(String(params.sensorSn))}/firmware/${encodeURIComponent(String(params.firmwareType))}/${encodeURIComponent(String(params.version))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Upgrade/Downgrade Shield firmware
   * @param params The `ShieldDevicesService.PutShieldDeviceFirmwareParams` containing the following parameters:
   *
   * - `sensor_sn`: Shield Device Serial Number
   *
   * - `firmware_type`: The type of firmware, currently only "EMBEDDED" is valid
   *
   * - `version`: The firmware version number (see: /firmwares endpoint)
   */
  putShieldDeviceFirmware(params: ShieldDevicesService.PutShieldDeviceFirmwareParams): __Observable<null> {
    return this.putShieldDeviceFirmwareResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param sensor_sn undefined
   */
  getValidateShieldDeviceResponse(sensorSn: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/shield_devices/${encodeURIComponent(String(sensorSn))}/validate_configuration`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param sensor_sn undefined
   */
  getValidateShieldDevice(sensorSn: string): __Observable<null> {
    return this.getValidateShieldDeviceResponse(sensorSn).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ShieldDevicesService {

  /**
   * Parameters for postShieldDevices
   */
  export interface PostShieldDevicesParams {
    payload: Shield_device;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for getShieldDevices
   */
  export interface GetShieldDevicesParams {

    /**
     * List of shield device ids (Serial Number)
     */
    ids?: Array<string>;

    /**
     * List of sensor ids
     */
    sensorIds?: Array<number>;

    /**
     * List of operating statuses (0=End of line, 1=Operational, 2=Maintenance, 3=Decommissioned, 4=Service Ops)
     */
    operatingStatuses?: Array<number>;

    /**
     * List of organization ids
     */
    organizationIds?: Array<number>;

    /**
     * Examples: "timestamp desc", "timestamp asc"
     */
    sorts?: Array<string>;

    /**
     * include firmware
     */
    includeFirmware?: boolean;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for putShieldDevice
   */
  export interface PutShieldDeviceParams {
    payload: Shield_device_input;
    sensorSn: string;
  }

  /**
   * Parameters for putShieldDeviceFirmware
   */
  export interface PutShieldDeviceFirmwareParams {

    /**
     * Shield Device Serial Number
     */
    sensorSn: string;

    /**
     * The type of firmware, currently only "EMBEDDED" is valid
     */
    firmwareType: string;

    /**
     * The firmware version number (see: /firmwares endpoint)
     */
    version: string;
  }
}

export { ShieldDevicesService }
