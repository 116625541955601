/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Shield_results } from '../models/shield-_results';
import { Continuous_results_input } from '../models/continuous-_results-_input';
import { Result_count } from '../models/result-_count';
import { Results } from '../models/results';
import { Shield_forecasts } from '../models/shield-_forecasts';
import { Shield_forecast_inputs } from '../models/shield-_forecast-_inputs';
import { Shield_result_aggregates } from '../models/shield-_result-_aggregates';
import { Result_input } from '../models/result-_input';
import { Shield_result_led_intensities } from '../models/shield-_result-_led-_intensities';
import { Lsi_result } from '../models/lsi-_result';
import { Result_min_max } from '../models/result-_min-_max';
import { Results_input } from '../models/results-_input';
import { Update_ack } from '../models/update-_ack';
import { Update_exp } from '../models/update-_exp';

/**
 * Test related operations
 */
@Injectable({
  providedIn: 'root',
})
class ResultsService extends __BaseService {
  static readonly getShieldResultsPath = '/results';
  static readonly putShieldResultsPath = '/results';
  static readonly DEPRECATEDINTERNALInsertResultPath = '/results/continuous';
  static readonly GetResultCountsBySensorPath = '/results/counts';
  static readonly GetPaginatedResultsGivenOptionalWaterSourceIdsAndOptionalExperimentTypesPath = '/results/es/{start_date}/{end_date}/{water_source_ids}/{experiment_types}/{page}/{per_page}';
  static readonly postShieldResultForecastPath = '/results/forecasts';
  static readonly getShieldResultsHistogramResultsHistogramIntBucketsPath = '/results/histogram/{buckets}';
  static readonly GetResultsGivenTestIdPath = '/results/integrations/{water_source_id}/{test_id}';
  static readonly GetLatestResultsBySensorPath = '/results/latest/{water_source_ids}';
  static readonly getShieldResultLedIntensitiesPath = '/results/led_intensities';
  static readonly GetTestResultsPath = '/results/lsi/{water_source_id}/{test_number}/';
  static readonly GetHighestAndLowestResultValuesByWaterSourcePath = '/results/min_max/{water_source_ids}/{start_date}/{end_date}';
  static readonly DEPRECATEDINTERNALInsertResult_1Path = '/results/scheduled';
  static readonly getScratchPath = '/results/scratch';
  static readonly TestAcknowledgementPath = '/results/test_ack/';
  static readonly TestUpdateExpPath = '/results/update_exp/';
  static readonly GetResultsGivenResultIdPath = '/results/{result_id}/';
  static readonly getShieldResultRawDataPath = '/results/{sensor_id}/{test_number}/{operating_status}/raw_data';
  static readonly putShieldResultPath = '/results/{shield_result_id}';
  static readonly GetAllResultsGivenOptionalWaterSourceIdsAndOptionalExperimentTypesPath = '/results/{start_date}/{end_date}/{water_source_ids}/{experiment_types}';
  static readonly GetPaginatedResultsGivenOptionalWaterSourceIdsAndOptionalExperimentTypes_1Path = '/results/{start_date}/{end_date}/{water_source_ids}/{experiment_types}/{page}/{per_page}';
  static readonly GetResultsByTestTypePath = '/results/{test_type}/{page}/{per_page}';
  static readonly GetResultsGivenTestNumberPath = '/results/{water_source_id}/{test_number}/';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ResultsService.GetShieldResultsParams` containing the following parameters:
   *
   * - `ids`: List of shield result ids
   *
   * - `sensor_ids`: List of sensor IDs
   *
   * - `water_source_ids`: List of water source ids
   *
   * - `test_numbers`: List of test numbers
   *
   * - `experiments`: List of experiments
   *
   * - `methods`: List of methods
   *
   * - `operating_statuses`: List of operating statuses (0=End of line, 1=Operational, 2=Maintenance, 3=Decommissioned, 4=Service Ops)
   *
   * - `start_timestamp`: Start date (ISO 8601)
   *
   * - `end_timestamp`: End date (ISO 8601)
   *
   * - `time_zone`: Valid values are ISO 8601 UTC offsets, such as +01:00 or -08:00
   *
   * - `is_scheduled`: Is scheduled/manual Shield tests
   *
   * - `is_continuous`: Is continuous parameters
   *
   * - `is_3rd_party`: Is data from 3rd party labs
   *
   * - `include_shield_test_schedule`: Embed associated shield_test_schedule record if any
   *
   * - `scroll_id`: PAGINATION: Pass to get next page for result sets over 10000
   *
   * - `per_page`: PAGINATION: Number of results to return (10000 max)
   *
   * - `page`: PAGINATION: Page number for result sets under 10000
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldResultsResponse(params: ResultsService.GetShieldResultsParams): __Observable<__StrictHttpResponse<Shield_results>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    (params.sensorIds || []).forEach(val => {if (val != null) __params = __params.append('sensor_ids', val.toString())});
    (params.waterSourceIds || []).forEach(val => {if (val != null) __params = __params.append('water_source_ids', val.toString())});
    (params.testNumbers || []).forEach(val => {if (val != null) __params = __params.append('test_numbers', val.toString())});
    (params.experiments || []).forEach(val => {if (val != null) __params = __params.append('experiments', val.toString())});
    (params.methods || []).forEach(val => {if (val != null) __params = __params.append('methods', val.toString())});
    (params.operatingStatuses || []).forEach(val => {if (val != null) __params = __params.append('operating_statuses', val.toString())});
    if (params.startTimestamp != null) __params = __params.set('start_timestamp', params.startTimestamp.toString());
    if (params.endTimestamp != null) __params = __params.set('end_timestamp', params.endTimestamp.toString());
    if (params.timeZone != null) __params = __params.set('time_zone', params.timeZone.toString());
    if (params.isScheduled != null) __params = __params.set('is_scheduled', params.isScheduled.toString());
    if (params.isContinuous != null) __params = __params.set('is_continuous', params.isContinuous.toString());
    if (params.is3rdParty != null) __params = __params.set('is_3rd_party', params.is3rdParty.toString());
    if (params.includeShieldTestSchedule != null) __params = __params.set('include_shield_test_schedule', params.includeShieldTestSchedule.toString());
    if (params.scrollId != null) __params = __params.set('scroll_id', params.scrollId.toString());
    if (params.perPage != null) __params = __params.set('per_page', params.perPage.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/results`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Shield_results>;
      })
    );
  }
  /**
   * @param params The `ResultsService.GetShieldResultsParams` containing the following parameters:
   *
   * - `ids`: List of shield result ids
   *
   * - `sensor_ids`: List of sensor IDs
   *
   * - `water_source_ids`: List of water source ids
   *
   * - `test_numbers`: List of test numbers
   *
   * - `experiments`: List of experiments
   *
   * - `methods`: List of methods
   *
   * - `operating_statuses`: List of operating statuses (0=End of line, 1=Operational, 2=Maintenance, 3=Decommissioned, 4=Service Ops)
   *
   * - `start_timestamp`: Start date (ISO 8601)
   *
   * - `end_timestamp`: End date (ISO 8601)
   *
   * - `time_zone`: Valid values are ISO 8601 UTC offsets, such as +01:00 or -08:00
   *
   * - `is_scheduled`: Is scheduled/manual Shield tests
   *
   * - `is_continuous`: Is continuous parameters
   *
   * - `is_3rd_party`: Is data from 3rd party labs
   *
   * - `include_shield_test_schedule`: Embed associated shield_test_schedule record if any
   *
   * - `scroll_id`: PAGINATION: Pass to get next page for result sets over 10000
   *
   * - `per_page`: PAGINATION: Number of results to return (10000 max)
   *
   * - `page`: PAGINATION: Page number for result sets under 10000
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldResults(params: ResultsService.GetShieldResultsParams): __Observable<Shield_results> {
    return this.getShieldResultsResponse(params).pipe(
      __map(_r => _r.body as Shield_results)
    );
  }

  /**
   * CSV format: sensor_id, test_number, experiment_name, method, value
   * @param file undefined
   */
  putShieldResultsResponse(file: Blob): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (file != null) { __formData.append('file', file as string | Blob);}
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/results`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * CSV format: sensor_id, test_number, experiment_name, method, value
   * @param file undefined
   */
  putShieldResults(file: Blob): __Observable<null> {
    return this.putShieldResultsResponse(file).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Used by EMR
   * @param payload undefined
   */
  DEPRECATEDINTERNALInsertResultResponse(payload: Continuous_results_input): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/results/continuous`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Used by EMR
   * @param payload undefined
   */
  DEPRECATEDINTERNALInsertResult(payload: Continuous_results_input): __Observable<null> {
    return this.DEPRECATEDINTERNALInsertResultResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param X-Fields An optional fields mask
   * @return Success
   */
  GetResultCountsBySensorResponse(XFields?: string): __Observable<__StrictHttpResponse<Array<Result_count>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (XFields != null) __headers = __headers.set('X-Fields', XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/results/counts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Result_count>>;
      })
    );
  }
  /**
   * @param X-Fields An optional fields mask
   * @return Success
   */
  GetResultCountsBySensor(XFields?: string): __Observable<Array<Result_count>> {
    return this.GetResultCountsBySensorResponse(XFields).pipe(
      __map(_r => _r.body as Array<Result_count>)
    );
  }

  /**
   * @param params The `ResultsService.GetPaginatedResultsGivenOptionalWaterSourceIdsAndOptionalExperimentTypesParams` containing the following parameters:
   *
   * - `start_date`: ISO 8601 start date/time or "none" (inclusive)
   *
   * - `end_date`: ISO 8601 end data/time or "none" (inclusive)
   *
   * - `water_source_ids`: Comma separated list of water source IDs or "all"
   *
   * - `experiment_types`: Comma separated list of experiment types "all"
   *
   * - `page`: Page number
   *
   * - `per_page`: Results per page
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetPaginatedResultsGivenOptionalWaterSourceIdsAndOptionalExperimentTypesResponse(params: ResultsService.GetPaginatedResultsGivenOptionalWaterSourceIdsAndOptionalExperimentTypesParams): __Observable<__StrictHttpResponse<Results>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;






    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/results/es/${encodeURIComponent(String(params.startDate))}/${encodeURIComponent(String(params.endDate))}/${encodeURIComponent(String(params.waterSourceIds))}/${encodeURIComponent(String(params.experimentTypes))}/${encodeURIComponent(String(params.page))}/${encodeURIComponent(String(params.perPage))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Results>;
      })
    );
  }
  /**
   * @param params The `ResultsService.GetPaginatedResultsGivenOptionalWaterSourceIdsAndOptionalExperimentTypesParams` containing the following parameters:
   *
   * - `start_date`: ISO 8601 start date/time or "none" (inclusive)
   *
   * - `end_date`: ISO 8601 end data/time or "none" (inclusive)
   *
   * - `water_source_ids`: Comma separated list of water source IDs or "all"
   *
   * - `experiment_types`: Comma separated list of experiment types "all"
   *
   * - `page`: Page number
   *
   * - `per_page`: Results per page
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetPaginatedResultsGivenOptionalWaterSourceIdsAndOptionalExperimentTypes(params: ResultsService.GetPaginatedResultsGivenOptionalWaterSourceIdsAndOptionalExperimentTypesParams): __Observable<Results> {
    return this.GetPaginatedResultsGivenOptionalWaterSourceIdsAndOptionalExperimentTypesResponse(params).pipe(
      __map(_r => _r.body as Results)
    );
  }

  /**
   * Get forecasts for multiple experiments
   * @param params The `ResultsService.PostShieldResultForecastParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  postShieldResultForecastResponse(params: ResultsService.PostShieldResultForecastParams): __Observable<__StrictHttpResponse<Shield_forecasts>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/results/forecasts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Shield_forecasts>;
      })
    );
  }
  /**
   * Get forecasts for multiple experiments
   * @param params The `ResultsService.PostShieldResultForecastParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  postShieldResultForecast(params: ResultsService.PostShieldResultForecastParams): __Observable<Shield_forecasts> {
    return this.postShieldResultForecastResponse(params).pipe(
      __map(_r => _r.body as Shield_forecasts)
    );
  }

  /**
   * @param params The `ResultsService.GetShieldResultsHistogramResultsHistogramIntBucketsParams` containing the following parameters:
   *
   * - `buckets`: How many buckets to return
   *
   * - `ids`: List of shield result ids
   *
   * - `sensor_ids`: List of sensor ids
   *
   * - `water_source_ids`: List of water source ids
   *
   * - `test_numbers`: List of test numbers
   *
   * - `experiments`: List of experiments
   *
   * - `methods`: List of methods
   *
   * - `operating_statuses`: List of operating statuses (0=End of line, 1=Operational, 2=Maintenance, 3=Decommissioned, 4=Service Ops)
   *
   * - `start_timestamp`: Start date (ISO 8601)
   *
   * - `end_timestamp`: End date (ISO 8601)
   *
   * - `time_zone`: Valid values are ISO 8601 UTC offsets, such as +01:00 or -08:00
   *
   * - `snap_to_interval`: Generate and snap to interval based on date range and buckets
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldResultsHistogramResultsHistogramIntBucketsResponse(params: ResultsService.GetShieldResultsHistogramResultsHistogramIntBucketsParams): __Observable<__StrictHttpResponse<Shield_result_aggregates>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    (params.sensorIds || []).forEach(val => {if (val != null) __params = __params.append('sensor_ids', val.toString())});
    (params.waterSourceIds || []).forEach(val => {if (val != null) __params = __params.append('water_source_ids', val.toString())});
    (params.testNumbers || []).forEach(val => {if (val != null) __params = __params.append('test_numbers', val.toString())});
    (params.experiments || []).forEach(val => {if (val != null) __params = __params.append('experiments', val.toString())});
    (params.methods || []).forEach(val => {if (val != null) __params = __params.append('methods', val.toString())});
    (params.operatingStatuses || []).forEach(val => {if (val != null) __params = __params.append('operating_statuses', val.toString())});
    if (params.startTimestamp != null) __params = __params.set('start_timestamp', params.startTimestamp.toString());
    if (params.endTimestamp != null) __params = __params.set('end_timestamp', params.endTimestamp.toString());
    if (params.timeZone != null) __params = __params.set('time_zone', params.timeZone.toString());
    if (params.snapToInterval != null) __params = __params.set('snap_to_interval', params.snapToInterval.toString());
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/results/histogram/${encodeURIComponent(String(params.buckets))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Shield_result_aggregates>;
      })
    );
  }
  /**
   * @param params The `ResultsService.GetShieldResultsHistogramResultsHistogramIntBucketsParams` containing the following parameters:
   *
   * - `buckets`: How many buckets to return
   *
   * - `ids`: List of shield result ids
   *
   * - `sensor_ids`: List of sensor ids
   *
   * - `water_source_ids`: List of water source ids
   *
   * - `test_numbers`: List of test numbers
   *
   * - `experiments`: List of experiments
   *
   * - `methods`: List of methods
   *
   * - `operating_statuses`: List of operating statuses (0=End of line, 1=Operational, 2=Maintenance, 3=Decommissioned, 4=Service Ops)
   *
   * - `start_timestamp`: Start date (ISO 8601)
   *
   * - `end_timestamp`: End date (ISO 8601)
   *
   * - `time_zone`: Valid values are ISO 8601 UTC offsets, such as +01:00 or -08:00
   *
   * - `snap_to_interval`: Generate and snap to interval based on date range and buckets
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldResultsHistogramResultsHistogramIntBuckets(params: ResultsService.GetShieldResultsHistogramResultsHistogramIntBucketsParams): __Observable<Shield_result_aggregates> {
    return this.getShieldResultsHistogramResultsHistogramIntBucketsResponse(params).pipe(
      __map(_r => _r.body as Shield_result_aggregates)
    );
  }

  /**
   * @param params The `ResultsService.GetResultsGivenTestIdParams` containing the following parameters:
   *
   * - `water_source_id`: Water Source ID
   *
   * - `test_id`: Test ID (External, lab or device)
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetResultsGivenTestIdResponse(params: ResultsService.GetResultsGivenTestIdParams): __Observable<__StrictHttpResponse<Result_input>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/results/integrations/${encodeURIComponent(String(params.waterSourceId))}/${encodeURIComponent(String(params.testId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Result_input>;
      })
    );
  }
  /**
   * @param params The `ResultsService.GetResultsGivenTestIdParams` containing the following parameters:
   *
   * - `water_source_id`: Water Source ID
   *
   * - `test_id`: Test ID (External, lab or device)
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetResultsGivenTestId(params: ResultsService.GetResultsGivenTestIdParams): __Observable<Result_input> {
    return this.GetResultsGivenTestIdResponse(params).pipe(
      __map(_r => _r.body as Result_input)
    );
  }

  /**
   * @param params The `ResultsService.GetLatestResultsBySensorParams` containing the following parameters:
   *
   * - `water_source_ids`: Comma separated list of water source IDs or "all"
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetLatestResultsBySensorResponse(params: ResultsService.GetLatestResultsBySensorParams): __Observable<__StrictHttpResponse<Array<Result_input>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/results/latest/${encodeURIComponent(String(params.waterSourceIds))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Result_input>>;
      })
    );
  }
  /**
   * @param params The `ResultsService.GetLatestResultsBySensorParams` containing the following parameters:
   *
   * - `water_source_ids`: Comma separated list of water source IDs or "all"
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetLatestResultsBySensor(params: ResultsService.GetLatestResultsBySensorParams): __Observable<Array<Result_input>> {
    return this.GetLatestResultsBySensorResponse(params).pipe(
      __map(_r => _r.body as Array<Result_input>)
    );
  }

  /**
   * @param params The `ResultsService.GetShieldResultLedIntensitiesParams` containing the following parameters:
   *
   * - `sensor_id`: Sensor ID
   *
   * - `start_test_number`: Inclusive starting test number
   *
   * - `end_test_number`: Inclusive ending test number
   *
   * - `experiment`: Experiment name: ("lead", "copper", ...)
   *
   * - `operating_status`: Operating status
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldResultLedIntensitiesResponse(params: ResultsService.GetShieldResultLedIntensitiesParams): __Observable<__StrictHttpResponse<Shield_result_led_intensities>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sensorId != null) __params = __params.set('sensor_id', params.sensorId.toString());
    if (params.startTestNumber != null) __params = __params.set('start_test_number', params.startTestNumber.toString());
    if (params.endTestNumber != null) __params = __params.set('end_test_number', params.endTestNumber.toString());
    if (params.experiment != null) __params = __params.set('experiment', params.experiment.toString());
    if (params.operatingStatus != null) __params = __params.set('operating_status', params.operatingStatus.toString());
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/results/led_intensities`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Shield_result_led_intensities>;
      })
    );
  }
  /**
   * @param params The `ResultsService.GetShieldResultLedIntensitiesParams` containing the following parameters:
   *
   * - `sensor_id`: Sensor ID
   *
   * - `start_test_number`: Inclusive starting test number
   *
   * - `end_test_number`: Inclusive ending test number
   *
   * - `experiment`: Experiment name: ("lead", "copper", ...)
   *
   * - `operating_status`: Operating status
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldResultLedIntensities(params: ResultsService.GetShieldResultLedIntensitiesParams): __Observable<Shield_result_led_intensities> {
    return this.getShieldResultLedIntensitiesResponse(params).pipe(
      __map(_r => _r.body as Shield_result_led_intensities)
    );
  }

  /**
   * @param params The `ResultsService.GetTestResultsParams` containing the following parameters:
   *
   * - `water_source_id`:
   *
   * - `test_number`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetTestResultsResponse(params: ResultsService.GetTestResultsParams): __Observable<__StrictHttpResponse<Array<Lsi_result>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/results/lsi/${encodeURIComponent(String(params.waterSourceId))}/${encodeURIComponent(String(params.testNumber))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Lsi_result>>;
      })
    );
  }
  /**
   * @param params The `ResultsService.GetTestResultsParams` containing the following parameters:
   *
   * - `water_source_id`:
   *
   * - `test_number`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetTestResults(params: ResultsService.GetTestResultsParams): __Observable<Array<Lsi_result>> {
    return this.GetTestResultsResponse(params).pipe(
      __map(_r => _r.body as Array<Lsi_result>)
    );
  }

  /**
   * @param params The `ResultsService.GetHighestAndLowestResultValuesByWaterSourceParams` containing the following parameters:
   *
   * - `water_source_ids`: Comma separated list of water source IDs or "all"
   *
   * - `start_date`: ISO 8601 start date/time or "none" (inclusive)
   *
   * - `end_date`: ISO 8601 end data/time or "none" (inclusive)
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetHighestAndLowestResultValuesByWaterSourceResponse(params: ResultsService.GetHighestAndLowestResultValuesByWaterSourceParams): __Observable<__StrictHttpResponse<Array<Result_min_max>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/results/min_max/${encodeURIComponent(String(params.waterSourceIds))}/${encodeURIComponent(String(params.startDate))}/${encodeURIComponent(String(params.endDate))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Result_min_max>>;
      })
    );
  }
  /**
   * @param params The `ResultsService.GetHighestAndLowestResultValuesByWaterSourceParams` containing the following parameters:
   *
   * - `water_source_ids`: Comma separated list of water source IDs or "all"
   *
   * - `start_date`: ISO 8601 start date/time or "none" (inclusive)
   *
   * - `end_date`: ISO 8601 end data/time or "none" (inclusive)
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetHighestAndLowestResultValuesByWaterSource(params: ResultsService.GetHighestAndLowestResultValuesByWaterSourceParams): __Observable<Array<Result_min_max>> {
    return this.GetHighestAndLowestResultValuesByWaterSourceResponse(params).pipe(
      __map(_r => _r.body as Array<Result_min_max>)
    );
  }

  /**
   * Used by EMR
   * @param payload undefined
   */
  DEPRECATEDINTERNALInsertResult_1Response(payload: Results_input): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/results/scheduled`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Used by EMR
   * @param payload undefined
   */
  DEPRECATEDINTERNALInsertResult_1(payload: Results_input): __Observable<null> {
    return this.DEPRECATEDINTERNALInsertResult_1Response(payload).pipe(
      __map(_r => _r.body as null)
    );
  }
  getScratchResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/results/scratch`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  getScratch(): __Observable<null> {
    return this.getScratchResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Used by EMR
   * @param payload undefined
   */
  TestAcknowledgementResponse(payload: Update_ack): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/results/test_ack/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Used by EMR
   * @param payload undefined
   */
  TestAcknowledgement(payload: Update_ack): __Observable<null> {
    return this.TestAcknowledgementResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Used by EMR
   * @param payload undefined
   */
  TestUpdateExpResponse(payload: Update_exp): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/results/update_exp/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Used by EMR
   * @param payload undefined
   */
  TestUpdateExp(payload: Update_exp): __Observable<null> {
    return this.TestUpdateExpResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ResultsService.GetResultsGivenResultIdParams` containing the following parameters:
   *
   * - `result_id`: Result ID
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetResultsGivenResultIdResponse(params: ResultsService.GetResultsGivenResultIdParams): __Observable<__StrictHttpResponse<Result_input>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/results/${encodeURIComponent(String(params.resultId))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Result_input>;
      })
    );
  }
  /**
   * @param params The `ResultsService.GetResultsGivenResultIdParams` containing the following parameters:
   *
   * - `result_id`: Result ID
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetResultsGivenResultId(params: ResultsService.GetResultsGivenResultIdParams): __Observable<Result_input> {
    return this.GetResultsGivenResultIdResponse(params).pipe(
      __map(_r => _r.body as Result_input)
    );
  }

  /**
   * @param params The `ResultsService.GetShieldResultRawDataParams` containing the following parameters:
   *
   * - `sensor_id`:
   *
   * - `test_number`:
   *
   * - `operating_status`:
   */
  getShieldResultRawDataResponse(params: ResultsService.GetShieldResultRawDataParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/results/${encodeURIComponent(String(params.sensorId))}/${encodeURIComponent(String(params.testNumber))}/${encodeURIComponent(String(params.operatingStatus))}/raw_data`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ResultsService.GetShieldResultRawDataParams` containing the following parameters:
   *
   * - `sensor_id`:
   *
   * - `test_number`:
   *
   * - `operating_status`:
   */
  getShieldResultRawData(params: ResultsService.GetShieldResultRawDataParams): __Observable<null> {
    return this.getShieldResultRawDataResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ResultsService.PutShieldResultParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `shield_result_id`:
   */
  putShieldResultResponse(params: ResultsService.PutShieldResultParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/results/${encodeURIComponent(String(params.shieldResultId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ResultsService.PutShieldResultParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `shield_result_id`:
   */
  putShieldResult(params: ResultsService.PutShieldResultParams): __Observable<null> {
    return this.putShieldResultResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ResultsService.GetAllResultsGivenOptionalWaterSourceIdsAndOptionalExperimentTypesParams` containing the following parameters:
   *
   * - `start_date`: ISO 8601 start date/time or "none" (inclusive)
   *
   * - `end_date`: ISO 8601 end data/time or "none" (inclusive)
   *
   * - `water_source_ids`: Comma separated list of water source IDs or "all"
   *
   * - `experiment_types`: Comma separated list of experiment types "all"
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetAllResultsGivenOptionalWaterSourceIdsAndOptionalExperimentTypesResponse(params: ResultsService.GetAllResultsGivenOptionalWaterSourceIdsAndOptionalExperimentTypesParams): __Observable<__StrictHttpResponse<Array<Result_input>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;




    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/results/${encodeURIComponent(String(params.startDate))}/${encodeURIComponent(String(params.endDate))}/${encodeURIComponent(String(params.waterSourceIds))}/${encodeURIComponent(String(params.experimentTypes))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Result_input>>;
      })
    );
  }
  /**
   * @param params The `ResultsService.GetAllResultsGivenOptionalWaterSourceIdsAndOptionalExperimentTypesParams` containing the following parameters:
   *
   * - `start_date`: ISO 8601 start date/time or "none" (inclusive)
   *
   * - `end_date`: ISO 8601 end data/time or "none" (inclusive)
   *
   * - `water_source_ids`: Comma separated list of water source IDs or "all"
   *
   * - `experiment_types`: Comma separated list of experiment types "all"
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetAllResultsGivenOptionalWaterSourceIdsAndOptionalExperimentTypes(params: ResultsService.GetAllResultsGivenOptionalWaterSourceIdsAndOptionalExperimentTypesParams): __Observable<Array<Result_input>> {
    return this.GetAllResultsGivenOptionalWaterSourceIdsAndOptionalExperimentTypesResponse(params).pipe(
      __map(_r => _r.body as Array<Result_input>)
    );
  }

  /**
   * @param params The `ResultsService.GetPaginatedResultsGivenOptionalWaterSourceIdsAndOptionalExperimentTypes_1Params` containing the following parameters:
   *
   * - `start_date`: ISO 8601 start date/time or "none" (inclusive)
   *
   * - `end_date`: ISO 8601 end data/time or "none" (inclusive)
   *
   * - `water_source_ids`: Comma separated list of water source IDs or "all"
   *
   * - `experiment_types`: Comma separated list of experiment types "all"
   *
   * - `page`: Page number
   *
   * - `per_page`: Results per page
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetPaginatedResultsGivenOptionalWaterSourceIdsAndOptionalExperimentTypes_1Response(params: ResultsService.GetPaginatedResultsGivenOptionalWaterSourceIdsAndOptionalExperimentTypes_1Params): __Observable<__StrictHttpResponse<Results>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;






    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/results/${encodeURIComponent(String(params.startDate))}/${encodeURIComponent(String(params.endDate))}/${encodeURIComponent(String(params.waterSourceIds))}/${encodeURIComponent(String(params.experimentTypes))}/${encodeURIComponent(String(params.page))}/${encodeURIComponent(String(params.perPage))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Results>;
      })
    );
  }
  /**
   * @param params The `ResultsService.GetPaginatedResultsGivenOptionalWaterSourceIdsAndOptionalExperimentTypes_1Params` containing the following parameters:
   *
   * - `start_date`: ISO 8601 start date/time or "none" (inclusive)
   *
   * - `end_date`: ISO 8601 end data/time or "none" (inclusive)
   *
   * - `water_source_ids`: Comma separated list of water source IDs or "all"
   *
   * - `experiment_types`: Comma separated list of experiment types "all"
   *
   * - `page`: Page number
   *
   * - `per_page`: Results per page
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetPaginatedResultsGivenOptionalWaterSourceIdsAndOptionalExperimentTypes_1(params: ResultsService.GetPaginatedResultsGivenOptionalWaterSourceIdsAndOptionalExperimentTypes_1Params): __Observable<Results> {
    return this.GetPaginatedResultsGivenOptionalWaterSourceIdsAndOptionalExperimentTypes_1Response(params).pipe(
      __map(_r => _r.body as Results)
    );
  }

  /**
   * @param params The `ResultsService.GetResultsByTestTypeParams` containing the following parameters:
   *
   * - `test_type`: Test Type m=Manual, s=Scheduled, c=Continuous
   *
   * - `page`: Page number
   *
   * - `per_page`: Results per page
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetResultsByTestTypeResponse(params: ResultsService.GetResultsByTestTypeParams): __Observable<__StrictHttpResponse<Results>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/results/${encodeURIComponent(String(params.testType))}/${encodeURIComponent(String(params.page))}/${encodeURIComponent(String(params.perPage))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Results>;
      })
    );
  }
  /**
   * @param params The `ResultsService.GetResultsByTestTypeParams` containing the following parameters:
   *
   * - `test_type`: Test Type m=Manual, s=Scheduled, c=Continuous
   *
   * - `page`: Page number
   *
   * - `per_page`: Results per page
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetResultsByTestType(params: ResultsService.GetResultsByTestTypeParams): __Observable<Results> {
    return this.GetResultsByTestTypeResponse(params).pipe(
      __map(_r => _r.body as Results)
    );
  }

  /**
   * @param params The `ResultsService.GetResultsGivenTestNumberParams` containing the following parameters:
   *
   * - `water_source_id`: Water Source ID
   *
   * - `test_number`: Test Number
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetResultsGivenTestNumberResponse(params: ResultsService.GetResultsGivenTestNumberParams): __Observable<__StrictHttpResponse<Result_input>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/results/${encodeURIComponent(String(params.waterSourceId))}/${encodeURIComponent(String(params.testNumber))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Result_input>;
      })
    );
  }
  /**
   * @param params The `ResultsService.GetResultsGivenTestNumberParams` containing the following parameters:
   *
   * - `water_source_id`: Water Source ID
   *
   * - `test_number`: Test Number
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetResultsGivenTestNumber(params: ResultsService.GetResultsGivenTestNumberParams): __Observable<Result_input> {
    return this.GetResultsGivenTestNumberResponse(params).pipe(
      __map(_r => _r.body as Result_input)
    );
  }
}

module ResultsService {

  /**
   * Parameters for getShieldResults
   */
  export interface GetShieldResultsParams {

    /**
     * List of shield result ids
     */
    ids?: Array<number>;

    /**
     * List of sensor IDs
     */
    sensorIds?: Array<number>;

    /**
     * List of water source ids
     */
    waterSourceIds?: Array<number>;

    /**
     * List of test numbers
     */
    testNumbers?: Array<number>;

    /**
     * List of experiments
     */
    experiments?: Array<string>;

    /**
     * List of methods
     */
    methods?: Array<string>;

    /**
     * List of operating statuses (0=End of line, 1=Operational, 2=Maintenance, 3=Decommissioned, 4=Service Ops)
     */
    operatingStatuses?: Array<number>;

    /**
     * Start date (ISO 8601)
     */
    startTimestamp?: string;

    /**
     * End date (ISO 8601)
     */
    endTimestamp?: string;

    /**
     * Valid values are ISO 8601 UTC offsets, such as +01:00 or -08:00
     */
    timeZone?: string;

    /**
     * Is scheduled/manual Shield tests
     */
    isScheduled?: boolean;

    /**
     * Is continuous parameters
     */
    isContinuous?: boolean;

    /**
     * Is data from 3rd party labs
     */
    is3rdParty?: boolean;

    /**
     * Embed associated shield_test_schedule record if any
     */
    includeShieldTestSchedule?: boolean;

    /**
     * PAGINATION: Pass to get next page for result sets over 10000
     */
    scrollId?: string;

    /**
     * PAGINATION: Number of results to return (10000 max)
     */
    perPage?: number;

    /**
     * PAGINATION: Page number for result sets under 10000
     */
    page?: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for GetPaginatedResultsGivenOptionalWaterSourceIdsAndOptionalExperimentTypes
   */
  export interface GetPaginatedResultsGivenOptionalWaterSourceIdsAndOptionalExperimentTypesParams {

    /**
     * ISO 8601 start date/time or "none" (inclusive)
     */
    startDate: string;

    /**
     * ISO 8601 end data/time or "none" (inclusive)
     */
    endDate: string;

    /**
     * Comma separated list of water source IDs or "all"
     */
    waterSourceIds: string;

    /**
     * Comma separated list of experiment types "all"
     */
    experimentTypes: string;

    /**
     * Page number
     */
    page: number;

    /**
     * Results per page
     */
    perPage: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for postShieldResultForecast
   */
  export interface PostShieldResultForecastParams {
    payload: Shield_forecast_inputs;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for getShieldResultsHistogramResultsHistogramIntBuckets
   */
  export interface GetShieldResultsHistogramResultsHistogramIntBucketsParams {

    /**
     * How many buckets to return
     */
    buckets: number;

    /**
     * List of shield result ids
     */
    ids?: Array<number>;

    /**
     * List of sensor ids
     */
    sensorIds?: Array<number>;

    /**
     * List of water source ids
     */
    waterSourceIds?: Array<number>;

    /**
     * List of test numbers
     */
    testNumbers?: Array<number>;

    /**
     * List of experiments
     */
    experiments?: Array<string>;

    /**
     * List of methods
     */
    methods?: Array<string>;

    /**
     * List of operating statuses (0=End of line, 1=Operational, 2=Maintenance, 3=Decommissioned, 4=Service Ops)
     */
    operatingStatuses?: Array<number>;

    /**
     * Start date (ISO 8601)
     */
    startTimestamp?: string;

    /**
     * End date (ISO 8601)
     */
    endTimestamp?: string;

    /**
     * Valid values are ISO 8601 UTC offsets, such as +01:00 or -08:00
     */
    timeZone?: string;

    /**
     * Generate and snap to interval based on date range and buckets
     */
    snapToInterval?: boolean;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for GetResultsGivenTestId
   */
  export interface GetResultsGivenTestIdParams {

    /**
     * Water Source ID
     */
    waterSourceId: number;

    /**
     * Test ID (External, lab or device)
     */
    testId: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for GetLatestResultsBySensor
   */
  export interface GetLatestResultsBySensorParams {

    /**
     * Comma separated list of water source IDs or "all"
     */
    waterSourceIds: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for getShieldResultLedIntensities
   */
  export interface GetShieldResultLedIntensitiesParams {

    /**
     * Sensor ID
     */
    sensorId?: number;

    /**
     * Inclusive starting test number
     */
    startTestNumber?: number;

    /**
     * Inclusive ending test number
     */
    endTestNumber?: number;

    /**
     * Experiment name: ("lead", "copper", ...)
     */
    experiment?: string;

    /**
     * Operating status
     */
    operatingStatus?: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for GetTestResults
   */
  export interface GetTestResultsParams {
    waterSourceId: number;
    testNumber: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for GetHighestAndLowestResultValuesByWaterSource
   */
  export interface GetHighestAndLowestResultValuesByWaterSourceParams {

    /**
     * Comma separated list of water source IDs or "all"
     */
    waterSourceIds: string;

    /**
     * ISO 8601 start date/time or "none" (inclusive)
     */
    startDate: string;

    /**
     * ISO 8601 end data/time or "none" (inclusive)
     */
    endDate: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for GetResultsGivenResultId
   */
  export interface GetResultsGivenResultIdParams {

    /**
     * Result ID
     */
    resultId: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for getShieldResultRawData
   */
  export interface GetShieldResultRawDataParams {
    sensorId: number;
    testNumber: number;
    operatingStatus: number;
  }

  /**
   * Parameters for putShieldResult
   */
  export interface PutShieldResultParams {
    payload: Result_input;
    shieldResultId: string;
  }

  /**
   * Parameters for GetAllResultsGivenOptionalWaterSourceIdsAndOptionalExperimentTypes
   */
  export interface GetAllResultsGivenOptionalWaterSourceIdsAndOptionalExperimentTypesParams {

    /**
     * ISO 8601 start date/time or "none" (inclusive)
     */
    startDate: string;

    /**
     * ISO 8601 end data/time or "none" (inclusive)
     */
    endDate: string;

    /**
     * Comma separated list of water source IDs or "all"
     */
    waterSourceIds: string;

    /**
     * Comma separated list of experiment types "all"
     */
    experimentTypes: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for GetPaginatedResultsGivenOptionalWaterSourceIdsAndOptionalExperimentTypes_1
   */
  export interface GetPaginatedResultsGivenOptionalWaterSourceIdsAndOptionalExperimentTypes_1Params {

    /**
     * ISO 8601 start date/time or "none" (inclusive)
     */
    startDate: string;

    /**
     * ISO 8601 end data/time or "none" (inclusive)
     */
    endDate: string;

    /**
     * Comma separated list of water source IDs or "all"
     */
    waterSourceIds: string;

    /**
     * Comma separated list of experiment types "all"
     */
    experimentTypes: string;

    /**
     * Page number
     */
    page: number;

    /**
     * Results per page
     */
    perPage: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for GetResultsByTestType
   */
  export interface GetResultsByTestTypeParams {

    /**
     * Test Type m=Manual, s=Scheduled, c=Continuous
     */
    testType: string;

    /**
     * Page number
     */
    page: number;

    /**
     * Results per page
     */
    perPage: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for GetResultsGivenTestNumber
   */
  export interface GetResultsGivenTestNumberParams {

    /**
     * Water Source ID
     */
    waterSourceId: number;

    /**
     * Test Number
     */
    testNumber: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }
}

export { ResultsService }
