/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiInternalConfiguration as __Configuration } from '../api-internal-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Create_user_parser } from '../models/create-_user-_parser';
import { Delete_user_parser } from '../models/delete-_user-_parser';
import { Get_user_roles } from '../models/get-_user-_roles';

/**
 * Internal user
 */
@Injectable({
  providedIn: 'root',
})
class InternalUserService extends __BaseService {
  static readonly getUsersPath = '/internal_user/';
  static readonly putUsersPath = '/internal_user/';
  static readonly deleteUsersPath = '/internal_user/';
  static readonly getGetUserListPath = '/internal_user/all';
  static readonly GetUserDetailsPath = '/internal_user/get/';
  static readonly getGetUserRolesPath = '/internal_user/roles';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }
  getUsersResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/internal_user/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  getUsers(): __Observable<null> {
    return this.getUsersResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param payload undefined
   */
  putUsersResponse(payload: Create_user_parser): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/internal_user/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param payload undefined
   */
  putUsers(payload: Create_user_parser): __Observable<null> {
    return this.putUsersResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param payload undefined
   */
  deleteUsersResponse(payload: Delete_user_parser): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/internal_user/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param payload undefined
   */
  deleteUsers(payload: Delete_user_parser): __Observable<null> {
    return this.deleteUsersResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }
  getGetUserListResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/internal_user/all`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  getGetUserList(): __Observable<null> {
    return this.getGetUserListResponse().pipe(
      __map(_r => _r.body as null)
    );
  }
  GetUserDetailsResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/internal_user/get/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  GetUserDetails(): __Observable<null> {
    return this.GetUserDetailsResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param X-Fields An optional fields mask
   * @return Success
   */
  getGetUserRolesResponse(XFields?: string): __Observable<__StrictHttpResponse<Array<Get_user_roles>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (XFields != null) __headers = __headers.set('X-Fields', XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/internal_user/roles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Get_user_roles>>;
      })
    );
  }
  /**
   * @param X-Fields An optional fields mask
   * @return Success
   */
  getGetUserRoles(XFields?: string): __Observable<Array<Get_user_roles>> {
    return this.getGetUserRolesResponse(XFields).pipe(
      __map(_r => _r.body as Array<Get_user_roles>)
    );
  }
}

module InternalUserService {
}

export { InternalUserService }
