/*
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CALENDAR } from './models/calendar.constant';
import { isDate } from 'moment';
import { Subscription, timer } from 'rxjs';
import {RoundToInterval} from '../../utils/TimeHelper';

export interface CalendarValueInterface {
  min: Date;
  max: Date;
  defaultValue?: boolean;
}

export interface CalendarStateInterface {
  min: {
    hours: {
      selected: number,
      hours: number[],
      displayHours: number[]
    },
    minutes: {
      selected: number,
      minutes: string[],
      displayMinutes: number[]
    }
  };
  max: {
    hours: {
      selected: number,
      hours: number[],
      displayHours: number[]
    },
    minutes: {
      selected: number,
      minutes: string[],
      displayMinutes: number[]
    }
  };
}

@Component({
  selector: 'lib-calendar',
  templateUrl: './calendar.page.html',
  styleUrls: ['./calendar.page.scss']
})
export class CalendarPage implements OnInit, OnDestroy {

  readonly msInterval = 15 * 60 * 1000; // 15 minute intervals in minute selector
  msIntervalTimer: Subscription;
  private _value: CalendarValueInterface | Date;
  @Input() set value(value: CalendarValueInterface | Date) {
    this._value = value;

    this.init();
  }

  get value(): CalendarValueInterface | Date {
    if (this.instanceOfCalendarValueInterface(this._value)) {
      return {
        min: RoundToInterval(this._value.min, this.msInterval),
        max: RoundToInterval(this._value.max, this.msInterval)
      };
    } else if (isDate(this._value)) {
      return {
        min: RoundToInterval(this._value, this.msInterval),
        max: null
      };
    } else {
      return {
        min: null,
        max: null
      };
    }
  }

  @Input() popoverMode = false;
  @Input() popoverDateSelected: (date: CalendarValueInterface | Date) => {};
  @Input() selectRange: boolean;  // note: if selectRange = false then typeof value = Date

  private _endTimeIndefinite: boolean;
  @Input() set endTimeIndefinite(value: boolean) {
    this._endTimeIndefinite = value;
    this.init();
  }
  get endTimeIndefinite(): boolean {
    return this._endTimeIndefinite;
  }
  private _endTimeNow: boolean;
  @Input() set endTimeNow(value: boolean) {
    this._endTimeNow = value;
    // Hide calendar if visible when forecast is enabled
    value && this.isCalendarVisible ? this.isCalendarVisible = false : '';
    this.init();
  }
  get endTimeNow(): boolean {
    return this._endTimeNow;
  }
  @Input() calendarTitle: string;
  @Input() forceMinutes: boolean; // only applies if selectRange = true
  @Input() hidePreviousDates: boolean;
  @Input() hideFutureDates: boolean;
  @Input() isCalendarVisible: boolean;
  @Input() emitOnDateChange: boolean;
  @Input() hideMinutes: boolean; // hides minutes and hours
  @Input() showHours: boolean; // if (true && hideMinutes === true && selectRange === false) show hours only
  @Input() hideWeekends: boolean;
  @Output() isCalendarVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() calendarRight: boolean;
  @Output() dateSelected: EventEmitter<CalendarValueInterface | Date> =
    new EventEmitter<CalendarValueInterface | Date>();
  @Input() hideDefaultButton: boolean;

  months = CALENDAR.months;
  daysName = CALENDAR.days;

  totalHoursDisplay = 6;
  dateIntervalFlag = 'max';

  totalHours;
  interval;
  selectedDay;
  month;
  monthName;
  year;
  weeks;
  dayModel;

  intervalHours = {
    min: {
      selected: null,
      displayHours: []
    },
    max: {
      selected: null,
      displayHours: []
    },
  };

  hoursAndMinutes = {
    min: {
      hours: {
        selected: null,
        hours: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
        displayHours: [0, 1, 2, 3]
      },
      minutes: {
        selected: null,
        minutes: ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'],
        displayMinutes: [0, 15, 30, 45]
      }
    },
    max: {
      hours: {
        selected: null,
        hours: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
        displayHours: [0, 1, 2, 3]
      },
      minutes: {
        selected: null,
        minutes: ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'],
        displayMinutes: [0, 15, 30, 45]
      }
    }
  };

  today = new Date();
  currentDate = {
    selectedDay: this.today.getDate(),
    month: this.today.getMonth(),
    monthName: this.months[this.today.getMonth()],
    year: this.today.getFullYear()
  };

  constructor() {
  }

  ngOnInit() {
    // Get the start time
    const now = new Date();
    const then = RoundToInterval(now, this.msInterval);
    this.msIntervalTimer = timer(then.getTime() - now.getTime(), this.msInterval)
      .subscribe({
        next: (result: any) => {
          console.log(result); // @todo Update opacity
        }
      });

    this.totalHours = [];
    const hoursArray: any = new Array(24);
    hoursArray.fill('');
    hoursArray.forEach((item, index) => {
      const hour = (1e4 + '' + index).slice(-2).concat(':00');
      this.totalHours.push(hour);
    });

    this.init();
  }

  ngOnDestroy(): void {
    this.msIntervalTimer.unsubscribe();
  }

  init() {
    if ((this.value as CalendarValueInterface).min === null && (this.value as CalendarValueInterface).max === null) {
      return;
    }
    if (this.selectRange) {
      this.interval = {
        min: new Date((this.value as CalendarValueInterface).min.getTime()),
        displayMin: new Date((this.value as CalendarValueInterface).min.getTime()),
        max: new Date((this.value as CalendarValueInterface).max.getTime()),
        displayMax: new Date((this.value as CalendarValueInterface).max.getTime())
      };

      if (this.dateIntervalFlag === 'min') {
        this.today = new Date(this.interval.max);
      } else {
        this.today = new Date(this.interval.min);
      }

      this.intervalHours.min.selected = (this.value as CalendarValueInterface).min.getHours();
      this.intervalHours.max.selected = (this.value as CalendarValueInterface).max.getHours();
      this.intervalHours.min.displayHours = this.formatHoursDisplay(this.intervalHours.min.selected);
      this.intervalHours.max.displayHours = this.formatHoursDisplay(this.intervalHours.max.selected);
      this.interval.displayMax = this.formatDateToDisplay(this.interval.max);
      this.interval.displayMin = this.formatDateToDisplay(this.interval.min);
    } else {
      if (this.value !== null && (this.value as CalendarValueInterface).min !== null) {
        this.today = new Date((this.value as CalendarValueInterface).min.toString());
      }
    }
    if (!this.selectRange && this.value && !(this.value as CalendarValueInterface).min && typeof (this.value as Date).getTime === 'function') {
      this.interval = {
        min: new Date((this.value as Date).getTime()),
        displayMin: new Date((this.value as Date).getTime()),
        // max: new Date((<CalendarValueInterface>this.value).max.getTime()),
        // displayMax: new Date((<CalendarValueInterface>this.value).max.getTime())
      };
    }

    this.selectedDay = this.today.getDate();
    this.month = this.today.getMonth();
    this.monthName = this.months[this.month]; // maybe just months somewhere?
    this.year = this.today.getFullYear();

    this.buildMonth();

    if ((this.value as CalendarValueInterface).min !== null) {
      const getHours = (this.value as CalendarValueInterface).min.getHours();
      if (this.hidePreviousDates) {
        this.hoursAndMinutes.min.minutes.selected = (this.value as CalendarValueInterface).min.getMinutes();
        this.selectedDay = (this.value as CalendarValueInterface).min.getDate();
      }

      this.hoursAndMinutes.min.hours.selected = (this.value as CalendarValueInterface).min.getHours();
      if (this.hoursAndMinutes.min.hours.selected > 19) {
        this.hoursAndMinutes.min.hours.displayHours = [20, 21, 22, 23];
      } else {
        this.hoursAndMinutes.min.hours.displayHours = [getHours, getHours + 1, getHours + 2, getHours + 3];
      }
    }

    if ((this.value as CalendarValueInterface).max !== null) {
      const getHours = (this.value as CalendarValueInterface).max.getHours();
      if (this.hidePreviousDates) {
        this.hoursAndMinutes.max.minutes.selected = (this.value as CalendarValueInterface).max.getMinutes();
        this.selectedDay = (this.value as CalendarValueInterface).max.getDate();
      }

      this.hoursAndMinutes.max.hours.selected = (this.value as CalendarValueInterface).max.getHours();
      if (this.hoursAndMinutes.max.hours.selected > 19) {
        this.hoursAndMinutes.max.hours.displayHours = [20, 21, 22, 23];
      } else {
        this.hoursAndMinutes.max.hours.displayHours = [getHours, getHours + 1, getHours + 2, getHours + 3];
      }
    }
  }

  openCalendar() {
    this.isCalendarVisible = !this.isCalendarVisible;
    this.isCalendarVisibleChange.emit(this.isCalendarVisible);
  }

  close() {
    this.isCalendarVisible = false;
    this.isCalendarVisibleChange.emit(this.isCalendarVisible);
  }

  selectHour(hoursModel, hourIndex, minmax, type?) {
    if (hoursModel) {
      hoursModel.selected = hourIndex;
      if (this.interval) {
        this.interval[minmax].setHours(hourIndex);
        this.interval[minmax] = new Date(this.interval[minmax]);
      }
    }
  }

  selectMinute(minutesModel, minuteIndex, minmax) {
    if (minutesModel) {
      minutesModel.selected = minuteIndex;
      if (this.interval) {
        this.interval[minmax].setMinutes(minuteIndex);
        this.interval[minmax] = new Date(this.interval[minmax]);
      }
    }
  }

  updateDisplayHours(hoursModel, increment) {
    let index = null;
    if (increment === 'top') {
      if (hoursModel.displayHours[0].index === 0) {
        index = this.totalHours.length - 1;
      } else {
        index = --hoursModel.displayHours[0].index;
      }
    } else if (increment === 'bottom') {
      if (hoursModel.displayHours[0].index === this.totalHours.length - 1) {
        index = 0;
      } else {
        index = ++hoursModel.displayHours[0].index;
      }
    }
    hoursModel.displayHours = this.formatHoursDisplay(index);
  }

  updateDisplay(obj, minmax: 'min' | 'max', parameter, increment) {
    const hoursObj = this.hoursAndMinutes[minmax].hours[parameter];
    if (increment === 'bottom') {
      if (obj[obj.length - 1] !== hoursObj[hoursObj.length - 1]) {
        obj.splice(0, 1);
        obj.push(obj[obj.length - 1] + 1);
      }
    } else if (increment === 'top') {
      if (obj[0] !== hoursObj[0]) {
        obj.splice(obj.length - 1, 1);
        obj.splice(0, 0, obj[0] - 1);
      }
    }
  }

  formatHoursDisplay(selectedHour) {
    const displayArray = [];
    this.totalHours?.forEach((item, index) => {
      if (index >= selectedHour && displayArray.length < this.totalHoursDisplay) {
        displayArray.push({
          index,
          hour: item
        });
      }
    });
    const restHours = this.totalHoursDisplay - displayArray.length;
    if (displayArray.length < this.totalHoursDisplay) {
      this.totalHours?.forEach((item, index) => {
        if (index < restHours) {
          displayArray.push({
            index,
            hour: item
          });
        }
      });
    }
    return displayArray;
  }

  formatDateToDisplay(data: any) {
    let date;
    date = new Date(data);
//      date = (1e4 + '' + date.getMonth()).slice(-2) + '/' + (1e4 + '' + date.getDate()).slice(-2) + '/' + date.getFullYear();
    return date;
  }

  buildMonth() {
    this.weeks = [];

    let day = CALENDAR.day;
    let i = 0;
    let j;
    let haveDays = true;
    let startDay = new Date(this.year, this.month, 1).getDay(); // get day of the week for first day of the month
    const daysInMonth = [
      31, (((this.year % 4 === 0) && (this.year % 100 !== 0)) || (this.year % 400 === 0)) ? 29 : 28,
      31, 30, 31, 30, 31, 31, 30, 31, 30, 31
    ];
    this.dayModel = {
      day,
      month: this.month,
      year: this.year,
      selected: false,
      first: false,
      last: false,
      time: null
    };

    if (this.selectRange) {
      const minDateFormat = new Date(this.interval.min);
      let maxIntervalDate = new Date(this.interval.max);
      if (this.endTimeIndefinite) {
        maxIntervalDate = new Date(8640000000000000);
      }
      if (this._endTimeNow) {
        maxIntervalDate = new Date();
        this.interval.max = new Date();
      }
      minDateFormat.setHours(0, 0, 0, 0);
      while (haveDays) {
        this.weeks[i] = [];

        for (j = 0; j < 7; j = j + 1) {
          if (!i) {
            if (j === startDay) {
              this.weeks[i][j] = this.deepCopy(this.dayModel);
              this.weeks[i][j].time = new Date(this.year, this.month, this.dayModel.day).getTime();
              if (this.weeks[i][j].time >= minDateFormat && this.weeks[i][j].time <= maxIntervalDate) {
                this.weeks[i][j].selected = true;
              }
              this.dayModel.day++;
              startDay++;
            } else {
              this.weeks[i][j] = '';
            }
          } else {
            if (this.dayModel.day <= daysInMonth[this.month]) {
              this.weeks[i][j] = this.deepCopy(this.dayModel);
              this.weeks[i][j].time = new Date(this.year, this.month, this.dayModel.day).getTime();
              if (this.weeks[i][j].time >= minDateFormat && this.weeks[i][j].time <= maxIntervalDate) {
                this.weeks[i][j].selected = true;
              }
              this.dayModel.day++;
            } else {
              this.weeks[i][j] = '';
              haveDays = false;
            }
          }

          if (this.dayModel.day > daysInMonth[this.month]) {
            haveDays = false;
          }
        }

        i += 1;
      }

      // find the first and last dates selected for styling
      const firstDateFormat = new Date(this.interval.min);
      const firstDate = {
        day: firstDateFormat.getDate(),
        month: firstDateFormat.getMonth(),
        year: firstDateFormat.getFullYear()
      };
      let lastDateFormat = new Date(this.interval.max);
      if (this.endTimeIndefinite) {
        lastDateFormat = new Date(8640000000000000);
      }
      if (this.endTimeNow) {
        lastDateFormat = new Date();
      }
      const lastDate = {
        day: lastDateFormat.getDate(),
        month: lastDateFormat.getMonth(),
        year: lastDateFormat.getFullYear()
      };
      this.weeks.forEach((week) => {
        week.forEach((item) => {
          if (item.day === firstDate.day && item.month === firstDate.month && item.year === firstDate.year) {
            item.first = true;
          }
          if (item.day === lastDate.day && item.month === lastDate.month && item.year === lastDate.year) {
            item.last = true;
          }
        });
      });
    } else {
      while (haveDays) {
        this.weeks[i] = [];

        for (j = 0; j < 7; j = j + 1) {
          if (!i) {
            if (j === startDay) {
              this.weeks[i][j] = day++;
              startDay++;
            } else {
              this.weeks[i][j] = '';
            }
          } else {
            if (day <= daysInMonth[this.month]) {
              this.weeks[i][j] = day++;
            } else {
              this.weeks[i][j] = '';

              haveDays = false;
            }
          }

          if (day > daysInMonth[this.month]) {
            haveDays = false;
          }
        }

        i += 1;
      }
    }
  }

  updateSelectedDay(day) {
    // console.log('updateSelectedDay', day, this.dateIntervalFlag);
    if (!day) {
      return;
    }
    if (this.selectRange) {
      if (this.dateIntervalFlag === 'min') {
        if (day.time <= this.interval.max) {
          const minDate = new Date(day.time);
          const now = new Date();
          // if (minDate.getFullYear() === now.getFullYear() && minDate.getMonth() === now.getMonth() && minDate.getDate() === now.getDate()) {
          //
          // }
          this.interval.min = minDate;
          this.dateIntervalFlag = 'max';
        } else if (day.time > this.interval.max.getTime()) {
          this.interval.min = new Date(this.interval.max.getTime());
          const maxDate = new Date(day.time);
          this.interval.max = maxDate;
        }
      } else {
        if (day.time >= this.interval.min) {
          const maxDate = new Date(day.time);
          this.interval.max = maxDate;
          this.dateIntervalFlag = 'min';
        } else if (day.time < this.interval.min.getTime()) {
          this.interval.max = new Date(this.interval.min.getTime());
          const minDate = new Date(day.time);
          this.interval.min = minDate;
        }
      }
      this.interval.displayMax = this.formatDateToDisplay(this.interval.max);
      this.interval.displayMin = this.formatDateToDisplay(this.interval.min);

      this.buildMonth();
    } else {
      this.selectedDay = day;
    }

    if (this.emitOnDateChange === true) {
      this.selectDate(false);
    }
  }

  formatTimestampToDisplayDate(data) {
    const dd = data.getDate() < 10 ? '0' + data.getDate() : data.getDate();
    const monthNames = CALENDAR.months;
    const mm = monthNames[data.getMonth()];
    const yy = data.getFullYear();

    return `${dd} ${mm}, ${yy}`;
  }

  setDefault() {
    if (this.selectRange) {
      const defaultTimeInterval = {min: new Date(), max: new Date()};
      defaultTimeInterval.max = new Date();
      const last24 = new Date();
      last24.setDate(last24.getDate() - 7);
      defaultTimeInterval.min = last24;
      //  console.log(defaultTimeInterval);
      this.value = defaultTimeInterval;
      // model.updateSelectedDay(defaultTimeInterval);
    } else {
      this.value = {
        min: new Date(),
        max: null
      };
    }
    this.init();
    this.selectDate(true, true);
    // console.log(model);
  }

  selectDate(closeCalendar = true, defaultValue = false) {
    const value = new Date(this.year, this.month, this.selectedDay);

    if (!this.selectRange) {
      value.setHours(this.hoursAndMinutes.min.hours.selected, this.hoursAndMinutes.min.minutes.selected);
      if (this.popoverMode){
        this.popoverDateSelected(value);
      }
      else{
        this.dateSelected.emit(value);
      }
    } else if (this.dateSelected) {
      if (this.selectRange) {
        this.interval.min = new Date(this.interval.min);
        this.interval.max = new Date(this.interval.max);
        if (this.selectRange && this.hideMinutes) {
          this.interval.min.setHours(this.intervalHours.min.selected, 0, 0, 0);
          this.interval.max.setHours(this.intervalHours.max.selected, 0, 0, 0);
        } else {
          this.interval.min.setHours(this.hoursAndMinutes.min.hours.selected, this.hoursAndMinutes.min.minutes.selected);
          this.interval.max.setHours(this.hoursAndMinutes.max.hours.selected, this.hoursAndMinutes.max.minutes.selected);
        }
        if (defaultValue === true) {
          if (this.popoverMode){
            this.popoverDateSelected({min: this.interval.min, max: this.interval.max, defaultValue});
          }
          else{
            this.dateSelected.emit({min: this.interval.min, max: this.interval.max, defaultValue});
          }
        } else {
          if (this.popoverMode){
            this.popoverDateSelected({min: this.interval.min, max: this.interval.max});
          }
          else{
            this.dateSelected.emit({min: this.interval.min, max: this.interval.max});
          }
        }
        console.log('emitted:', {min: this.interval.min, max: this.interval.max});
      } else {
        if (this.popoverMode){
          this.popoverDateSelected(value);
        }
        else{
          this.dateSelected.emit(value);
        }
      }
    }
    if (closeCalendar === true) {
      this.close();
    }
  }

  nextMonth() {
    this.month = this.month + 1;

    if (this.month > 11) {
      this.month = 0;

      this.year = this.year + 1;
    }

    this.monthName = this.months[this.month]; // months[this.monts]

    this.buildMonth();
  }

  prevMonth() {
    if (this.hidePreviousDates && this.year <= this.currentDate.year && this.month <= this.currentDate.month) {
      return;
    }
    this.month = this.month - 1;

    if (this.month < 0) {
      this.month = 11;

      this.year = this.year - 1;
    }

    this.monthName = this.months[this.month]; // months[this.month]

    this.buildMonth();
  }

  nextYear() {
    this.year = this.year + 1;

    this.buildMonth();
  }

  prevYear() {
    if (this.hidePreviousDates && this.year - 1 < this.currentDate.year) { // currentdate.year
      return;
    }
    this.year = this.year - 1;

    this.buildMonth();
  }

  generateDate(date) {
    const splittdDate = date.split('\/');

    return new Date(splittdDate[2], splittdDate[1] - 1, splittdDate[0]);
  }

  isValidDate(dateString, minAge, maxAge) {
    if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) {
      return false;
    }

    const parts = dateString.split('\/');
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10);
    const year = parseInt(parts[2], 10);
    const currentYear = new Date().getFullYear();
    const minYear = currentYear - minAge;
    const maxYear = currentYear - maxAge;

    if (year < maxYear || year > minYear || month === 0 || month > 12) {
      return false;
    }

    const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
      monthLength[1] = 29;
    }

    return day > 0 && day <= monthLength[month - 1];
  }

  disableButton() {
    return !this.selectRange && !(this.hoursAndMinutes.min.hours.selected !== null && this.hoursAndMinutes.min.minutes.selected !== null);
  }

  isPreviousDate(day: number) {
    if (this.year < this.currentDate.year) {
      return true;
    }
    if (this.year === this.currentDate.year) {
      if (this.month < this.currentDate.month || (day < this.currentDate.selectedDay && this.month <= this.currentDate.month)) {
        return true;
      }
    }
    return false;
  }

  isFutureDate(day: number) {
    if (this.year > this.currentDate.year) {
      return true;
    }
    if (this.year === this.currentDate.year) {
      if (this.month > this.currentDate.month || (day > this.currentDate.selectedDay && this.month >= this.currentDate.month)) {
        return true;
      }
    }
    return false;
  }

  isWeekend(week, day) {
    const idx = week.indexOf(day);
    return idx === 0 || idx > 5;
  }

  isPreviousHour(hour) {
    if (!this.selectRange) {
      const now = new Date();
      const date = new Date();
      const then = new Date(this.year, this.month, this.selectedDay, this.hoursAndMinutes.min.hours.selected, 0, 0, 0);
      if (this.selectedDay === date.getDate() && this.month === date.getMonth() && this.year === date.getFullYear()) {
        return hour < date.getHours();
      } else if (then < now) {
        return true;
      }
      return false;
    }

    const now = new Date();
    if (this.interval.min.getFullYear() === now.getFullYear() && this.interval.min.getMonth() === now.getMonth() && this.interval.min.getDate() === now.getDate()) {
      if (this.hoursAndMinutes.min.hours.selected < now.getHours()) {
        this.hoursAndMinutes.min.hours.selected = (now.getHours() as any);
        if (this.hidePreviousDates === true) {
          this.hoursAndMinutes.min.hours.selected += 1;
        }
        if (this.hoursAndMinutes.min.hours.selected > 21) {
          this.hoursAndMinutes.min.hours.displayHours = [21, 22, 23, 24];
        } else {
          this.hoursAndMinutes.min.hours.displayHours = [this.hoursAndMinutes.min.hours.selected, this.hoursAndMinutes.min.hours.selected + 1, this.hoursAndMinutes.min.hours.selected + 2, this.hoursAndMinutes.min.hours.selected + 3];
        }
      }
      if (hour < now.getHours() || this.hidePreviousDates === true && hour <= now.getHours()) {
        return true;
      } else if (hour === now.getHours()) {
        if (now.getMinutes() > 45) {
          this.hoursAndMinutes.min.hours.selected = ((now.getHours() as any) + 1);
          if (this.hoursAndMinutes.min.hours.selected > 21) {
            this.hoursAndMinutes.min.hours.displayHours = [21, 22, 23, 24];
          } else {
            this.hoursAndMinutes.min.hours.displayHours = [this.hoursAndMinutes.min.hours.selected, this.hoursAndMinutes.min.hours.selected + 1, this.hoursAndMinutes.min.hours.selected + 2, this.hoursAndMinutes.min.hours.selected + 3];
          }
          return true;
        }
      }
    }
    return false;
  }

  isFutureHour(hour) {
    const date = new Date();
    if (this.selectedDay === date.getDate() && this.month === date.getMonth() && this.year === date.getFullYear()) {
      const currentHours = new Date().getHours();
      this.resetHours();
      return hour > currentHours + 1;
    }
    return false;
  }

  isFutureHourWithDate(hour: number, date: Date): boolean {
    const newDate = new Date(date.getTime());
    newDate.setHours(hour, 0, 0, 0);
    const now = new Date();
    now.setHours(now.getHours() + 1, 0, 0, 0);
    return newDate > now;
  }

  isPreviousHourMax(hour: number) {
    let min = new Date(this.interval.min.getTime());
    min.setHours(this.hoursAndMinutes.min.hours.selected, 0, 0, 0);
    const now = new Date();
    now.setMinutes(0, 0, 0);
    if (min < now) {
      min = now;
    }
    const max = new Date(this.interval.max.getTime());
    max.setHours(hour, 0, 0, 0);
    return min > max;
  }

  isPreviousHourWithDate(hour: number, date: Date) {
    const nextHour = new Date();
    nextHour.setHours(nextHour.getHours() + 1, 0, 0, 0);
    const exactDate = new Date(date);
    exactDate.setHours(hour, 0, 0, 0);
    return exactDate < nextHour;
  }

  isHourLargerThanMax(hour: number) {
    if (this.interval.min.getFullYear() === this.interval.max.getFullYear() &&
      this.interval.min.getMonth() === this.interval.max.getMonth() &&
      this.interval.min.getDate() === this.interval.max.getDate()) {
      if (this.intervalHours.min.selected == 23 && hour == 0) { return false; }
      return this.intervalHours.min.selected >= hour;
    }
    return false;
  }

  isPreviousMinute(minute) {
    const date = new Date();
    if (this.hoursAndMinutes.min.hours.selected === date.getHours() && this.selectedDay === date.getDate() && this.month === date.getMonth() && this.year === date.getFullYear()) {
      return minute <= date.getMinutes();
    }
    return false;
  }

  isPreviousMinuteMin(minute: number) {
    const min = new Date(this.interval.min.getTime());
    min.setHours(this.hoursAndMinutes.min.hours.selected, minute);
    const now = new Date();
    return min < now;
  }

  isPreviousMinuteMax(minute: number) {
    let min = new Date(this.interval.min.getTime());
    min.setHours(this.hoursAndMinutes.min.hours.selected, this.hoursAndMinutes.min.minutes.selected);
    if (min < new Date()) {
      min = new Date();
    }
    const max = new Date(this.interval.max.getTime());
    max.setHours(this.hoursAndMinutes.max.hours.selected, minute);
    return min > max;
  }

  isOpaqueMinute(minute) {
    // console.log('isOpaqueMinute', minute, this.interval);
    if (this.hidePreviousDates === true && this.forceMinutes) {
      const now = new Date();
      const min = this.interval.min;
      if (now.getFullYear() === min.getFullYear() && now.getMonth() === min.getMonth() && now.getDate() === min.getDate() && now.getHours() === this.hoursAndMinutes.min.hours.selected) {
        // const minutes = ;
        // console.log('compare me:', minutes);
        if (now.getMinutes() > minute) {
          // const selectedMin =
          if (now.getMinutes() >= this.hoursAndMinutes.min.minutes.selected) {
            if (now.getMinutes() < 15) {
              this.hoursAndMinutes.min.minutes.selected = 15;
            } else if (now.getMinutes() < 30) {
              this.hoursAndMinutes.min.minutes.selected = 30;
            } else if (now.getMinutes() < 45) {
              this.hoursAndMinutes.min.minutes.selected = 45;
            } else {
              this.hoursAndMinutes.min.minutes.selected = 0;
            }
          }
          return true;
        }
      }
    }
    return false;
  }

  displayHour(hour) {
    if (hour < 10) {
      return '0' + hour;
    }
    return hour;
  }

  resetHours() {
//        model.hoursAndMinutes.hours.selected = 0;
    const date = new Date();
    const getHours = date.getHours();
    if (this.hoursAndMinutes.min.hours.selected < getHours + 1 && this.selectedDay === date.getDate()) {
      this.hoursAndMinutes.min.hours.selected = getHours + 1;
    }
  }

  deepCopy(obj: any): any {
    return JSON.parse(JSON.stringify(obj));
  }

  instanceOfCalendarValueInterface(object: any): object is CalendarValueInterface {
    return object !== undefined && object !== null && 'min' in object && 'max' in object;
  }

}
