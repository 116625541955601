import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, forkJoin, mergeMap, Observable, of, Subject, Subscription} from 'rxjs';
import {Sensor_group_objects} from '../../../../api/src/lib/models/sensor-_group-_objects';
import {HttpClient} from '@angular/common/http';
import {SensorGroupCachedService} from './sensor-group-cached.service';
import {WaveSensorsService} from './wave-sensors.service';
import {first, flatMap, map, skip, take} from 'rxjs/operators';
import {AppStorage} from '../utils/AppStorage';
import {AuthUserResponse} from './user.service';
import {SensorTypes} from '../models/sensor-types.enum';
import {GroupCachedService} from './group-cached.service';
import {Group_master} from '../../../../api/src/lib/models/group-_master';
import {ExperimentConfigurationsService, ExperimentConfInterface} from './experiment-configurations.service';
import {ThresholdsCachedService, ThresholdTypeEnum} from './threshold-cached.service';
import {ConfigService, OrganizationsService, ShieldThresholdService} from '../../../../api/src/lib/services';
import { environment } from '../../../../../environments/environment';
import {KetosSocketService} from './ketos-socket.service';
import {OrganizationSettingsService} from './organization-settings.service';
import { OrganizationCustomParamsSettings} from './organization-customParams-settings.service'
import {TestNotificationService} from './test.notification.service';
import {UserNotificationService} from './user.notification.service';
import {WaterSourcesCachedService} from './water-sources-cached.service';
import {Water_source} from '../../../../api/src/lib/models/water-_source';
import {WaterSourceExtended} from '../models/extensions/water-source-extended';
import {ShieldDevicesCachedService} from './shield-devices-cached.service';
import {Experiments} from '../../../../api/src/lib/models';
import { OrganizationSettingsInterface } from '../models/organization-settings.interface'
import {UserSettingsCachedService} from './user-settings-cached.service';
import {OrganizationCustomParamsSettingsCachedService} from './organization-customParams-settings.cached.service';
import { OptionInterface } from '../components/multi-select-popover/multi-select-popover.component';
import { CloneDeep } from '../utils/utils';
import {SensorsBlacklistService} from './sensors-blacklist.service';
import * as moment from 'moment';
import { ExtendedConfigService } from '../../../../api/src/lib/extended-services/config.service';

export interface AppState {
  sensorType: SensorTypes;
  location: WaterSourceExtended;
  locations: WaterSourceExtended[];
  group: Group_master;
  groups: Group_master[];
  groupMappings: Sensor_group_objects[];
  orgSettings: OrganizationSettingsInterface;
}

export interface TimeIntervalInterface {
  min: Date | null;
  max: Date | null;
  defaultValue?: boolean;
}
export interface ExperimentOptionInterface extends OptionInterface {
  key: string;
  checked: boolean;
}

interface ExperimentsCustom extends Experiments {
  inactive?: boolean;
}
@Injectable({
  providedIn: 'root'
})
export class AppStateService {
  private model: AppState;
  private appState: BehaviorSubject<AppState>;

  // @TODO: move into appState
  public groupMapping: Sensor_group_objects[];

  public selectedTimeInterval: TimeIntervalInterface = {
    min: new Date(),
    max: new Date(),
  };
  locationsUpdateSubscription: Subscription;

  initOnceDone = false;
  initDoneMoment: moment.Moment;
  thresholdsInternalService: ThresholdsCachedService;
  thresholdsExternalService: ThresholdsCachedService;

  organizationSettings: OrganizationSettingsInterface;
  dropdownExperiments: Experiments[];

  visibleExperiments: Experiments[];
  experimentsWithRequiredBits: Experiments[];
  bitIdxToExperimentsDict: {[key: number]: Experiments[]};

  public static environment(email?: string) {
    if (environment.production) {
      return environment;
    }
    const user = JSON.parse(AppStorage.getItem('user'));
    const prodEmail = AppStorage.getItem('prod-email');
    if (prodEmail && (user && user.username === prodEmail && user.roleName === 'SuperAdmin' || prodEmail === email)) {
      return {
        production: environment.production,
        key: environment.key,
        CONSTANTS: environment.CONSTANTS
      };
    }
    return environment;
  }

  private menuToggleSubject = new Subject<void>();

  // Expose the observable
 public menuToggle$ = this.menuToggleSubject.asObservable();

  // Method to trigger the menu toggle event
  triggerMenuToggle() {
    this.menuToggleSubject.next();
  }

  constructor(
    private experimentConfigurationsService: ExperimentConfigurationsService,
    private http: HttpClient,
    private organizationsService: OrganizationsService,
    private groupsService: GroupCachedService,
    private orgSettingsService: OrganizationSettingsService,
    private organizationCustomParamsSettings: OrganizationCustomParamsSettings,
    private shieldDevicesService: ShieldDevicesCachedService,
    private waterSourcesService: WaterSourcesCachedService,
    private sensorGroupService: SensorGroupCachedService,
    private testNotificationService: TestNotificationService,
    private userNotificationService: UserNotificationService,
    private waveSensorsService: WaveSensorsService,
    private shieldThresholdService: ShieldThresholdService,
    private socketService: KetosSocketService,
    private userSettingsCachedService: UserSettingsCachedService,
    private organizationCustomParamsSettingsCachedService: OrganizationCustomParamsSettingsCachedService,
    private sensorsBlacklistService: SensorsBlacklistService,
    private configService: ConfigService,
    private extendedConfigService: ExtendedConfigService
  ) {
    this.selectedTimeInterval = this.getDefaultTimeIntervalLive();
    this.selectedTimeInterval.defaultValue = true;

    //this.captureMaximizeLive()
  }

  private initOnce(): Observable<boolean> {
    if (this.initOnceDone) {
      return of(true);
    }

    this.testNotificationService.init();
    this.userNotificationService.init();
    return this.experimentConfigurationsService.fetchConfigurations()
      .pipe(
        flatMap(conf => {
          const userData: AuthUserResponse = JSON.parse(AppStorage.getItem('user'));
          this.thresholdsInternalService = new ThresholdsCachedService( this.waterSourcesService, this.shieldThresholdService, this.extendedConfigService, ThresholdTypeEnum.internal, this.socketService, userData.organization_id);
          this.thresholdsExternalService = new ThresholdsCachedService( this.waterSourcesService, this.shieldThresholdService, this.extendedConfigService, ThresholdTypeEnum.external, this.socketService, userData.organization_id);
          return combineLatest([
            this.thresholdsInternalService.getCached(false, true),
            this.thresholdsExternalService.getCached(false, true),
            this.orgSettingsService.getDisplayUnitsMap(),
            this.shieldDevicesService.getCached(false, true),
            this.organizationCustomParamsSettings.getOrganizationSettings(),
            this.userSettingsCachedService.getCached(false),

        ]);
        }),
        mergeMap( res => {
          this.organizationSettings = res[4];
          const userData: AuthUserResponse = JSON.parse(AppStorage.getItem('user'));
          this.experimentConfigurationsService.applyAdlBdlOverridesIfApplicable(this.organizationSettings.isZincHREnabled, this.organizationSettings.isNitratesHREnabled, this.organizationSettings.isManganeseHrEnabled, userData.organization_id);
          this.dropdownExperiments = this.experimentConfigurationsService.getAllDropdownExperiments(this.organizationSettings);
          this.experimentsWithRequiredBits = this.experimentConfigurationsService.getExperimentsWithRequiredBits(this.organizationSettings);
          this.bitIdxToExperimentsDict = this.experimentConfigurationsService.getBitIdxToExperimentsDict(this.organizationSettings);
          return this.sensorsBlacklistService.getSensorBlackdict();
        }),
        map(blackDict => {
          this.initOnceDone = true;
          this.initDoneMoment = moment();
          return true;
        })
      );
  }
  
  captureMaximizeLive(maximize){
    const ionHeader = document.getElementById('ion-header');
    const element = document.getElementById('leftmenu');
    const mainContent = document.getElementById('main-content');
    setTimeout(() => {
      if (maximize) {
        ionHeader.style.zIndex = '1';
        mainContent.style.marginLeft = '0px';
        element.style.visibility = 'hidden';
      } else {
        element.style.visibility = 'revert';
        ionHeader.style.zIndex = '12';
        mainContent.style.marginLeft = 'revert-layer';
      }
      this.triggerMenuToggle();
    },100)
  }

  getDefaultTimeIntervalLive(): TimeIntervalInterface {
    const delta = 7 * 24 * 60 * 60 * 1000;
    const now = new Date();
    return {
      max: now,
      min: new Date(now.getTime() - delta),
    };
  }

  public init(sensorType?: SensorTypes, setLocation?: WaterSourceExtended): Observable<AppState | null> {
    if (!sensorType) {
      sensorType = this.getDefaultSensorType();

      // In this case there are no sensors and the appState is useless
      if (sensorType === null) {
        return of(null);
      }
    }

    if (this.locationsUpdateSubscription) {
      this.locationsUpdateSubscription.unsubscribe();
      this.locationsUpdateSubscription = null;
    }

    // Get all data associated with the default sensor type once
    let groupsFetch = this.groupsService.getCached(false, true);
    const userData: AuthUserResponse = JSON.parse(AppStorage.getItem('user'));
    if (!userData) {
      return of(null);
    }
    //if (userData.role_id > 2) { fix for MRWSV0-1932
    if (userData.role_id > 3) {
      groupsFetch = of(null);
    }
    return combineLatest([
      this.getSensorLocationsData(sensorType),
      userData?.role_id < 4 ? this.sensorGroupService.getCached(false, true) : of([]),
      groupsFetch,
      this.initOnce()
    ])
      .pipe(
        first(),
        flatMap((result: [Water_source[], Sensor_group_objects[], Group_master[], boolean]) => {
           //console.log(result,"appStateResult")
          const mappings = result[1];
          const groups = result[2];
          const selectedGroup = this.getSelectedGroup(groups, mappings, sensorType);
          const locations = result[0];
          const selectedLocation = this.getSelectedLocation(locations, sensorType, mappings, selectedGroup);
          let exp = this.dropdownExperiments.slice();
          if (selectedLocation) {
            exp = exp.filter(ex => {
              return !this.sensorsBlacklistService.experimentsBlackdict[selectedLocation.sensor_id]?.[ex.experiment];
            })
          }
          this.visibleExperiments = exp.filter(exp => exp.experiment !== 'ambient_temperature');

          this.model = {
            sensorType: sensorType,
            location: selectedLocation,
            locations: locations,
            group: selectedGroup,
            groups: groups,
            groupMappings: mappings,
            orgSettings: this.organizationSettings
          };

          if (!this.appState) {
            this.appState = new BehaviorSubject<AppState>(this.model);
            this.appState.next(this.model);
          } else {
            this.appState.next(this.model);
          }
          if (!this.locationsUpdateSubscription) {
            let groupsFeed = this.groupsService.getCached(true, false).pipe(take(1));
            // //if (userData.role_id > 2) { fix for MRWSV0-1932

            if (userData.role_id > 3) {
              groupsFeed = of(null);
            }
            this.locationsUpdateSubscription = combineLatest([
              this.getSensorLocationsData(sensorType, true).pipe(take(1)),
              userData?.role_id < 4 ? this.sensorGroupService.getCached(true, false).pipe(take(1)) : of([]),
              groupsFeed
            ])
              .subscribe((res: [WaterSourceExtended[], Sensor_group_objects[], Group_master[]]) => {

                this.model.locations = res[0];
                this.model.groupMappings = res[1];
                this.model.groups = res[2];
              });
          }
         console.log(this.model)
          this.appState.value.groupMappings = this.model.groupMappings;

          return of(this.model);
        })
      );
  }

  public getAppState(): BehaviorSubject<AppState> {
    return this.appState;
  }

  // public getGroupMapping(){
  //   return this.model.groupMappings
  // }

  public getDefaultSensorType(): SensorTypes {
    return SensorTypes.shield;
    // @TODO: Johannes reimplement NG4
    // let defaultLocationNameDictionary = {};
    // const selectedUserData = JSON.parse(AppStorage.getItem('selectedUser'));
    // if (selectedUserData !== null && selectedUserData.ownerId.defaultLocationNameDictionary && Object.keys(selectedUserData.ownerId.defaultLocationNameDictionary).length > 0) {
    //   defaultLocationNameDictionary = selectedUserData.ownerId.defaultLocationNameDictionary;
    // } else {
    //   const userData: AuthUserResponseInterface = JSON.parse(AppStorage.getItem('user'));
    //   defaultLocationNameDictionary = userData.defaultLocationNameDictionary;
    // }
    //
    // const selectedSensorType = JSON.parse(AppStorage.getItem('selectedSensorType'));
    // let defaultSensorType;
    //
    // if (selectedSensorType !== null) {
    //   defaultSensorType = selectedSensorType;
    // } else if (defaultLocationNameDictionary.hasOwnProperty(SensorTypes.shield)) {
    //   defaultSensorType = SensorTypes.shield;
    // } else if (defaultLocationNameDictionary.hasOwnProperty(SensorTypes.wave)) {
    //   defaultSensorType = SensorTypes.wave;
    // } else if (defaultLocationNameDictionary.hasOwnProperty(SensorTypes.iceberg)) {
    //   defaultSensorType = SensorTypes.iceberg;
    // } else {
    //   // defaultSensorType = SensorTypes.shield;
    //   defaultSensorType = null;
    // }
    //
    // return defaultSensorType;
  }

  public selectSensorType(sensorType: SensorTypes): Observable<AppState> {
    AppStorage.setItem('selectedSensorType', JSON.stringify(sensorType));

    return this.init(sensorType);
  }

  public setSelectedGroup(groupName: string) {
    if (groupName === undefined) {
      groupName = null;
    }
    const sensorType: SensorTypes = this.appState.value.sensorType;
    AppStorage.setItem(`selectedGroup_${sensorType}`, JSON.stringify(groupName));
  }

  public setSelectedLocation(location: WaterSourceExtended): Observable<AppState> {
    const sensorType: SensorTypes = this.appState.value.sensorType;
    const localLocation = this.findLocation(location, this.appState.value.locations, this.appState.value.sensorType);
    AppStorage.setItem(`selectedLocation_${sensorType}`, JSON.stringify(localLocation));

    return this.init(this.appState.value.sensorType, localLocation);
  }

  private getSelectedLocation(waterSources: WaterSourceExtended[], sensorType: SensorTypes, mappings?: Sensor_group_objects[], group?: Group_master): WaterSourceExtended {
    let selectedLocation: WaterSourceExtended;
    // if (location) {
    //   selectedLocation = Object.assign({}, this.findLocation(location, locations, sensorType));
    // } else {
      const savedLoc: WaterSourceExtended = JSON.parse(AppStorage.getItem(`selectedLocation_${sensorType}`));
      if (savedLoc) {
        if (sensorType === SensorTypes.wave) {
          // locationName = Object.values(savedLoc)[0]['location_name'];
        } else {
            for (const src of waterSources) {
              if (src.id === savedLoc.id) {
                selectedLocation = src;
              }
            }
        }
      } else {
        if (group && mappings.length > 0) {
          const defaultMapping = mappings.find(mp => mp.group_id === group.id && mp.is_default_location === 1);
          if (defaultMapping && defaultMapping.sensor_id) {
            selectedLocation = waterSources.find(loc => loc.sensor_id === defaultMapping.sensor_id);
          }
        }
      }

    if (!selectedLocation) {
      // Set the new selected location to the first one in the locations list
      if (sensorType === SensorTypes.shield) {
        selectedLocation = waterSources[0];
      } else {
        selectedLocation = Object.assign({}, Object.values(waterSources)[0] as unknown as WaterSourceExtended);
      }
    }
    return selectedLocation;
  }

  private findLocation(location: WaterSourceExtended, locations: any[], sensorType: SensorTypes): WaterSourceExtended {

    if (sensorType === SensorTypes.shield) {
      const sources: WaterSourceExtended[] = locations;
      for (const src of sources) {
        if (src.id === location.id) {
          return src;
        }
      }
      return null;
    }

    Object.keys(locations).forEach((key) => {
      switch (sensorType) {
        case SensorTypes.iceberg:
          if (location.location_name === locations[key].location_name) {
            location = locations[key];
          }
          break;
        case SensorTypes.wave:

          if (location.location_name === Object.values(locations[key])[0]['location_name']) {
            location = locations[key];
          }
          break;
      }
    });

    return location;
  }

  private getSelectedGroup(groups: Group_master[], mappings: Sensor_group_objects[], sensorType: SensorTypes): Group_master {
    if (!groups) {
      return null;
    }
    const savedGroupName = JSON.parse(AppStorage.getItem(`selectedGroup_${sensorType}`));

      switch (sensorType) {
        case SensorTypes.shield:
          if (savedGroupName) {
            for (const group of groups) {
              if (group.group_name === savedGroupName) {
                return group;
              }
            }
          }
          if(groups.length){
            let defaultGroup: Group_master;
            for (const group of groups) {
              if (group.is_default_group === 'True') {
                // AppStorage.setItem(`selectedGroup_${sensorType}`, JSON.stringify(group.group_name));
                defaultGroup = group;
              }
            }
            if (!defaultGroup) {
              defaultGroup = groups[0];
            }
            return defaultGroup;
          }
          break;
        case SensorTypes.iceberg:
          // if (selectedLocation && selectedLocation.group_names && selectedLocation.group_names.length > 0) {
          //   savedGroupName = selectedLocation.group_names[0];
          // }
          break;
        case SensorTypes.wave:
          // if (selectedLocation && Object.keys(selectedLocation).length > 0 && selectedLocation[Object.keys(selectedLocation)[0]] && selectedLocation[Object.keys(selectedLocation)[0]].group_names.length > 0) {
          //   savedGroupName = selectedLocation[Object.keys(selectedLocation)[0]].group_names[0];
          // }
          break;
      }
  }
  /**
   * Get the sensor locations data at the root level.
   * Iceberg and Shield have a .locations key
   * Wave has the locations at the root
   * @param sensorType
   */
  private getSensorLocationsData(sensorType: SensorTypes, live = false): Observable<Water_source[]> {
    switch (sensorType) {
      case SensorTypes.shield:
        return this.getShieldDropdownLocations(live);
      case SensorTypes.wave:
        // return this.getWaveSensorsLocations(0);
        return of();
      case SensorTypes.iceberg:
        return of();
      // return this.getIcebergLocations();
    }
  }

  private getWaveSensorsLocations(pageNumber): Observable<any> {
    // const url = this.appService.getWaveSensorsLocationsUrl(pageNumber);
    //
    // return this.http.get(url, {
    //   headers: this.appService.httpApiRequestsHeaders
    // });
    return of([]);
  }

  private getShieldDropdownLocations(live = false): Observable<Water_source[]> {
    return this.waterSourcesService.getCached(live);
  }

  private getIcebergLocations(): Observable<any> {
    // const url = this.appService.getIcebergLocationsUrl();
    //
    // return this.http.get(url, {
    //   headers: this.appService.httpApiRequestsHeaders
    // });
    return of([]);
  }
  filterExperimentsBasedOnSensorIds = (waterSourceIds: number[], sensorIds: number[], applyBlacklists = true): Experiments[] => {
    // console.log('filterExperimentsBasedOnSensorIds', waterSourceIds, sensorIds);
    if (!Array.isArray(sensorIds)) {
      sensorIds = [];
    }
    waterSourceIds?.map(wsId => {
      const sId = this.waterSourcesService.dictById[wsId]?.sensor_id;
      if (typeof sId === 'number' && sensorIds.indexOf(sId) === -1) {
        sensorIds.push(sId);
      }
    });

    const bitMatrix = sensorIds.map(sId => this.shieldDevicesService.dictBySensorId[sId]?.exp_bitmap?.split('') || []);
    if (bitMatrix.length === 0 || bitMatrix.length === 1 && bitMatrix[0].length === 0) {
      if (applyBlacklists) {
        return this.filterExperimentsFromBlackdict(this.dropdownExperiments, sensorIds, this.sensorsBlacklistService.experimentsBlackdict);
      } else {
        return this.dropdownExperiments;
      }
    }
    const maxLen = Math.max(...bitMatrix.map(arr => arr?.length || 0));
    const enabledBits = new Array(maxLen).fill(false);

    for (let i = 0; i < maxLen; i++) {
      for (let j = 0; j < bitMatrix.length; j++) {
        if (bitMatrix[j]?.[i] === '1') {
          enabledBits[i] = true;
          break;
        }
      }
    }

    const experimentsToReturn = [];
    const expAddedToDict: {[key: string]: ExperimentsCustom} = {};
    enabledBits.map( (enabled: boolean, idx) => {
      const experiments = this.bitIdxToExperimentsDict[idx];
      if (experiments) {
        for (const exp of experiments) {
          if (typeof expAddedToDict[exp.experiment] !== 'object') {
            if (enabled) {
              experimentsToReturn.push(exp);
              expAddedToDict[exp.experiment] = exp;
            } else {
              const inactiveExp: ExperimentsCustom = CloneDeep(exp);
              inactiveExp.inactive = true;
              experimentsToReturn.push(inactiveExp);
              expAddedToDict[inactiveExp.experiment] = inactiveExp;
            }
          } else {
            if (enabled) {
              if (expAddedToDict[exp.experiment].inactive) {
                experimentsToReturn[experimentsToReturn.indexOf(expAddedToDict[exp.experiment])] = exp;
                expAddedToDict[exp.experiment] = exp;
              }
            }
          }
        }
      }
    });

    for (const reqExp of this.experimentsWithRequiredBits) {
      const requirementsMetArray = reqExp.requiredBits.map(bit => enabledBits[bit]);
      if (requirementsMetArray.indexOf(false) === -1) {
        experimentsToReturn.push(reqExp);
      } else {
        const inactiveExp: ExperimentsCustom = CloneDeep(reqExp);
        inactiveExp.inactive = true;
        experimentsToReturn.push(inactiveExp);
      }
    }

    experimentsToReturn.push(...this.experimentConfigurationsService.wbExperiments);
    const experiments = experimentsToReturn.filter(exp => !exp.inactive).concat(experimentsToReturn.filter(exp => exp.inactive));
    if (applyBlacklists) {
      return this.filterExperimentsFromBlackdict(experiments, sensorIds, this.sensorsBlacklistService.experimentsBlackdict);
    } else {
      return experiments;
    }
  }

  filterExperimentsFromBlackdict(experiments: Experiments[], sensorIds: number[], blackdict: {[key: number]: {[key:string]: boolean}}): Experiments[] {
    if (sensorIds?.length > 0) {
      const exp = experiments.filter(ex => {
        for (const snId of sensorIds) {
          if (this.sensorsBlacklistService.experimentsBlackdict[snId]?.[ex.experiment] !== true) {
            return true;
          }
        }
        return false;
      });
      return exp;
    }
    return experiments;
  }
}

