/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiInternalConfiguration as __Configuration } from '../api-internal-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Device_histories } from '../models/device-_histories';
import { Device_history } from '../models/device-_history';
import { Device_history_input } from '../models/device-_history-_input';

/**
 * Device history related operations
 */
@Injectable({
  providedIn: 'root',
})
class DeviceHistoryService extends __BaseService {
  static readonly getDeviceHistoriesPath = '/device_history';
  static readonly postDeviceHistoriesPath = '/device_history';
  static readonly putDeviceHistoryResourceDeviceHistoryIntDeviceHistoryIdPath = '/device_history/{device_history_id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get Device Histories
   * @param params The `DeviceHistoryService.GetDeviceHistoriesParams` containing the following parameters:
   *
   * - `ids`: List of device history IDs
   *
   * - `sensor_ids`: List of sensor ids
   *
   * - `sensor_sns`: List of shield device serial numbers
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getDeviceHistoriesResponse(params: DeviceHistoryService.GetDeviceHistoriesParams): __Observable<__StrictHttpResponse<Device_histories>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    (params.sensorIds || []).forEach(val => {if (val != null) __params = __params.append('sensor_ids', val.toString())});
    (params.sensorSns || []).forEach(val => {if (val != null) __params = __params.append('sensor_sns', val.toString())});
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/device_history`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Device_histories>;
      })
    );
  }
  /**
   * Get Device Histories
   * @param params The `DeviceHistoryService.GetDeviceHistoriesParams` containing the following parameters:
   *
   * - `ids`: List of device history IDs
   *
   * - `sensor_ids`: List of sensor ids
   *
   * - `sensor_sns`: List of shield device serial numbers
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getDeviceHistories(params: DeviceHistoryService.GetDeviceHistoriesParams): __Observable<Device_histories> {
    return this.getDeviceHistoriesResponse(params).pipe(
      __map(_r => _r.body as Device_histories)
    );
  }

  /**
   * Create Device History
   * @param params The `DeviceHistoryService.PostDeviceHistoriesParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  postDeviceHistoriesResponse(params: DeviceHistoryService.PostDeviceHistoriesParams): __Observable<__StrictHttpResponse<Device_history>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/device_history`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Device_history>;
      })
    );
  }
  /**
   * Create Device History
   * @param params The `DeviceHistoryService.PostDeviceHistoriesParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  postDeviceHistories(params: DeviceHistoryService.PostDeviceHistoriesParams): __Observable<Device_history> {
    return this.postDeviceHistoriesResponse(params).pipe(
      __map(_r => _r.body as Device_history)
    );
  }

  /**
   * Update Device History
   * @param params The `DeviceHistoryService.PutDeviceHistoryResourceDeviceHistoryIntDeviceHistoryIdParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `device_history_id`: Device History ID
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  putDeviceHistoryResourceDeviceHistoryIntDeviceHistoryIdResponse(params: DeviceHistoryService.PutDeviceHistoryResourceDeviceHistoryIntDeviceHistoryIdParams): __Observable<__StrictHttpResponse<Device_history>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/device_history/${encodeURIComponent(String(params.deviceHistoryId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Device_history>;
      })
    );
  }
  /**
   * Update Device History
   * @param params The `DeviceHistoryService.PutDeviceHistoryResourceDeviceHistoryIntDeviceHistoryIdParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `device_history_id`: Device History ID
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  putDeviceHistoryResourceDeviceHistoryIntDeviceHistoryId(params: DeviceHistoryService.PutDeviceHistoryResourceDeviceHistoryIntDeviceHistoryIdParams): __Observable<Device_history> {
    return this.putDeviceHistoryResourceDeviceHistoryIntDeviceHistoryIdResponse(params).pipe(
      __map(_r => _r.body as Device_history)
    );
  }
}

module DeviceHistoryService {

  /**
   * Parameters for getDeviceHistories
   */
  export interface GetDeviceHistoriesParams {

    /**
     * List of device history IDs
     */
    ids?: Array<number>;

    /**
     * List of sensor ids
     */
    sensorIds?: Array<number>;

    /**
     * List of shield device serial numbers
     */
    sensorSns?: Array<string>;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for postDeviceHistories
   */
  export interface PostDeviceHistoriesParams {
    payload: Device_history;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for putDeviceHistoryResourceDeviceHistoryIntDeviceHistoryId
   */
  export interface PutDeviceHistoryResourceDeviceHistoryIntDeviceHistoryIdParams {
    payload: Device_history_input;

    /**
     * Device History ID
     */
    deviceHistoryId: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }
}

export { DeviceHistoryService }
