/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Devices_paginated } from '../models/devices-_paginated';
import { Device_next_id } from '../models/device-_next-_id';

/**
 * Device related operations
 */
@Injectable({
  providedIn: 'root',
})
class DevicesService extends __BaseService {
  static readonly getDevicesPath = '/devices';
  static readonly putScrapApprovePath = '/devices/scrap/approve';
  static readonly putScrapDenyPath = '/devices/scrap/deny';
  static readonly getDeviceNextIdPath = '/devices/{device_type}/next_id';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `DevicesService.GetDevicesParams` containing the following parameters:
   *
   * - `ids`: List of device ids (Serial Number)
   *
   * - `sensor_ids`: List of sensor ids
   *
   * - `organization_ids`: List of organization ids
   *
   * - `organization_types`: Organization types - 1=Internal, 2=External
   *
   * - `types`: Device types - one of: embedded_device, fluid_tray_device, lid_actuator_device, ph_alk_probe_device, shield_device, do_probe_device, ec_probe_device, bb_device, mux_device, mcu_device, p_axis_device, ksm_device, wb_device, orp_probe_device, ph_probe_device, temperature_probe_device
   *
   * - `installed`: Filter by installation status (true/false/null)
   *
   * - `start_expires_on`: Start expires_on (ISO 8601)
   *
   * - `end_expires_on`: End expires_on (ISO 8601)
   *
   * - `is_deleted`:
   *
   * - `page`: Page number
   *
   * - `per_page`: Per page
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getDevicesResponse(params: DevicesService.GetDevicesParams): __Observable<__StrictHttpResponse<Devices_paginated>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    (params.sensorIds || []).forEach(val => {if (val != null) __params = __params.append('sensor_ids', val.toString())});
    (params.organizationIds || []).forEach(val => {if (val != null) __params = __params.append('organization_ids', val.toString())});
    (params.organizationTypes || []).forEach(val => {if (val != null) __params = __params.append('organization_types', val.toString())});
    (params.types || []).forEach(val => {if (val != null) __params = __params.append('types', val.toString())});
    if (params.installed != null) __params = __params.set('installed', params.installed.toString());
    if (params.startExpiresOn != null) __params = __params.set('start_expires_on', params.startExpiresOn.toString());
    if (params.endExpiresOn != null) __params = __params.set('end_expires_on', params.endExpiresOn.toString());
    if (params.isDeleted != null) __params = __params.set('is_deleted', params.isDeleted.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.perPage != null) __params = __params.set('per_page', params.perPage.toString());
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/devices`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Devices_paginated>;
      })
    );
  }
  /**
   * @param params The `DevicesService.GetDevicesParams` containing the following parameters:
   *
   * - `ids`: List of device ids (Serial Number)
   *
   * - `sensor_ids`: List of sensor ids
   *
   * - `organization_ids`: List of organization ids
   *
   * - `organization_types`: Organization types - 1=Internal, 2=External
   *
   * - `types`: Device types - one of: embedded_device, fluid_tray_device, lid_actuator_device, ph_alk_probe_device, shield_device, do_probe_device, ec_probe_device, bb_device, mux_device, mcu_device, p_axis_device, ksm_device, wb_device, orp_probe_device, ph_probe_device, temperature_probe_device
   *
   * - `installed`: Filter by installation status (true/false/null)
   *
   * - `start_expires_on`: Start expires_on (ISO 8601)
   *
   * - `end_expires_on`: End expires_on (ISO 8601)
   *
   * - `is_deleted`:
   *
   * - `page`: Page number
   *
   * - `per_page`: Per page
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getDevices(params: DevicesService.GetDevicesParams): __Observable<Devices_paginated> {
    return this.getDevicesResponse(params).pipe(
      __map(_r => _r.body as Devices_paginated)
    );
  }

  /**
   * @param device_ids List of device IDs
   */
  putScrapApproveResponse(deviceIds: Array<string>): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (deviceIds || []).forEach(val => {if (val != null) __params = __params.append('device_ids', val.toString())});
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/devices/scrap/approve`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param device_ids List of device IDs
   */
  putScrapApprove(deviceIds: Array<string>): __Observable<null> {
    return this.putScrapApproveResponse(deviceIds).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param device_ids List of device IDs
   */
  putScrapDenyResponse(deviceIds: Array<string>): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (deviceIds || []).forEach(val => {if (val != null) __params = __params.append('device_ids', val.toString())});
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/devices/scrap/deny`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param device_ids List of device IDs
   */
  putScrapDeny(deviceIds: Array<string>): __Observable<null> {
    return this.putScrapDenyResponse(deviceIds).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `DevicesService.GetDeviceNextIdParams` containing the following parameters:
   *
   * - `device_type`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getDeviceNextIdResponse(params: DevicesService.GetDeviceNextIdParams): __Observable<__StrictHttpResponse<Device_next_id>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/devices/${encodeURIComponent(String(params.deviceType))}/next_id`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Device_next_id>;
      })
    );
  }
  /**
   * @param params The `DevicesService.GetDeviceNextIdParams` containing the following parameters:
   *
   * - `device_type`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getDeviceNextId(params: DevicesService.GetDeviceNextIdParams): __Observable<Device_next_id> {
    return this.getDeviceNextIdResponse(params).pipe(
      __map(_r => _r.body as Device_next_id)
    );
  }
}

module DevicesService {

  /**
   * Parameters for getDevices
   */
  export interface GetDevicesParams {

    /**
     * List of device ids (Serial Number)
     */
    ids?: Array<string>;

    /**
     * List of sensor ids
     */
    sensorIds?: Array<number>;

    /**
     * List of organization ids
     */
    organizationIds?: Array<number>;

    /**
     * Organization types - 1=Internal, 2=External
     */
    organizationTypes?: Array<number>;

    /**
     * Device types - one of: embedded_device, fluid_tray_device, lid_actuator_device, ph_alk_probe_device, shield_device, do_probe_device, ec_probe_device, bb_device, mux_device, mcu_device, p_axis_device, ksm_device, wb_device, orp_probe_device, ph_probe_device, temperature_probe_device
     */
    types?: Array<string>;

    /**
     * Filter by installation status (true/false/null)
     */
    installed?: boolean;

    /**
     * Start expires_on (ISO 8601)
     */
    startExpiresOn?: string;

    /**
     * End expires_on (ISO 8601)
     */
    endExpiresOn?: string;
    isDeleted?: boolean;

    /**
     * Page number
     */
    page?: number;

    /**
     * Per page
     */
    perPage?: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for getDeviceNextId
   */
  export interface GetDeviceNextIdParams {
    deviceType: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }
}

export { DevicesService }
