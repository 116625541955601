import { Injectable, Injector } from '@angular/core';
import {map} from 'rxjs/operators';
import {Observable, of, ReplaySubject} from 'rxjs';
import { ExtendedConfigService } from '../../../../api/src/lib/extended-services/config.service';
import {Experiments} from '../../../../api/src/lib/models/experiments';
import {CloneDeep} from '../utils/utils';
import {OrganizationSettingsInterface} from '../models/organization-settings.interface';
import { UserService } from './user.service';
import { ConfigService } from '../../../../api/src/lib/services/config.service';

export interface ExperimentConfInterface {
  icon: string;
  unit: string;
  hideUnit?: boolean;
  status?: string;
  title: string;
  titleShort?: string;
  titleStatus?: string;
  info: string;
  settings: boolean;
  continuous_test?: boolean;
  epaLimit?: {
    US: number,
    Mexico: number,
    India: number
  };
  defaultThresholds: {
    [key: number]: number; // 1 - internal, 2 - external
  };
  alerts?: {
    key?: string,
    icon?: string,
    method?: string,
    unit?: string,
    high?: string,
    moderate?: string,
    low?: string,
  };
  threshold_key: string;      // min_value or max_value from thresholds endpoint
  bdl: number; // Below Detection Limit
  adl: number; // Above Detection Limit
  umolPerL?: number;
  key: string;
  ipKeyOverride?: string;
  method?: string;
  type: 'parameters' | 'metals' | 'nutrients' | 'inorganics' | 'organics' | 'lab';
  oldType?: 'water' | 'toxins'; // @TODO: remove once mobile app does not use these
  visible?: boolean;
  thresholdLimitMin?: number;
  thresholdLimitMax?: number;
  bitmapIdx?: number;
  sourceBitIdx?: {sources?: number[], required?: number[][] | number[]}; // sources: any idx activates, required: all bits have to be present, if null, always enabled (not in bitmap)
  hideOnBitmapDecode?: boolean; // if true hide when encoding or decoding bitmaps
  graphIdx?: number; // if -1 hide from graph dropdowns
}

@Injectable({
  providedIn: 'root'
})
export class ExperimentConfigurationsService {
 // public static manganeseHrOrgIdWhiteList = [2, 73];

  initialized = false;
  initializedReplaySubject = new ReplaySubject<boolean>(1);

  // Old deprecateed ones
  public allExperimentConfigurations: ExperimentConfInterface[];
  public allExperimentConfigurationsMap: {[key: string]: ExperimentConfInterface};
  public experimentConfigurations: ExperimentConfInterface[]; // Deprecated, use experiments instead
  public experimentConfigurationsMap: {[key: string]: ExperimentConfInterface};
  public bitIdxToExperimentsMapFiltered: {[key: number]: ExperimentConfInterface[]};
  public bitIdxToExperimentConfsDict: {[key: number]: ExperimentConfInterface[]};
  public experimentConfigurationsMethodsDictByExperiment: {[key: string]: {[key: string]: ExperimentConfInterface}};

  // New good ones
  public allExperiments: Experiments[];
  public experimentsDict: {[key: string]: Experiments};
  public bitIdxToExperimentsDict: {[key: string]: Experiments[]};
  public wbExperiments: Experiments[];

  bitmapTexts: string[];
  bitmapTextsShort: string[];
  disabledBitIndexes: number[];

  constructor(
              private injector: Injector,
              private configService: ConfigService,
              private extendedConfigService: ExtendedConfigService) {
  }


  initOnce(filterExperimentTypes?: string[]): Observable<ExperimentConfInterface[]> {
    if (this.initialized) {

      return of(this.allExperimentConfigurations);

    }
    return this.fetchConfigurations(filterExperimentTypes).pipe(
      map( confs => {
        this.initialized = true;
        this.initializedReplaySubject.next(true);
        return confs;
      })
    );
  }

  public fetchConfigurations(filterExperimentTypes = ['scheduled', 'continuous', 'lab']): Observable<ExperimentConfInterface[]> {
    // return of(testData as unknown as ExperimentConfInterface[]).pipe(
    // return combineLatest([
      // this.http.get(url, {headers: { 'Cache-Control': 'no-cache'}}),
      // of(testData as unknown as ExperimentConfInterface[]),
      return this.extendedConfigService.getExperiments({experimentTypes: filterExperimentTypes})
      // of(testExp)
    // ])
      .pipe(
    //   delay(699),
      map( res => {
        this.allExperiments = res as unknown as Experiments[];
        this.experimentsDict = {};
        this.bitIdxToExperimentsDict = {};
        this.allExperiments.map(exp => {
          if (!this.experimentsDict[exp.experiment]) {
            this.experimentsDict[exp.experiment] = exp;
          }
          if (!this.bitIdxToExperimentsDict[exp.bit]) {
            this.bitIdxToExperimentsDict[exp.bit] = [];
          }
          this.bitIdxToExperimentsDict[exp.bit].push(exp);
        });
        this.wbExperiments = this.allExperiments.filter(exp => typeof exp.bit !== 'number' && exp.method === 'wb' && exp.experiment !== 'ambient_temperature');


        const confs = this.experimentsToConfs(this.allExperiments);
        // const confs = res[0] as ExperimentConfInterface[];
        this.allExperimentConfigurations = confs;
        this.allExperimentConfigurationsMap = {};
        this.bitIdxToExperimentsMapFiltered = {};
        this.bitIdxToExperimentConfsDict = {};
        this.allExperimentConfigurations.map(conf => {
          // if (!this.allExperimentConfigurationsMap[conf.key]) {
          //   this.allExperimentConfigurationsMap[conf.key] = conf;
          // }
          // Keep overwriting until we get the last one otherwise the sources can be incomplete
          //  (sources are the other experiments with the same key like "nitrates"
          this.allExperimentConfigurationsMap[conf.key] = conf;
          if (typeof conf.bitmapIdx === 'number') {
            if (conf.key !== 'calc_magnesium') {
              if (!this.bitIdxToExperimentsMapFiltered[conf.bitmapIdx]) {
                this.bitIdxToExperimentsMapFiltered[conf.bitmapIdx] = [];
              }
              this.bitIdxToExperimentsMapFiltered[conf.bitmapIdx].push(conf);
            }
            if (!this.bitIdxToExperimentConfsDict[conf.bitmapIdx]) {
              this.bitIdxToExperimentConfsDict[conf.bitmapIdx] = [];
            }
            this.bitIdxToExperimentConfsDict[conf.bitmapIdx].push(conf);
          }
        });
        this.experimentConfigurations = confs.filter(conf => conf.visible);
        this.experimentConfigurationsMap = {};
        this.experimentConfigurations.map(conf => {
          this.experimentConfigurationsMap[conf.key] = conf;
        });
        this.generateBitmapTexts();

        return confs as ExperimentConfInterface[];
      })
    );
  }

  getExperimentsWithRequiredBits(orgSettings?: OrganizationSettingsInterface): Experiments[] {
    const blacklist = [];
    if (!orgSettings?.isLsiEnabled) {
      blacklist.push('lsi');
    }
    return this.allExperiments.filter(exp => typeof exp.bit !== 'number' && exp.requiredBits?.length > 0 && blacklist.indexOf(exp.experiment) === -1);
  }

  getBitIdxToExperimentsDict(orgSettings?: OrganizationSettingsInterface): {[key: number]: Experiments[]} {
    const blacklist = [];
    if (!orgSettings?.isMagnesiumEnabled) {
      blacklist.push('calc_magnesium')
    }
    const bitIdxToExperimentsDict = {};
    this.allExperiments.filter(exp => typeof exp.bit === 'number' && blacklist.indexOf(exp.experiment) === -1)
      .map( exp => {
        if (!bitIdxToExperimentsDict[exp.bit]) {
          bitIdxToExperimentsDict[exp.bit] = [];
        }
        bitIdxToExperimentsDict[exp.bit].push(exp);
      })
    return bitIdxToExperimentsDict;
  }

  // All supported experiments for this organization
  getAllDropdownExperiments(orgSettings?: OrganizationSettingsInterface): Experiments[] {
    const blacklist = ['ambient_temperature'];
    if (!orgSettings?.isMagnesiumEnabled) {
      blacklist.push('calc_magnesium')
    }
    if (!orgSettings?.isLsiEnabled) {
      blacklist.push('lsi');
    }

    const methodOverrides: {[key: string]: string} = {};
    if (orgSettings?.isNitratesHREnabled) {
      methodOverrides['nitrates'] = 'bb';
    }
    if (orgSettings?.isZincHREnabled) {
      methodOverrides['zinc'] = 'bb';
    }

    const dropdownExperiments = [];
    const experimentByExpDict: {[key: string]: Experiments} = {};
    this.allExperiments
      .filter(exp => blacklist.indexOf(exp.experiment) === -1 && (!methodOverrides[exp.experiment] || exp.method !== methodOverrides[exp.experiment]))
      .map(exp => {
      if (!experimentByExpDict[exp.experiment] && exp.method !== 'lab') {
        experimentByExpDict[exp.experiment] = exp;
        dropdownExperiments.push(exp);
      }
    });
    return dropdownExperiments;
  }

  applyAdlBdlOverridesIfApplicable(isZincHrEnabled: boolean, isNitratesHrEnabled: boolean, isManganeseHrenabled: boolean, orgId: number) {
    if (isZincHrEnabled){
     if (this.allExperimentConfigurationsMap.zinc && this.experimentConfigurationsMethodsDictByExperiment.zinc?.bb) {
        this.allExperimentConfigurationsMap.zinc.adl = this.experimentConfigurationsMethodsDictByExperiment.zinc.bb.adl;
        this.allExperimentConfigurationsMap.zinc.bdl = this.experimentConfigurationsMethodsDictByExperiment.zinc.bb.bdl;
      }
    }
    if (isNitratesHrEnabled) {
      if (this.allExperimentConfigurationsMap.nitrates && this.experimentConfigurationsMethodsDictByExperiment.nitrates?.bb) {
        this.allExperimentConfigurationsMap.nitrates.adl = this.experimentConfigurationsMethodsDictByExperiment.nitrates?.bb.adl;
        this.allExperimentConfigurationsMap.nitrates.bdl = this.experimentConfigurationsMethodsDictByExperiment.nitrates?.bb.bdl;
      }
    }
    if (isManganeseHrenabled) {
      if (this.allExperimentConfigurationsMap.manganese && this.experimentConfigurationsMethodsDictByExperiment.manganese?.bb) {
        this.allExperimentConfigurationsMap.manganese.adl = this.experimentConfigurationsMethodsDictByExperiment.manganese?.bb.adl;
        this.allExperimentConfigurationsMap.manganese.bdl = this.experimentConfigurationsMethodsDictByExperiment.manganese?.bb.bdl;
      }
    }
  }

  public static getExperimentBlacklist(orgId?: number,isMagnisiumEnabled?: boolean, isLsiEnabled?: boolean): string[] {
    const blacklist = ['mn_bb', 'boron_hr'];

    if (orgId) {
      if(!isMagnisiumEnabled){
        blacklist.push('calc_magnesium');
      }
      if(!isLsiEnabled){
        blacklist.push('lsi');
      }
    }
    return blacklist;
  }

  private generateBitmapTexts() {
    this.bitmapTexts = [];
    this.bitmapTextsShort = [];
    this.allExperimentConfigurations.map(exp => {
      if (typeof exp.bitmapIdx === 'number' && !exp.hideOnBitmapDecode) {
        this.bitmapTexts[exp.bitmapIdx] = (this.bitmapTexts[exp.bitmapIdx] || '') + exp.title + ', ';
        this.bitmapTextsShort[exp.bitmapIdx] = (this.bitmapTextsShort[exp.bitmapIdx] || '') + (exp.titleShort || exp.title) + ', ';
      }
    })
    for (let i = 0; i < this.bitmapTexts.length ; i ++) {
      if (!this.bitmapTexts[i]) {
        this.bitmapTexts[i] = '';
      }
    }
    this.disabledBitIndexes = [];
    for (let i = 0; i < this.bitmapTextsShort.length ; i ++) {
      if (!this.bitmapTextsShort[i]) {
        this.bitmapTextsShort[i] = '';
        this.disabledBitIndexes.push(i);
      }
    }
  }

  private experimentsToConfs(experiments: Experiments[]): ExperimentConfInterface[] {
    this.experimentConfigurationsMethodsDictByExperiment = {};

    const confs: ExperimentConfInterface[] = [];

    const expMap: {[key: string]: Experiments} = {};
    const confMap: {[key: string]: ExperimentConfInterface} = {};

    // This creates a list of all the first of each "experiment"
    //  and sets the sources bits for the other ones with the same
    //  experiment name
    experiments
      .map(exp => {
        const conf: ExperimentConfInterface = {
          icon: exp.experiment,
          unit: exp.unit,
          hideUnit: exp.experiment === 'ph',
          title: exp.title,
          titleShort: exp.titleShort,
          info: exp.title,
          settings: true,
          defaultThresholds: {},
          threshold_key: 'max_value',
          bdl: exp.bdl,
          adl: exp.adl,
          umolPerL: exp.umolPerL,
          key: exp.experiment,
          // key: !expMap[exp.experiment] ? exp.experiment : exp.experiment + '_' + exp.method,
          method: exp.method,
          type: exp.tab as any,
          visible: !expMap[exp.experiment] && exp.method !== 'lab' && exp.experiment !== 'ambient_temperature',
          bitmapIdx: exp.bit,
          status: exp.status,
        };

        if (conf.visible && exp.method !== 'lab') {
          conf.settings = true;
        }

        if (exp.defaultThreshold) {
          const epaLimit: number = exp.defaultThreshold?.external?.max || null;
          if (exp.experiment === 'dissolved_oxygen') {
            conf.threshold_key = 'min_value';
            conf.defaultThresholds = {
              1: exp.defaultThreshold?.external?.min || null,
              2: exp.defaultThreshold?.external?.min || null
            };
          } else {
            conf.defaultThresholds = {
              1: epaLimit,
              2: epaLimit
            };
          }
          conf.epaLimit = {
            US: epaLimit,
            Mexico: epaLimit,
            India: epaLimit
          };
        }

        if (conf.key !== exp.experiment) {
          conf.ipKeyOverride = exp.experiment;
        }

        if (exp.experiment === 'calcium' || exp.experiment === 'calc_magnesium') {
          conf.hideOnBitmapDecode = true;
        }

        if (typeof exp.bit === 'number') {
          if (confMap[exp.experiment]) {
            const oldConf = confMap[exp.experiment];
            if (oldConf.sourceBitIdx) {
              oldConf.sourceBitIdx.sources = [...oldConf.sourceBitIdx.sources, exp.bit];
              conf.sourceBitIdx = {sources: oldConf.sourceBitIdx.sources.slice()};
            } else {
              oldConf.sourceBitIdx = {sources: [exp.bit]};
              conf.sourceBitIdx = {sources: [exp.bit]};
            }
          } else {
            conf.sourceBitIdx = { sources: [exp.bit]};
          }
        }

        if (exp.requiredBits?.length > 0) {
          if (conf.sourceBitIdx) {
            conf.sourceBitIdx.required = exp.requiredBits;
          } else {
            conf.sourceBitIdx = {required: exp.requiredBits};
          }

          if (exp.experiment === 'lsi') {
            conf.sourceBitIdx = {
              sources: [9, 10],
              required: [[9, 10], [9, 20]]
            };
          }

          // @todo This is here because the code does not properly support three of the same experiment name
          //  After all code is migrated to use the new configuration.json all of this will be removed anyway.
          if (exp.experiment === 'nitrates') {
            conf.sourceBitIdx = {sources: [3, 27, 40]};
          }
        }

        confs.push(conf);
        expMap[conf.key] = exp;
        confMap[conf.key] = conf;

        if (!this.experimentConfigurationsMethodsDictByExperiment[exp.experiment]) {
          this.experimentConfigurationsMethodsDictByExperiment[exp.experiment] = {};
        }
        this.experimentConfigurationsMethodsDictByExperiment[exp.experiment][exp.method] = conf;
      });
    return confs;
  }

  getConfByExperimentMethodBitmap(experiment: string, method?: string, bitmap?: string): ExperimentConfInterface {
    // console.log('bitmapping:', experiment, method, bitmap);
    if (method &&
      this.experimentConfigurationsMethodsDictByExperiment[experiment] &&
      this.experimentConfigurationsMethodsDictByExperiment[experiment][method]
    ) {
      return this.experimentConfigurationsMethodsDictByExperiment[experiment][method];
    }

    let conf = this.allExperimentConfigurationsMap[experiment];
    if (
      bitmap &&
      conf &&
      typeof conf.bitmapIdx === 'number' &&
      conf.sourceBitIdx?.sources?.length > 1
    ) {
      const confsToMerge: ExperimentConfInterface[] = [];
      for (const bit of conf.sourceBitIdx?.sources) {
        if (bitmap.charAt(bit) === '1') {
          confsToMerge.push(this.bitIdxToExperimentConfsDict[bit].find(c => c.type === conf.type));
        }
      }
      if (confsToMerge.length > 1) {
        conf = CloneDeep(confsToMerge[0]);
        confsToMerge.map( newConf => {
          if (newConf.adl === null || newConf.adl === undefined || typeof newConf.adl === 'number' && newConf.adl > conf.adl) {
            conf.adl = newConf.adl;
          }
          if (newConf.bdl === null || newConf.bdl === undefined || typeof newConf.bdl === 'number' && newConf.bdl < conf.bdl) {
            conf.bdl = newConf.bdl;
          }
        });
        // console.log('merging adls bdls:', conf, confsToMerge);
      } else if (confsToMerge.length === 1) {
        return confsToMerge[0];
      }
    }
    return conf;
  }
}
