/*
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */

import { Component, Input, OnInit, AfterViewInit, Output } from '@angular/core';
import { AuthService } from '../../../../services/auth.service';
import { AppService } from '../../../../services/legacy-services/app.service';
import { Router } from '@angular/router';
import { SensorTypes} from '../../../../models/sensor-types.enum';
import { AppStorage} from '../../../../utils/AppStorage';
import {OrganizationService} from '../../../../../../../api/src/lib/services/organization.service';
import {Get_organization_parser} from "../../../../../../../api/src/lib/models/get-_organization-_parser";
import {DropdownOptionsInterface, DropdownValueInterface} from "../../dropdown/models/dropdown-options.interface";
import {NGRoleEnum} from "../../../../services/user.service";
import {AppStateService} from '../../../../services/app-state.service';
import {DashboardUiStateService} from '../../../../services/dashboard-ui-state.service';
import {combineLatest, Subscription, timer} from 'rxjs';

import { EventEmitter } from '@angular/core';
import { MenuController } from '@ionic/angular';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit {
  public userName: string;
  public role: number;
  public orgsMap: {[key: string]: Get_organization_parser } = {};
  public orgsDropdown: DropdownOptionsInterface = {values: []};
  public selectedOrg: DropdownValueInterface;
  subscribeScoll
  @Input() hasSensors = true;
  @Input() smallScreen = true;
  @Input() smallHeader = false;
  @Input() showMenu;
  sub: Subscription;
  subscriptions: Subscription[] = [];
  constructor(
    private appService: AppService,
    private appStateService: AppStateService,
    public authService: AuthService,
    private router: Router,
    private menuCtrl: MenuController,
    private organizationService: OrganizationService,
    public dashboardUiStateService: DashboardUiStateService,

  ) {}

  ngOnInit() {
    this.sub = this.dashboardUiStateService.dashboardUiStateSubject.subscribe(res =>{
      setTimeout(() => {
        this.userName = res.userName
      })
    })

    const user = JSON.parse(AppStorage.getItem('user'))
    this.role = user?.role_id;
    this.userName = user?.name;

    if (this.role === NGRoleEnum.SuperAdmin) {
      this.organizationService.getOrganization().subscribe( orgs => {
        this.orgsMap = {};
        orgs.map(org => this.orgsMap[org.id] = org);
        this.orgsDropdown = {
          values: orgs.map((org, index) => {
            return {title: org.organization_name, value: org.id as unknown as string}
          })
        }
        const current = AppStorage.getItem('selected-org');
        if (!current || !this.orgsMap[JSON.parse(current).id]) {
          this.selectedOrg = this.orgsDropdown.values[0];
          AppStorage.setItem('selected-org', JSON.stringify(this.orgsMap[this.selectedOrg.value]));
          location.reload();
        } else {
          const org: Get_organization_parser = JSON.parse(current);
          for (const val of this.orgsDropdown.values) {
            if (val.value === org.id as unknown as string) {
              this.selectedOrg = val;
              break;
            }
          }
        }
      })
    }

    const appRoot = document.getElementsByTagName('app-root')[0];
    let sideMenu;
    setTimeout(() => {
      sideMenu = document.getElementById("sideMenu");
    }, 100)
    appRoot?.addEventListener('scroll',  () => {
      if (appRoot.scrollTop>100) {
        this.smallHeader = true;
        if (sideMenu){
          sideMenu.style.top = "60px";
        }
      }
      else {
        this.smallHeader = false;
        if(sideMenu){
          sideMenu.style.top = "105px";
        }
      }
    });
  }
  makeAppstateInit(){
    this.appStateService.init().subscribe((res) => {
      return true
    })
    return false;
  }
  ngAfterViewInit(){
    // const scroll$ = fromEvent(window, 'scroll').pipe(
    //   throttleTime(10),
    // ).subscribe((x)=>{
    //   //console.log(x)
    //   const leftNav = document.getElementById('sideMenu');
    //   const header = document.getElementById('header');
    //   const dashboard = document.getElementById('dashboard');
    //   const windowScroll = window.pageYOffset;
    //   if(windowScroll > 150){
    //     //header.style.padding='1%';
    //     header.style.position='fixed';
    //     header.style.height='70px';
    //     header.style.opacity= '0.9'

    //     if(leftNav){
    //     header.style.setProperty('width', 'calc(100% - '+(leftNav.clientWidth)+'px)');
    //     }
    //     //dashboard.style.marginTop='70px'
    //     //header.style.padding='0 5px'

    //   }else{

    //     header.style.position='revert';
    //     header.style.height='105px';
    //     //dashboard.style.marginTop='105px'
    //     header.style.opacity= 'revert'
    //     //header.style.padding='revert'
    //     if(leftNav){
    //       header.style.width='revert'
    //       }
    //   }
    // })
  }
  private init() {
  }

  toggleMenu(){
    this.menuCtrl.toggle('main-menu');
  }

  changeOrg(selection: DropdownValueInterface) {
    this.selectedOrg = selection;
    AppStorage.setItem('selected-org', JSON.stringify(this.orgsMap[this.selectedOrg.value]));
    location.reload();
  }

  openDashboardAlerts(sensorType: SensorTypes) {
    this.appStateService.selectSensorType(sensorType).subscribe(() => {
      this.router.navigate(['/dashboard/alerts']);
    });
  }
  ngOnDestroy(): void {
      this.sub.unsubscribe();
  }
}
