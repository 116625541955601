import {Component, OnInit} from '@angular/core';
import {first} from 'rxjs/operators';
import {AppStateService} from '../../../../services/app-state.service';
import {GoogleMapsService} from '../../../../../../../../projects/web/src/app/core/api/services/google-maps.service';

@Component({
  selector: 'app-nutrient-shield-monitoring',
  templateUrl: './nutrient-shield-monitoring.component.html',
  styleUrls: ['./nutrient-shield-monitoring.component.scss']
})
export class NutrientShieldMonitoringComponent implements OnInit {
  mapData;
  isMapLoaded: boolean = false;
  options: google.maps.MapOptions = {
    center: {lat: 35.0265, lng: -118.955},
    zoom: 4
  };

  constructor(
    private appStateService: AppStateService,
    private googleMapsService: GoogleMapsService) {
  }

  async ngOnInit() {
    await this.googleMapsService.initMap();
    this.isMapLoaded = true;
    this.appStateService.getAppState().pipe(first())
      .subscribe(appState => {
        this.mapData = {  
          markers: [],
          locationCount: true,
          hideMapHeader: true,
        };
        Object.values(appState.locations).map((loc: any) => {
          this.mapData.markers.push({
            id: loc.location_name,
            latitude: loc.latitude,
            longitude: loc.longitude,
            TypeOfSensor: 'Iceberg',
            current_battery_level: loc.current_battery_level,
            current_height: loc.current_height,
            current_volume: loc.current_volume,
            last_sync_timestamp: loc.last_sync_timestamp,
            runtime: loc.runtime,
            tank_height: loc.tank_height,
            tank_volume: loc.tank_volume,
            total_measurements: loc.total_measurements,
            volume_percentage: loc.volume_percentage,
            // tooltip: {
            //   title: loc.location_name,
            //   subtitle: 'Current volume:' + this.formatResultValuePipe.transform(loc.tank_volume, 'volume')
            // }
          });
        });
      });
  }

}
