/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Alerts_paginated } from '../models/alerts-_paginated';
import { Alert } from '../models/alert';
import { Alert_solve } from '../models/alert-_solve';

/**
 * Alert related operations
 */
@Injectable({
  providedIn: 'root',
})
class AlertsService extends __BaseService {
  static readonly getAlertsPaginatedPath = '/alerts';
  static readonly getAlertsCsvPath = '/alerts/csv';
  static readonly GetAlertPath = '/alerts/{sensor_type}/{alert_id}';
  static readonly SolveAlertPath = '/alerts/{sensor_type}/{alert_id}';
  static readonly GetAlertsPath = '/alerts/{sensor_type}/{page}/{per_page}';
  static readonly GetAlertsByStatusPath = '/alerts/{sensor_type}/{status}';
  static readonly GetAlertsByStatusAndSeverityPath = '/alerts/{sensor_type}/{status}/{warning_level}';
  static readonly GetPaginatedAlertsByStatusAndSeverityPath = '/alerts/{sensor_type}/{status}/{warning_level}/{page}/{per_page}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `AlertsService.GetAlertsPaginatedParams` containing the following parameters:
   *
   * - `ids`: List of alert ids
   *
   * - `organization_ids`: List of organization ids
   *
   * - `sensor_ids`: List of sensor ids
   *
   * - `water_source_ids`: List of water source ids
   *
   * - `test_numbers`: List of test numbers
   *
   * - `operating_statuses`: List of operating statuses (0=End of line, 1=Operational, 2=Maintenance, 3=Decommissioned, 4=Service Ops)
   *
   * - `alert_codes`: Alert codes: conductivity, 1002, continuous, etc
   *
   * - `alert_states`: Alert states: on, off, high, moderate, low, start, stop
   *
   * - `alert_values`: Alert values: 522.5
   *
   * - `generated_timestamp__min`: Min (inclusive) generated timestamp (ISO 8601)
   *
   * - `generated_timestamp__max`: Max (inclusive) generated timestamp (ISO 8601
   *
   * - `resolved`: True if the alert has been resolved
   *
   * - `resolved_timestamp__min`: Min (inclusive) resolved timestamp (ISO 8601
   *
   * - `resolved_timestamp__max`: Max (inclusive) resolved timestamp (ISO 8601
   *
   * - `warning_levels`: 1=good, 2=poor, 3=bad, 4=ugly
   *
   * - `categories`: 1=customer, 2=internal only
   *
   * - `multi_match`: Search alert_string and comment for any number of keywords
   *
   * - `time_zone`: Valid values are ISO 8601 UTC offsets, such as +01:00 or -08:00
   *
   * - `sorts`: Examples: "generated_timestamp desc", "generated_timestamp asc"
   *
   * - `include_sensor`: Embed related sensor record
   *
   * - `include_water_source`: Embed related water source record
   *
   * - `scroll_id`: PAGINATION: Pass to get next page for result sets over 10000
   *
   * - `per_page`: PAGINATION: Number of results to return (10000 max)
   *
   * - `page`: PAGINATION: Page number for result sets under 10000
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getAlertsPaginatedResponse(params: AlertsService.GetAlertsPaginatedParams): __Observable<__StrictHttpResponse<Alerts_paginated>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    (params.organizationIds || []).forEach(val => {if (val != null) __params = __params.append('organization_ids', val.toString())});
    (params.sensorIds || []).forEach(val => {if (val != null) __params = __params.append('sensor_ids', val.toString())});
    (params.waterSourceIds || []).forEach(val => {if (val != null) __params = __params.append('water_source_ids', val.toString())});
    (params.testNumbers || []).forEach(val => {if (val != null) __params = __params.append('test_numbers', val.toString())});
    (params.operatingStatuses || []).forEach(val => {if (val != null) __params = __params.append('operating_statuses', val.toString())});
    (params.alertCodes || []).forEach(val => {if (val != null) __params = __params.append('alert_codes', val.toString())});
    (params.alertStates || []).forEach(val => {if (val != null) __params = __params.append('alert_states', val.toString())});
    (params.alertValues || []).forEach(val => {if (val != null) __params = __params.append('alert_values', val.toString())});
    if (params.generatedTimestampMin != null) __params = __params.set('generated_timestamp__min', params.generatedTimestampMin.toString());
    if (params.generatedTimestampMax != null) __params = __params.set('generated_timestamp__max', params.generatedTimestampMax.toString());
    if (params.resolved != null) __params = __params.set('resolved', params.resolved.toString());
    if (params.resolvedTimestampMin != null) __params = __params.set('resolved_timestamp__min', params.resolvedTimestampMin.toString());
    if (params.resolvedTimestampMax != null) __params = __params.set('resolved_timestamp__max', params.resolvedTimestampMax.toString());
    (params.warningLevels || []).forEach(val => {if (val != null) __params = __params.append('warning_levels', val.toString())});
    (params.categories || []).forEach(val => {if (val != null) __params = __params.append('categories', val.toString())});
    if (params.multiMatch != null) __params = __params.set('multi_match', params.multiMatch.toString());
    if (params.timeZone != null) __params = __params.set('time_zone', params.timeZone.toString());
    (params.sorts || []).forEach(val => {if (val != null) __params = __params.append('sorts', val.toString())});
    if (params.includeSensor != null) __params = __params.set('include_sensor', params.includeSensor.toString());
    if (params.includeWaterSource != null) __params = __params.set('include_water_source', params.includeWaterSource.toString());
    if (params.scrollId != null) __params = __params.set('scroll_id', params.scrollId.toString());
    if (params.perPage != null) __params = __params.set('per_page', params.perPage.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/alerts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Alerts_paginated>;
      })
    );
  }
  /**
   * @param params The `AlertsService.GetAlertsPaginatedParams` containing the following parameters:
   *
   * - `ids`: List of alert ids
   *
   * - `organization_ids`: List of organization ids
   *
   * - `sensor_ids`: List of sensor ids
   *
   * - `water_source_ids`: List of water source ids
   *
   * - `test_numbers`: List of test numbers
   *
   * - `operating_statuses`: List of operating statuses (0=End of line, 1=Operational, 2=Maintenance, 3=Decommissioned, 4=Service Ops)
   *
   * - `alert_codes`: Alert codes: conductivity, 1002, continuous, etc
   *
   * - `alert_states`: Alert states: on, off, high, moderate, low, start, stop
   *
   * - `alert_values`: Alert values: 522.5
   *
   * - `generated_timestamp__min`: Min (inclusive) generated timestamp (ISO 8601)
   *
   * - `generated_timestamp__max`: Max (inclusive) generated timestamp (ISO 8601
   *
   * - `resolved`: True if the alert has been resolved
   *
   * - `resolved_timestamp__min`: Min (inclusive) resolved timestamp (ISO 8601
   *
   * - `resolved_timestamp__max`: Max (inclusive) resolved timestamp (ISO 8601
   *
   * - `warning_levels`: 1=good, 2=poor, 3=bad, 4=ugly
   *
   * - `categories`: 1=customer, 2=internal only
   *
   * - `multi_match`: Search alert_string and comment for any number of keywords
   *
   * - `time_zone`: Valid values are ISO 8601 UTC offsets, such as +01:00 or -08:00
   *
   * - `sorts`: Examples: "generated_timestamp desc", "generated_timestamp asc"
   *
   * - `include_sensor`: Embed related sensor record
   *
   * - `include_water_source`: Embed related water source record
   *
   * - `scroll_id`: PAGINATION: Pass to get next page for result sets over 10000
   *
   * - `per_page`: PAGINATION: Number of results to return (10000 max)
   *
   * - `page`: PAGINATION: Page number for result sets under 10000
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getAlertsPaginated(params: AlertsService.GetAlertsPaginatedParams): __Observable<Alerts_paginated> {
    return this.getAlertsPaginatedResponse(params).pipe(
      __map(_r => _r.body as Alerts_paginated)
    );
  }

  /**
   * @param params The `AlertsService.GetAlertsCsvParams` containing the following parameters:
   *
   * - `ids`: List of alert ids
   *
   * - `organization_ids`: List of organization ids
   *
   * - `sensor_ids`: List of sensor ids
   *
   * - `water_source_ids`: List of water source ids
   *
   * - `test_numbers`: List of test numbers
   *
   * - `operating_statuses`: List of operating statuses (0=End of line, 1=Operational, 2=Maintenance, 3=Decommissioned, 4=Service Ops)
   *
   * - `alert_codes`: Alert codes: conductivity, 1002, continuous, etc
   *
   * - `alert_states`: Alert states: on, off, high, moderate, low, start, stop
   *
   * - `alert_values`: Alert values: 522.5
   *
   * - `generated_timestamp__min`: Min (inclusive) generated timestamp (ISO 8601)
   *
   * - `generated_timestamp__max`: Max (inclusive) generated timestamp (ISO 8601
   *
   * - `resolved`: True if the alert has been resolved
   *
   * - `resolved_timestamp__min`: Min (inclusive) resolved timestamp (ISO 8601
   *
   * - `resolved_timestamp__max`: Max (inclusive) resolved timestamp (ISO 8601
   *
   * - `warning_levels`: 1=good, 2=poor, 3=bad, 4=ugly
   *
   * - `categories`: 1=customer, 2=internal only
   *
   * - `multi_match`: Search alert_string and comment for any number of keywords
   *
   * - `time_zone`: Valid values are ISO 8601 UTC offsets, such as +01:00 or -08:00
   */
  getAlertsCsvResponse(params: AlertsService.GetAlertsCsvParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    (params.organizationIds || []).forEach(val => {if (val != null) __params = __params.append('organization_ids', val.toString())});
    (params.sensorIds || []).forEach(val => {if (val != null) __params = __params.append('sensor_ids', val.toString())});
    (params.waterSourceIds || []).forEach(val => {if (val != null) __params = __params.append('water_source_ids', val.toString())});
    (params.testNumbers || []).forEach(val => {if (val != null) __params = __params.append('test_numbers', val.toString())});
    (params.operatingStatuses || []).forEach(val => {if (val != null) __params = __params.append('operating_statuses', val.toString())});
    (params.alertCodes || []).forEach(val => {if (val != null) __params = __params.append('alert_codes', val.toString())});
    (params.alertStates || []).forEach(val => {if (val != null) __params = __params.append('alert_states', val.toString())});
    (params.alertValues || []).forEach(val => {if (val != null) __params = __params.append('alert_values', val.toString())});
    if (params.generatedTimestampMin != null) __params = __params.set('generated_timestamp__min', params.generatedTimestampMin.toString());
    if (params.generatedTimestampMax != null) __params = __params.set('generated_timestamp__max', params.generatedTimestampMax.toString());
    if (params.resolved != null) __params = __params.set('resolved', params.resolved.toString());
    if (params.resolvedTimestampMin != null) __params = __params.set('resolved_timestamp__min', params.resolvedTimestampMin.toString());
    if (params.resolvedTimestampMax != null) __params = __params.set('resolved_timestamp__max', params.resolvedTimestampMax.toString());
    (params.warningLevels || []).forEach(val => {if (val != null) __params = __params.append('warning_levels', val.toString())});
    (params.categories || []).forEach(val => {if (val != null) __params = __params.append('categories', val.toString())});
    if (params.multiMatch != null) __params = __params.set('multi_match', params.multiMatch.toString());
    if (params.timeZone != null) __params = __params.set('time_zone', params.timeZone.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/alerts/csv`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `AlertsService.GetAlertsCsvParams` containing the following parameters:
   *
   * - `ids`: List of alert ids
   *
   * - `organization_ids`: List of organization ids
   *
   * - `sensor_ids`: List of sensor ids
   *
   * - `water_source_ids`: List of water source ids
   *
   * - `test_numbers`: List of test numbers
   *
   * - `operating_statuses`: List of operating statuses (0=End of line, 1=Operational, 2=Maintenance, 3=Decommissioned, 4=Service Ops)
   *
   * - `alert_codes`: Alert codes: conductivity, 1002, continuous, etc
   *
   * - `alert_states`: Alert states: on, off, high, moderate, low, start, stop
   *
   * - `alert_values`: Alert values: 522.5
   *
   * - `generated_timestamp__min`: Min (inclusive) generated timestamp (ISO 8601)
   *
   * - `generated_timestamp__max`: Max (inclusive) generated timestamp (ISO 8601
   *
   * - `resolved`: True if the alert has been resolved
   *
   * - `resolved_timestamp__min`: Min (inclusive) resolved timestamp (ISO 8601
   *
   * - `resolved_timestamp__max`: Max (inclusive) resolved timestamp (ISO 8601
   *
   * - `warning_levels`: 1=good, 2=poor, 3=bad, 4=ugly
   *
   * - `categories`: 1=customer, 2=internal only
   *
   * - `multi_match`: Search alert_string and comment for any number of keywords
   *
   * - `time_zone`: Valid values are ISO 8601 UTC offsets, such as +01:00 or -08:00
   */
  getAlertsCsv(params: AlertsService.GetAlertsCsvParams): __Observable<null> {
    return this.getAlertsCsvResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `AlertsService.GetAlertParams` containing the following parameters:
   *
   * - `sensor_type`: 1=wave | 2=shield | 3=iceberg
   *
   * - `alert_id`: Alert ID
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetAlertResponse(params: AlertsService.GetAlertParams): __Observable<__StrictHttpResponse<Alert>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/alerts/${encodeURIComponent(String(params.sensorType))}/${encodeURIComponent(String(params.alertId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Alert>;
      })
    );
  }
  /**
   * @param params The `AlertsService.GetAlertParams` containing the following parameters:
   *
   * - `sensor_type`: 1=wave | 2=shield | 3=iceberg
   *
   * - `alert_id`: Alert ID
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetAlert(params: AlertsService.GetAlertParams): __Observable<Alert> {
    return this.GetAlertResponse(params).pipe(
      __map(_r => _r.body as Alert)
    );
  }

  /**
   * @param params The `AlertsService.SolveAlertParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `sensor_type`: 1=wave | 2=shield | 3=iceberg
   *
   * - `alert_id`: Alert ID
   */
  SolveAlertResponse(params: AlertsService.SolveAlertParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;


    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/alerts/${encodeURIComponent(String(params.sensorType))}/${encodeURIComponent(String(params.alertId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `AlertsService.SolveAlertParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `sensor_type`: 1=wave | 2=shield | 3=iceberg
   *
   * - `alert_id`: Alert ID
   */
  SolveAlert(params: AlertsService.SolveAlertParams): __Observable<null> {
    return this.SolveAlertResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `AlertsService.GetAlertsParams` containing the following parameters:
   *
   * - `sensor_type`: 1=wave | 2=shield | 3=iceberg
   *
   * - `page`: Page number
   *
   * - `per_page`: Results per page
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetAlertsResponse(params: AlertsService.GetAlertsParams): __Observable<__StrictHttpResponse<Alerts_paginated>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/alerts/${encodeURIComponent(String(params.sensorType))}/${encodeURIComponent(String(params.page))}/${encodeURIComponent(String(params.perPage))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Alerts_paginated>;
      })
    );
  }
  /**
   * @param params The `AlertsService.GetAlertsParams` containing the following parameters:
   *
   * - `sensor_type`: 1=wave | 2=shield | 3=iceberg
   *
   * - `page`: Page number
   *
   * - `per_page`: Results per page
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetAlerts(params: AlertsService.GetAlertsParams): __Observable<Alerts_paginated> {
    return this.GetAlertsResponse(params).pipe(
      __map(_r => _r.body as Alerts_paginated)
    );
  }

  /**
   * @param params The `AlertsService.GetAlertsByStatusParams` containing the following parameters:
   *
   * - `sensor_type`: 1=wave | 2=shield | 3=iceberg
   *
   * - `status`: all | archived | live
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetAlertsByStatusResponse(params: AlertsService.GetAlertsByStatusParams): __Observable<__StrictHttpResponse<Array<Alert>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/alerts/${encodeURIComponent(String(params.sensorType))}/${encodeURIComponent(String(params.status))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Alert>>;
      })
    );
  }
  /**
   * @param params The `AlertsService.GetAlertsByStatusParams` containing the following parameters:
   *
   * - `sensor_type`: 1=wave | 2=shield | 3=iceberg
   *
   * - `status`: all | archived | live
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetAlertsByStatus(params: AlertsService.GetAlertsByStatusParams): __Observable<Array<Alert>> {
    return this.GetAlertsByStatusResponse(params).pipe(
      __map(_r => _r.body as Array<Alert>)
    );
  }

  /**
   * @param params The `AlertsService.GetAlertsByStatusAndSeverityParams` containing the following parameters:
   *
   * - `sensor_type`: 1=wave | 2=shield | 3=iceberg
   *
   * - `status`: all | archived | live
   *
   * - `warning_level`: 1=good | 2=poor | 3=bad | 4=ugly | "all"
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetAlertsByStatusAndSeverityResponse(params: AlertsService.GetAlertsByStatusAndSeverityParams): __Observable<__StrictHttpResponse<Array<Alert>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/alerts/${encodeURIComponent(String(params.sensorType))}/${encodeURIComponent(String(params.status))}/${encodeURIComponent(String(params.warningLevel))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Alert>>;
      })
    );
  }
  /**
   * @param params The `AlertsService.GetAlertsByStatusAndSeverityParams` containing the following parameters:
   *
   * - `sensor_type`: 1=wave | 2=shield | 3=iceberg
   *
   * - `status`: all | archived | live
   *
   * - `warning_level`: 1=good | 2=poor | 3=bad | 4=ugly | "all"
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetAlertsByStatusAndSeverity(params: AlertsService.GetAlertsByStatusAndSeverityParams): __Observable<Array<Alert>> {
    return this.GetAlertsByStatusAndSeverityResponse(params).pipe(
      __map(_r => _r.body as Array<Alert>)
    );
  }

  /**
   * @param params The `AlertsService.GetPaginatedAlertsByStatusAndSeverityParams` containing the following parameters:
   *
   * - `sensor_type`: 1=wave | 2=shield | 3=iceberg
   *
   * - `status`: all | archived | live
   *
   * - `warning_level`: 1=good | 2=poor | 3=bad | 4=ugly | "all"
   *
   * - `page`: Page number
   *
   * - `per_page`: Results per page
   *
   * - `search`: Examples: "sensor error"
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetPaginatedAlertsByStatusAndSeverityResponse(params: AlertsService.GetPaginatedAlertsByStatusAndSeverityParams): __Observable<__StrictHttpResponse<Alerts_paginated>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;





    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/alerts/${encodeURIComponent(String(params.sensorType))}/${encodeURIComponent(String(params.status))}/${encodeURIComponent(String(params.warningLevel))}/${encodeURIComponent(String(params.page))}/${encodeURIComponent(String(params.perPage))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Alerts_paginated>;
      })
    );
  }
  /**
   * @param params The `AlertsService.GetPaginatedAlertsByStatusAndSeverityParams` containing the following parameters:
   *
   * - `sensor_type`: 1=wave | 2=shield | 3=iceberg
   *
   * - `status`: all | archived | live
   *
   * - `warning_level`: 1=good | 2=poor | 3=bad | 4=ugly | "all"
   *
   * - `page`: Page number
   *
   * - `per_page`: Results per page
   *
   * - `search`: Examples: "sensor error"
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetPaginatedAlertsByStatusAndSeverity(params: AlertsService.GetPaginatedAlertsByStatusAndSeverityParams): __Observable<Alerts_paginated> {
    return this.GetPaginatedAlertsByStatusAndSeverityResponse(params).pipe(
      __map(_r => _r.body as Alerts_paginated)
    );
  }
}

module AlertsService {

  /**
   * Parameters for getAlertsPaginated
   */
  export interface GetAlertsPaginatedParams {

    /**
     * List of alert ids
     */
    ids?: Array<string>;

    /**
     * List of organization ids
     */
    organizationIds?: Array<number>;

    /**
     * List of sensor ids
     */
    sensorIds?: Array<number>;

    /**
     * List of water source ids
     */
    waterSourceIds?: Array<number>;

    /**
     * List of test numbers
     */
    testNumbers?: Array<number>;

    /**
     * List of operating statuses (0=End of line, 1=Operational, 2=Maintenance, 3=Decommissioned, 4=Service Ops)
     */
    operatingStatuses?: Array<number>;

    /**
     * Alert codes: conductivity, 1002, continuous, etc
     */
    alertCodes?: Array<string>;

    /**
     * Alert states: on, off, high, moderate, low, start, stop
     */
    alertStates?: Array<string>;

    /**
     * Alert values: 522.5
     */
    alertValues?: Array<string>;

    /**
     * Min (inclusive) generated timestamp (ISO 8601)
     */
    generatedTimestampMin?: string;

    /**
     * Max (inclusive) generated timestamp (ISO 8601
     */
    generatedTimestampMax?: string;

    /**
     * True if the alert has been resolved
     */
    resolved?: boolean;

    /**
     * Min (inclusive) resolved timestamp (ISO 8601
     */
    resolvedTimestampMin?: string;

    /**
     * Max (inclusive) resolved timestamp (ISO 8601
     */
    resolvedTimestampMax?: string;

    /**
     * 1=good, 2=poor, 3=bad, 4=ugly
     */
    warningLevels?: Array<number>;

    /**
     * 1=customer, 2=internal only
     */
    categories?: Array<number>;

    /**
     * Search alert_string and comment for any number of keywords
     */
    multiMatch?: string;

    /**
     * Valid values are ISO 8601 UTC offsets, such as +01:00 or -08:00
     */
    timeZone?: string;

    /**
     * Examples: "generated_timestamp desc", "generated_timestamp asc"
     */
    sorts?: Array<string>;

    /**
     * Embed related sensor record
     */
    includeSensor?: boolean;

    /**
     * Embed related water source record
     */
    includeWaterSource?: boolean;

    /**
     * PAGINATION: Pass to get next page for result sets over 10000
     */
    scrollId?: string;

    /**
     * PAGINATION: Number of results to return (10000 max)
     */
    perPage?: number;

    /**
     * PAGINATION: Page number for result sets under 10000
     */
    page?: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for getAlertsCsv
   */
  export interface GetAlertsCsvParams {

    /**
     * List of alert ids
     */
    ids?: Array<string>;

    /**
     * List of organization ids
     */
    organizationIds?: Array<number>;

    /**
     * List of sensor ids
     */
    sensorIds?: Array<number>;

    /**
     * List of water source ids
     */
    waterSourceIds?: Array<number>;

    /**
     * List of test numbers
     */
    testNumbers?: Array<number>;

    /**
     * List of operating statuses (0=End of line, 1=Operational, 2=Maintenance, 3=Decommissioned, 4=Service Ops)
     */
    operatingStatuses?: Array<number>;

    /**
     * Alert codes: conductivity, 1002, continuous, etc
     */
    alertCodes?: Array<string>;

    /**
     * Alert states: on, off, high, moderate, low, start, stop
     */
    alertStates?: Array<string>;

    /**
     * Alert values: 522.5
     */
    alertValues?: Array<string>;

    /**
     * Min (inclusive) generated timestamp (ISO 8601)
     */
    generatedTimestampMin?: string;

    /**
     * Max (inclusive) generated timestamp (ISO 8601
     */
    generatedTimestampMax?: string;

    /**
     * True if the alert has been resolved
     */
    resolved?: boolean;

    /**
     * Min (inclusive) resolved timestamp (ISO 8601
     */
    resolvedTimestampMin?: string;

    /**
     * Max (inclusive) resolved timestamp (ISO 8601
     */
    resolvedTimestampMax?: string;

    /**
     * 1=good, 2=poor, 3=bad, 4=ugly
     */
    warningLevels?: Array<number>;

    /**
     * 1=customer, 2=internal only
     */
    categories?: Array<number>;

    /**
     * Search alert_string and comment for any number of keywords
     */
    multiMatch?: string;

    /**
     * Valid values are ISO 8601 UTC offsets, such as +01:00 or -08:00
     */
    timeZone?: string;
  }

  /**
   * Parameters for GetAlert
   */
  export interface GetAlertParams {

    /**
     * 1=wave | 2=shield | 3=iceberg
     */
    sensorType: number;

    /**
     * Alert ID
     */
    alertId: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for SolveAlert
   */
  export interface SolveAlertParams {
    payload: Alert_solve;

    /**
     * 1=wave | 2=shield | 3=iceberg
     */
    sensorType: number;

    /**
     * Alert ID
     */
    alertId: number;
  }

  /**
   * Parameters for GetAlerts
   */
  export interface GetAlertsParams {

    /**
     * 1=wave | 2=shield | 3=iceberg
     */
    sensorType: number;

    /**
     * Page number
     */
    page: number;

    /**
     * Results per page
     */
    perPage: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for GetAlertsByStatus
   */
  export interface GetAlertsByStatusParams {

    /**
     * 1=wave | 2=shield | 3=iceberg
     */
    sensorType: number;

    /**
     * all | archived | live
     */
    status: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for GetAlertsByStatusAndSeverity
   */
  export interface GetAlertsByStatusAndSeverityParams {

    /**
     * 1=wave | 2=shield | 3=iceberg
     */
    sensorType: number;

    /**
     * all | archived | live
     */
    status: string;

    /**
     * 1=good | 2=poor | 3=bad | 4=ugly | "all"
     */
    warningLevel: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for GetPaginatedAlertsByStatusAndSeverity
   */
  export interface GetPaginatedAlertsByStatusAndSeverityParams {

    /**
     * 1=wave | 2=shield | 3=iceberg
     */
    sensorType: number;

    /**
     * all | archived | live
     */
    status: string;

    /**
     * 1=good | 2=poor | 3=bad | 4=ugly | "all"
     */
    warningLevel: string;

    /**
     * Page number
     */
    page: number;

    /**
     * Results per page
     */
    perPage: number;

    /**
     * Examples: "sensor error"
     */
    search?: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }
}

export { AlertsService }
