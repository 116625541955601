/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Shield_threshold } from '../models/shield-_threshold';
import { Shield_thresholds } from '../models/shield-_thresholds';

/**
 * Shield thresholds related operations
 */
@Injectable({
  providedIn: 'root',
})
class ShieldThresholdsService extends __BaseService {
  static readonly GetShieldThresholdsPath = '/shield/thresholds';
  static readonly putShieldThresholdsPath = '/shield/thresholds';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ShieldThresholdsService.GetShieldThresholdsParams` containing the following parameters:
   *
   * - `ids`: List of shield threshold ids
   *
   * - `sensor_ids`: List of sensor ids
   *
   * - `water_source_ids`: List of water source ids
   *
   * - `threshold_types`: List of threshold types (1=Internal, 2=External)
   *
   * - `experiments`: List of experiment names
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetShieldThresholdsResponse(params: ShieldThresholdsService.GetShieldThresholdsParams): __Observable<__StrictHttpResponse<Shield_threshold>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    (params.sensorIds || []).forEach(val => {if (val != null) __params = __params.append('sensor_ids', val.toString())});
    (params.waterSourceIds || []).forEach(val => {if (val != null) __params = __params.append('water_source_ids', val.toString())});
    (params.thresholdTypes || []).forEach(val => {if (val != null) __params = __params.append('threshold_types', val.toString())});
    (params.experiments || []).forEach(val => {if (val != null) __params = __params.append('experiments', val.toString())});
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/shield/thresholds`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Shield_threshold>;
      })
    );
  }
  /**
   * @param params The `ShieldThresholdsService.GetShieldThresholdsParams` containing the following parameters:
   *
   * - `ids`: List of shield threshold ids
   *
   * - `sensor_ids`: List of sensor ids
   *
   * - `water_source_ids`: List of water source ids
   *
   * - `threshold_types`: List of threshold types (1=Internal, 2=External)
   *
   * - `experiments`: List of experiment names
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetShieldThresholds(params: ShieldThresholdsService.GetShieldThresholdsParams): __Observable<Shield_threshold> {
    return this.GetShieldThresholdsResponse(params).pipe(
      __map(_r => _r.body as Shield_threshold)
    );
  }

  /**
   * @param payload undefined
   */
  putShieldThresholdsResponse(payload: Shield_thresholds): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/shield/thresholds`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param payload undefined
   */
  putShieldThresholds(payload: Shield_thresholds): __Observable<null> {
    return this.putShieldThresholdsResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ShieldThresholdsService {

  /**
   * Parameters for GetShieldThresholds
   */
  export interface GetShieldThresholdsParams {

    /**
     * List of shield threshold ids
     */
    ids?: Array<number>;

    /**
     * List of sensor ids
     */
    sensorIds?: Array<number>;

    /**
     * List of water source ids
     */
    waterSourceIds?: Array<number>;

    /**
     * List of threshold types (1=Internal, 2=External)
     */
    thresholdTypes?: Array<number>;

    /**
     * List of experiment names
     */
    experiments?: Array<string>;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }
}

export { ShieldThresholdsService }
