/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Device_audits } from '../models/device-_audits';
import { Device_audit } from '../models/device-_audit';
import { Device_audit_input } from '../models/device-_audit-_input';

/**
 * Device audit operations
 */
@Injectable({
  providedIn: 'root',
})
class DeviceAuditsService extends __BaseService {
  static readonly getDeviceAuditsPath = '/device_audits';
  static readonly postDeviceAuditsPath = '/device_audits';
  static readonly getDeviceAuditByIdPath = '/device_audits/{audit_id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get list of device audit records
   * @param params The `DeviceAuditsService.GetDeviceAuditsParams` containing the following parameters:
   *
   * - `sensor_ids`: List of sensor IDs to filter by
   *
   * - `sensor_sns`: List of shield device serial numbers to filter by
   *
   * - `serial_numbers`: List of device serial numbers to filter by
   *
   * - `events`: List of event types to filter by
   *
   * - `start_timestamp`: Filter events from this date (ISO 8601 format)
   *
   * - `end_timestamp`: Filter events until this date (ISO 8601 format)
   *
   * - `email_id`: Filter events by creator email
   *
   * - `page`: Page number for pagination
   *
   * - `per_page`: Number of items per page
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getDeviceAuditsResponse(params: DeviceAuditsService.GetDeviceAuditsParams): __Observable<__StrictHttpResponse<Device_audits>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.sensorIds || []).forEach(val => {if (val != null) __params = __params.append('sensor_ids', val.toString())});
    (params.sensorSns || []).forEach(val => {if (val != null) __params = __params.append('sensor_sns', val.toString())});
    (params.serialNumbers || []).forEach(val => {if (val != null) __params = __params.append('serial_numbers', val.toString())});
    (params.events || []).forEach(val => {if (val != null) __params = __params.append('events', val.toString())});
    if (params.startTimestamp != null) __params = __params.set('start_timestamp', params.startTimestamp.toString());
    if (params.endTimestamp != null) __params = __params.set('end_timestamp', params.endTimestamp.toString());
    if (params.emailId != null) __params = __params.set('email_id', params.emailId.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.perPage != null) __params = __params.set('per_page', params.perPage.toString());
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/device_audits`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Device_audits>;
      })
    );
  }
  /**
   * Get list of device audit records
   * @param params The `DeviceAuditsService.GetDeviceAuditsParams` containing the following parameters:
   *
   * - `sensor_ids`: List of sensor IDs to filter by
   *
   * - `sensor_sns`: List of shield device serial numbers to filter by
   *
   * - `serial_numbers`: List of device serial numbers to filter by
   *
   * - `events`: List of event types to filter by
   *
   * - `start_timestamp`: Filter events from this date (ISO 8601 format)
   *
   * - `end_timestamp`: Filter events until this date (ISO 8601 format)
   *
   * - `email_id`: Filter events by creator email
   *
   * - `page`: Page number for pagination
   *
   * - `per_page`: Number of items per page
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getDeviceAudits(params: DeviceAuditsService.GetDeviceAuditsParams): __Observable<Device_audits> {
    return this.getDeviceAuditsResponse(params).pipe(
      __map(_r => _r.body as Device_audits)
    );
  }

  /**
   * Create a new device audit record
   * @param params The `DeviceAuditsService.PostDeviceAuditsParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  postDeviceAuditsResponse(params: DeviceAuditsService.PostDeviceAuditsParams): __Observable<__StrictHttpResponse<Device_audit>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/device_audits`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Device_audit>;
      })
    );
  }
  /**
   * Create a new device audit record
   * @param params The `DeviceAuditsService.PostDeviceAuditsParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  postDeviceAudits(params: DeviceAuditsService.PostDeviceAuditsParams): __Observable<Device_audit> {
    return this.postDeviceAuditsResponse(params).pipe(
      __map(_r => _r.body as Device_audit)
    );
  }

  /**
   * Get a specific device audit record
   * @param params The `DeviceAuditsService.GetDeviceAuditByIdParams` containing the following parameters:
   *
   * - `audit_id`: The audit record identifier
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getDeviceAuditByIdResponse(params: DeviceAuditsService.GetDeviceAuditByIdParams): __Observable<__StrictHttpResponse<Device_audit>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/device_audits/${encodeURIComponent(String(params.auditId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Device_audit>;
      })
    );
  }
  /**
   * Get a specific device audit record
   * @param params The `DeviceAuditsService.GetDeviceAuditByIdParams` containing the following parameters:
   *
   * - `audit_id`: The audit record identifier
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getDeviceAuditById(params: DeviceAuditsService.GetDeviceAuditByIdParams): __Observable<Device_audit> {
    return this.getDeviceAuditByIdResponse(params).pipe(
      __map(_r => _r.body as Device_audit)
    );
  }
}

module DeviceAuditsService {

  /**
   * Parameters for getDeviceAudits
   */
  export interface GetDeviceAuditsParams {

    /**
     * List of sensor IDs to filter by
     */
    sensorIds?: Array<number>;

    /**
     * List of shield device serial numbers to filter by
     */
    sensorSns?: Array<string>;

    /**
     * List of device serial numbers to filter by
     */
    serialNumbers?: Array<string>;

    /**
     * List of event types to filter by
     */
    events?: Array<string>;

    /**
     * Filter events from this date (ISO 8601 format)
     */
    startTimestamp?: string;

    /**
     * Filter events until this date (ISO 8601 format)
     */
    endTimestamp?: string;

    /**
     * Filter events by creator email
     */
    emailId?: string;

    /**
     * Page number for pagination
     */
    page?: number;

    /**
     * Number of items per page
     */
    perPage?: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for postDeviceAudits
   */
  export interface PostDeviceAuditsParams {
    payload: Device_audit_input;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for getDeviceAuditById
   */
  export interface GetDeviceAuditByIdParams {

    /**
     * The audit record identifier
     */
    auditId: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }
}

export { DeviceAuditsService }
