/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiInternalConfiguration as __Configuration } from '../api-internal-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Lid_actuator_devices } from '../models/lid-_actuator-_devices';
import { Lid_actuator_device } from '../models/lid-_actuator-_device';
import { Lid_actuator_device_input } from '../models/lid-_actuator-_device-_input';

/**
 * Lid Actuator Device related operations
 */
@Injectable({
  providedIn: 'root',
})
class LidActuatorDevicesService extends __BaseService {
  static readonly getLidActuatorDevicesPath = '/lid_actuator_devices';
  static readonly postLidActuatorDevicesPath = '/lid_actuator_devices';
  static readonly putLidActuatorDeviceLidActuatorDevicesStringLidActuatorDeviceIdPath = '/lid_actuator_devices/{lid_actuator_device_id}';
  static readonly deleteLidActuatorDeviceLidActuatorDevicesStringLidActuatorDeviceIdPath = '/lid_actuator_devices/{lid_actuator_device_id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get Lid Actuator Devices
   * @param params The `LidActuatorDevicesService.GetLidActuatorDevicesParams` containing the following parameters:
   *
   * - `ids`: List of Lid Actuator Device ids (Serial Number)
   *
   * - `sensor_ids`: List of sensor ids
   *
   * - `shield_device_ids`: List of shield device serial numbers
   *
   * - `assigned`: Include devices with (True)/without (False) parent device
   *
   * - `include_deleted`: Include deleted
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getLidActuatorDevicesResponse(params: LidActuatorDevicesService.GetLidActuatorDevicesParams): __Observable<__StrictHttpResponse<Lid_actuator_devices>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    (params.sensorIds || []).forEach(val => {if (val != null) __params = __params.append('sensor_ids', val.toString())});
    (params.shieldDeviceIds || []).forEach(val => {if (val != null) __params = __params.append('shield_device_ids', val.toString())});
    if (params.assigned != null) __params = __params.set('assigned', params.assigned.toString());
    if (params.includeDeleted != null) __params = __params.set('include_deleted', params.includeDeleted.toString());
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/lid_actuator_devices`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Lid_actuator_devices>;
      })
    );
  }
  /**
   * Get Lid Actuator Devices
   * @param params The `LidActuatorDevicesService.GetLidActuatorDevicesParams` containing the following parameters:
   *
   * - `ids`: List of Lid Actuator Device ids (Serial Number)
   *
   * - `sensor_ids`: List of sensor ids
   *
   * - `shield_device_ids`: List of shield device serial numbers
   *
   * - `assigned`: Include devices with (True)/without (False) parent device
   *
   * - `include_deleted`: Include deleted
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getLidActuatorDevices(params: LidActuatorDevicesService.GetLidActuatorDevicesParams): __Observable<Lid_actuator_devices> {
    return this.getLidActuatorDevicesResponse(params).pipe(
      __map(_r => _r.body as Lid_actuator_devices)
    );
  }

  /**
   * Create Lid Actuator Device
   * @param params The `LidActuatorDevicesService.PostLidActuatorDevicesParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  postLidActuatorDevicesResponse(params: LidActuatorDevicesService.PostLidActuatorDevicesParams): __Observable<__StrictHttpResponse<Lid_actuator_device>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/lid_actuator_devices`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Lid_actuator_device>;
      })
    );
  }
  /**
   * Create Lid Actuator Device
   * @param params The `LidActuatorDevicesService.PostLidActuatorDevicesParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  postLidActuatorDevices(params: LidActuatorDevicesService.PostLidActuatorDevicesParams): __Observable<Lid_actuator_device> {
    return this.postLidActuatorDevicesResponse(params).pipe(
      __map(_r => _r.body as Lid_actuator_device)
    );
  }

  /**
   * Update Lid Actuator Device
   * @param params The `LidActuatorDevicesService.PutLidActuatorDeviceLidActuatorDevicesStringLidActuatorDeviceIdParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `lid_actuator_device_id`: Device Serial Number
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  putLidActuatorDeviceLidActuatorDevicesStringLidActuatorDeviceIdResponse(params: LidActuatorDevicesService.PutLidActuatorDeviceLidActuatorDevicesStringLidActuatorDeviceIdParams): __Observable<__StrictHttpResponse<Lid_actuator_device>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/lid_actuator_devices/${encodeURIComponent(String(params.lidActuatorDeviceId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Lid_actuator_device>;
      })
    );
  }
  /**
   * Update Lid Actuator Device
   * @param params The `LidActuatorDevicesService.PutLidActuatorDeviceLidActuatorDevicesStringLidActuatorDeviceIdParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `lid_actuator_device_id`: Device Serial Number
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  putLidActuatorDeviceLidActuatorDevicesStringLidActuatorDeviceId(params: LidActuatorDevicesService.PutLidActuatorDeviceLidActuatorDevicesStringLidActuatorDeviceIdParams): __Observable<Lid_actuator_device> {
    return this.putLidActuatorDeviceLidActuatorDevicesStringLidActuatorDeviceIdResponse(params).pipe(
      __map(_r => _r.body as Lid_actuator_device)
    );
  }

  /**
   * Delete Lid Actuator Device
   * @param params The `LidActuatorDevicesService.DeleteLidActuatorDeviceLidActuatorDevicesStringLidActuatorDeviceIdParams` containing the following parameters:
   *
   * - `lid_actuator_device_id`: Device Serial Number
   *
   * - `reason`: Reason for deleting the device
   */
  deleteLidActuatorDeviceLidActuatorDevicesStringLidActuatorDeviceIdResponse(params: LidActuatorDevicesService.DeleteLidActuatorDeviceLidActuatorDevicesStringLidActuatorDeviceIdParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.reason != null) __params = __params.set('reason', params.reason.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/lid_actuator_devices/${encodeURIComponent(String(params.lidActuatorDeviceId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete Lid Actuator Device
   * @param params The `LidActuatorDevicesService.DeleteLidActuatorDeviceLidActuatorDevicesStringLidActuatorDeviceIdParams` containing the following parameters:
   *
   * - `lid_actuator_device_id`: Device Serial Number
   *
   * - `reason`: Reason for deleting the device
   */
  deleteLidActuatorDeviceLidActuatorDevicesStringLidActuatorDeviceId(params: LidActuatorDevicesService.DeleteLidActuatorDeviceLidActuatorDevicesStringLidActuatorDeviceIdParams): __Observable<null> {
    return this.deleteLidActuatorDeviceLidActuatorDevicesStringLidActuatorDeviceIdResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module LidActuatorDevicesService {

  /**
   * Parameters for getLidActuatorDevices
   */
  export interface GetLidActuatorDevicesParams {

    /**
     * List of Lid Actuator Device ids (Serial Number)
     */
    ids?: Array<string>;

    /**
     * List of sensor ids
     */
    sensorIds?: Array<number>;

    /**
     * List of shield device serial numbers
     */
    shieldDeviceIds?: Array<string>;

    /**
     * Include devices with (True)/without (False) parent device
     */
    assigned?: boolean;

    /**
     * Include deleted
     */
    includeDeleted?: boolean;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for postLidActuatorDevices
   */
  export interface PostLidActuatorDevicesParams {
    payload: Lid_actuator_device;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for putLidActuatorDeviceLidActuatorDevicesStringLidActuatorDeviceId
   */
  export interface PutLidActuatorDeviceLidActuatorDevicesStringLidActuatorDeviceIdParams {
    payload: Lid_actuator_device_input;

    /**
     * Device Serial Number
     */
    lidActuatorDeviceId: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for deleteLidActuatorDeviceLidActuatorDevicesStringLidActuatorDeviceId
   */
  export interface DeleteLidActuatorDeviceLidActuatorDevicesStringLidActuatorDeviceIdParams {

    /**
     * Device Serial Number
     */
    lidActuatorDeviceId: string;

    /**
     * Reason for deleting the device
     */
    reason?: string;
  }
}

export { LidActuatorDevicesService }
