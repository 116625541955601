/*
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */

import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {ActivationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {Observable, of, Subscription} from 'rxjs';
import {AppState, AppStateService} from '../../services/app-state.service';
import {UiPlatformService} from '../../services/ui-platform.service';
import {SummaryShieldService} from '../sensor-summary/summary-shield.service';
import {SensorSummaryService} from '../../../../../api/src/lib/services/sensor-summary.service';
import {AlertController, MenuController, Platform, ScrollDetail} from '@ionic/angular';
import {UserStorageDataService} from '../../services/userStorageData.service';
import {AppStorage} from '../../utils/AppStorage';
import {AuthService} from '../../services/auth.service';
import {segmentButtons} from './route-data.json';
import {DashboardButtonInterface} from '../../components/sensor-tabs/sensor-tabs.component';

@Component({
  selector: 'lib-main',
  templateUrl: './main.page.html',
  styleUrls: ['./main.page.scss']
})
export class MainPage implements OnInit, OnDestroy {

  public openPage: DashboardButtonInterface;
  public alerts = {orangeAlerts: 0, redAlerts: 0};
  public ready = false;
  public showTabs = true;
  public showMenu = true;
  public showSensorSummary = true;
  public hasSensors = true;
  private subscriptions: Subscription[] = [];
  public pageTitle: string;
  public userData: { roleName: string; };
  public appState: AppState;
  public smallHeader = false;
  public isMenuCollapsed = false;
  public isSmallScreen = false;
  public showFooter = false;
  public segmentButtons: DashboardButtonInterface[] = segmentButtons;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.adjustMenuForScreenSize();
  }

  constructor(
    private appStateService: AppStateService,
    public uiPlatformService: UiPlatformService,
    private router: Router,
    private menuCtrl: MenuController,
    private summaryShieldService: SummaryShieldService,
    private sensorSummaryService: SensorSummaryService,
    public platform: Platform,
    public authService: AuthService,
    private userStorageDataService: UserStorageDataService,
    public alertController: AlertController,
  ) {
    this.subscriptions.push(router.events.pipe(
      filter(event => event instanceof ActivationEnd),
    ).subscribe(event => {
      const url = (event as ActivationEnd).snapshot['_routerState'].url;
      const page = url.split('/').pop();
      this.showFooter = false;
      this.showTabs = !url.startsWith('/home') && url.startsWith('/dashboard');
      if (this.showTabs) {
        this.getPageName(page).subscribe(title => {
          this.pageTitle = title;
        });
      }
    }));
  }

  handleScroll(ev: CustomEvent<ScrollDetail>) {
    this.smallHeader = ev.detail.scrollTop > 100;
    const threshold = 100; // Adjust this value as needed
    const contentElement = document.querySelector('.dashboardRight');
    const contentHeight = contentElement ? contentElement.scrollHeight : 0;
    const scrollTop = ev.detail.scrollTop;
    this.showFooter = scrollTop >= contentHeight - this.platform.height() + threshold;
  }

  adjustMenuForScreenSize() {
    if (window.innerWidth > 991) {
      this.isSmallScreen = false;
    } else {
      this.isMenuCollapsed = true;
      this.isSmallScreen = true;
    }
  }

  ngOnInit() {
    if (this.uiPlatformService.webPlatform) {
      this.adjustMenuForScreenSize();
    }
    this.sensorSummaryService.GetShieldSensorSummary()
      .subscribe(res => {
        if (res.sensors_with_warnings?.length > 0) {
          this.alerts.orangeAlerts = res.sensors_with_warnings?.filter(item => item.warning_level === 3)[0]?.sensor_count || 0;
          this.alerts.redAlerts = res.sensors_with_warnings?.filter(item => item.warning_level === 4)[0]?.sensor_count || 0;
        }
      });
    if (this.uiPlatformService.webPlatform) {
      this.init();
    } else {
      this.initAppStorage();
    }
  }

  async initAppStorage() {
    const userData = await JSON.parse(AppStorage.getItem('user'));
    this.userStorageDataService.updateUserData(userData);
  }

  private init() {
    localStorage.setItem('prevURL', this.router.url);
    this.appStateService.init().subscribe((appState: AppState | null) => {
      // No sensors so just show blank content area
      if (appState === null) {
        this.showTabs = false;
        this.showMenu = false;
        this.showSensorSummary = false;
        this.hasSensors = false;
        this.ready = true;
        return this.router.navigate(['/blank']);
      }
      // Show/hide tabs and sensor summary based on current url
      this.showTabs = this.router.url.startsWith('/dashboard') && !this.router.url.startsWith('/dashboard/dashboard');
      this.showMenu = this.router.url.startsWith('/dashboard');
      this.showSensorSummary = this.router.url.startsWith('/dashboard') && this.router.url !== '/dashboard' && !this.router.url.startsWith('/dashboard/dashboard');
      this.subscriptions.push(this.router.events.pipe(
        filter(event => event instanceof ActivationEnd),
      ).subscribe(event => {
        const url = (<ActivationEnd> event).snapshot['_routerState'].url;
        this.showTabs = url.startsWith('/dashboard') && !url.startsWith('/dashboard/dashboard');
        this.showMenu = url.startsWith('/dashboard') && !url.startsWith('/dashboard/dashboard');
        this.showSensorSummary = url.startsWith('/dashboard') && url !== '/dashboard' && !url.startsWith('/dashboard/dashboard');
      }));
      this.ready = true;
    });
  }

  doRefresh(event: any) {
    if (this.openPage === this.segmentButtons[0]) {
      this.refreshSensorSummary(event);
      return;
    }
    setTimeout(() => {
      event.target.complete();
    }, 2000);
  }

  refreshSensorSummary(event: any) {
    const sub = this.summaryShieldService.triggerReloadSubject
      .subscribe(loading => {
        if (loading === false) {
          event.target.complete();
          sub?.unsubscribe();
        }
      });
    this.summaryShieldService.reloadData();
  }

  getPageName(pageName: any): Observable<any> {
    const page = this.segmentButtons.find(item => item.name === pageName);
    if (page) {
      return of(page.title);
    }
    return of(null);
  }

  pageSelected(page: DashboardButtonInterface) {
    this.openPage = page;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }
}
