/*
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */

import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AppService} from '../../services/legacy-services/app.service';
import {ILoginResponse} from '../..//services/auth.service';
import {SensorTypes} from '../..//models/sensor-types.enum';
import {filter, first} from 'rxjs/operators';
import {AppStorage} from '../..//utils/AppStorage';
import {AppState, AppStateService} from '../..//services/app-state.service';
import {Group_master} from '../../../../../api/src/lib/models/group-_master';
import {Sensor_group_objects} from '../../../../../api/src/lib/models/sensor-_group-_objects';
import {SensorSummaryService} from '../../../../../api/src/lib/services/sensor-summary.service';
import {WaterSourceExtended} from '../..//models/extensions/water-source-extended';
import {DatasheetsService} from '../../../../../api/src/lib/services/datasheets.service';
import { Utils } from '../../utils/utils';
import { UiPlatformService } from '../../services/ui-platform.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { IonSelect, PopoverController } from '@ionic/angular';
import {Observable, of, Subscription} from 'rxjs';
import { Pagination } from '../../../../../api/src/lib/models';
import { AlertsService } from '../../../../../api/src/lib/services';
import { LocationsPopoverComponent } from './modal/locations-popover/locations-popover.component';
export interface DashboardButtonInterface {
  icon: string;
  name: string;
  pageName?: string,
  pullDown?: boolean,
  title: string;
}
@Component({
  selector: 'lib-sensor-tabs',
  templateUrl: './sensor-tabs.component.html',
  styleUrls: ['./sensor-tabs.component.scss']
})

export class SensorTabsComponent implements OnInit {
  @Output() segmentTapped: EventEmitter<any> = new EventEmitter<any>();
  @Input() segmentButtons: DashboardButtonInterface[];

  @Input() pageTitle: string;
  @Input() alertCounts;

  iconName="caret-down";
  dropdownRestrictedPages = ['water-safety','graphical-view','savings','data-correlation']
  customPopoverOptions;
  selectedLocation: WaterSourceExtended;
  selectedPage: DashboardButtonInterface;
  selectedPageName: string;
  isAllowed:boolean = true;
  locations: WaterSourceExtended[] = [];
  //pageTitle = '';
  @ViewChild(IonSelect, {static: true}) select: IonSelect;

  appStateSubscription: Subscription;
  public totalNotifications: any;
  pagination: Pagination;
  currentPage: number;
  pageSize = 3;
  public totalLocations: number = 0;
  public warningCounts: number = 0;
  public offlineCounts: number = 0;

  public selectedSensorType: SensorTypes;
  public selectedGroup: Group_master;

  public locationsDropdownTooltip = true;
  public sensorTypes = Object.values(SensorTypes);
  public enabledSensorTypes = {
    wave: false,
    shield: false,
    iceberg: false
  };

  sensors: WaterSourceExtended[];
  groups: Group_master[];
  groupMappings: Sensor_group_objects[];


  constructor(
    private route: ActivatedRoute,
    public appService: AppService,
    public appStateService: AppStateService,
    private dataSheetService: DatasheetsService,
    private sensorSummaryService: SensorSummaryService,
    public uiPlatformService: UiPlatformService,
    private alertsService: AlertsService,
    private router: Router,
    public popoverController: PopoverController,
    private cdr: ChangeDetectorRef
  ) {
    if(!uiPlatformService.webPlatform) {
      router.events.subscribe((event: any) => {
        //console.log(event);
        if (event instanceof NavigationEnd) {
          const path = event.url;
          const page = path.split("/").pop();
          //this.getPageName(page);
          console.log(page, "page")
          if (this.dropdownRestrictedPages.includes(page)) {
            this.isAllowed = false
          } else {
            this.isAllowed = true
          }
        }
      });
    }
  }
  ngOnInit() {

    this.sensorSummaryService?.GetShieldSensorSummary()
      .subscribe(res => {

        if (res.sensors_with_warnings?.length > 0) {
          this.warningCounts = res.sensors_with_warnings?.filter(item => item.warning_level === 3)[0]?.sensor_count || 0;
          this.offlineCounts = res.sensors_with_warnings?.filter(item => item.warning_level === 4)[0]?.sensor_count || 0;
        }
      });
      if(this.uiPlatformService.webPlatform){
      this.init();
      }else{
        this.mobileInit()
      }
  }
  mobileInit(){
    //console.log('mobileInit', this.route,this.appStateService.getAppState())
   // this.route.params.subscribe((val) => {
      const components = window.location.href.split('/');
      const componentName = components[components.length - 1];

      for (const page of this.segmentButtons) {
        if (page.name === componentName) {
          console.log(page.name,'page.name')
          this.selectedPageName = page.name;
          this.selectedPage = page;
          this.segmentTapped.emit(page);
          break;
        }
      }
   // });
    this.alertsService.getAlertsPaginated({
      page: 1,
      perPage: 3 || 20,
      resolved: false,
      warningLevels: [1,2,3,4]
    })
      .subscribe((res: any) => {
        // console.log('got alerts', res);
        this.totalNotifications = res.pagination.total;
      });
    if(!this.appStateService.getAppState()){
      this.appStateService.init().subscribe(res => {
        this.setSelectedLocationForMobile()
      })
   }else{
      this.setSelectedLocationForMobile()
    }
  }
  setSelectedLocationForMobile(){
    this.appStateSubscription = this.appStateService.getAppState().subscribe(state => {
      console.log('appstate', state)
      if (state.sensorType === SensorTypes.wave) {
        this.locations = state.locations.map(item => {
          if (Object.keys(item).length === 1) {
            return item[Object.keys(item)[0]];
          }
        });
      } else if (state.sensorType === SensorTypes.shield || state.sensorType === SensorTypes.iceberg) {
        this.locations = Object.keys(state.locations).map(key => {
          if (state.locations[key]) {
            return state.locations[key];
          }
        });
      }
      for (const loc of this.locations) {
        if ((state.sensorType === SensorTypes.shield || state.sensorType === SensorTypes.iceberg) && state.location.id === loc.id) {
          this.selectedLocation = loc;
          break;
        } else if (state.sensorType === SensorTypes.wave && state.location[Object.keys(state.location)[0]].location_name === loc.location_name) {
          this.selectedLocation = loc;
          break;
        }
      }
      if (!this.selectedPageName) {
        const components = window.location.href.split('/');
        const componentName = components[components.length - 1];

        for (const page of this.segmentButtons) {
          if (page.name === componentName) {
            this.selectedPageName = page.name;
            this.selectedPage = page;
            this.segmentTapped.emit(page);
            break;
          }
        }
      }
    }) as any;
  }
  selectPage(componentName: string) {
    // const componentName = event.detail.value;
    for (const page of this.segmentButtons) {
      if (page.name === componentName) {
        this.selectedPageName = page.name;
        this.selectedPage = page;
        this.segmentTapped.emit(page);
        this.router.navigateByUrl('/tabs/dashboard/' + this.appStateService.getAppState().value.sensorType + '/' + this.selectedPageName);
        break;
      }
    }
    // console.log(this.selectedPageName, this.selectedPage);
  }

  async showLocationSelection(ev: any) {
      this.iconName="caret-up"

    const popover = await this.popoverController.create({
      component: LocationsPopoverComponent,
      event: ev,
      id: 'locations-popover',
      cssClass: 'locations-popover',
      translucent: true,
      mode:'ios',
      componentProps: {
        locations: this.locations,
        selectedLocation: this.selectedLocation
      },

    });
    popover.onDidDismiss().then(result => {
      this.iconName="caret-down"

      if (result.data) {

        this.setSelectedLocation(result.data);
      }
    });
    return await popover.present();
  }

  setSelectedLocation(location: WaterSourceExtended) {
    this.appStateService.setSelectedLocation(location).subscribe(res => {
    });
  }
  alertsClicked() {
    this.router.navigateByUrl('/alerts');
  }
  private init() {
    setTimeout(() => {
      this.locationsDropdownTooltip = false;
    }, 4000);

    this.appStateService?.getAppState()
      .subscribe((appState: AppState) => {
        if (this.selectedSensorType !== appState?.sensorType) {
          this.selectedLocation = undefined;
        }

        this.selectedSensorType = appState?.sensorType;

        this.sensors = appState?.locations;
        this.groups = appState?.groups;
        this.groupMappings = appState?.groupMappings;
        const filterGroups = this.groupMappings?.map((group) => {
          return group?.group_id;
        });
        if (this.groups && this.groups?.length) {
          this.groups = this.groups?.filter(group => filterGroups.includes(group?.id));
        }
        this.selectedGroup = appState?.group;
        this.selectedLocation = appState?.location;
      });
  }

  public selectSensorType(sensorType: SensorTypes) {
    this.appStateService?.selectSensorType(sensorType)
      .subscribe();
  }

  public downloadDatasheet(sensorType: string) {
    if (sensorType === SensorTypes.wave || sensorType === SensorTypes.shield) {
      const url = this.dataSheetService?.rootUrl + `/datasheets/${encodeURIComponent(String(sensorType))}`
      Utils.download(url);
    }
  }

  public updateSelectedLocation(ev: { group: Group_master, sensor: WaterSourceExtended }) {
    this.selectedLocation = ev.sensor;
    if (ev.group?.group_name) {
      this.appStateService?.setSelectedGroup(ev.group?.group_name);
    }
    this.appStateService?.setSelectedLocation(ev.sensor)
      .pipe(first())
      .subscribe();
  }

  public getSelectedUser(): ILoginResponse {
    const selectedUserData = JSON.parse(AppStorage.getItem('selectedUser'));
    if (selectedUserData !== null && selectedUserData?.ownerId) {
      return selectedUserData?.ownerId;
    } else {
      return JSON.parse(AppStorage.getItem('user'));
    }
  }
  ngOnDestroy(): void {
    if (this.appStateSubscription) {
      this.appStateSubscription.unsubscribe();
    }
  }
}
