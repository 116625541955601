/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiInternalConfiguration as __Configuration } from '../api-internal-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';


/**
 * Scheduler related operations
 */
@Injectable({
  providedIn: 'root',
})
class SchedulerService extends __BaseService {
  static readonly getJobsPath = '/scheduler/jobs';
  static readonly deleteJobsPath = '/scheduler/jobs';
  static readonly deleteJobPath = '/scheduler/jobs/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `SchedulerService.GetJobsParams` containing the following parameters:
   *
   * - `per_page`: PAGINATION: Number of results to return
   *
   * - `page`: PAGINATION: Page number
   */
  getJobsResponse(params: SchedulerService.GetJobsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.perPage != null) __params = __params.set('per_page', params.perPage.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/scheduler/jobs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `SchedulerService.GetJobsParams` containing the following parameters:
   *
   * - `per_page`: PAGINATION: Number of results to return
   *
   * - `page`: PAGINATION: Page number
   */
  getJobs(params: SchedulerService.GetJobsParams): __Observable<null> {
    return this.getJobsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
  deleteJobsResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/scheduler/jobs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  deleteJobs(): __Observable<null> {
    return this.deleteJobsResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  deleteJobResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/scheduler/jobs/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  deleteJob(id: string): __Observable<null> {
    return this.deleteJobResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module SchedulerService {

  /**
   * Parameters for getJobs
   */
  export interface GetJobsParams {

    /**
     * PAGINATION: Number of results to return
     */
    perPage?: number;

    /**
     * PAGINATION: Page number
     */
    page?: number;
  }
}

export { SchedulerService }
