/*
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */

import { Component, OnDestroy, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AppService } from '../../services/legacy-services/app.service';
import { AuthService } from '../../services/auth.service';
import { filter, map, switchMap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { SensorTypes} from '../../models/sensor-types.enum';
import {AppState, AppStateService} from '../../services/app-state.service';
import {DashboardUiStateService} from '../../services/dashboard-ui-state.service';
import * as cloneDeep from 'lodash/cloneDeep';
import {
  OrganizationCustomParamsSettingsCachedService
} from '../../services/organization-customParams-settings.cached.service';
import { UiPlatformService } from '../../services/ui-platform.service';
import { OrganizationSettingsInterface } from '../../models/organization-settings.interface';
import { UserStorageDataService } from '../../services/userStorageData.service';
import { DashboardShieldGuard } from '../../guards/dashboard-shield.guard';

export interface MenuItem {
  title: string;
  icon: string;
  route: string;
  disabled?: boolean;
  visibility:any;
  submenu?: {
    title: string;
    enabled: boolean;
    route: string;
  }[];
}

@Component({
  selector: 'lib-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss'],
})

export class LeftMenuComponent implements OnInit, OnDestroy {
  @Input() showMenu: boolean;
  appStateSub: Subscription;
  appState: AppState;
  public showSubmenu = true;
  public opened: boolean;
  public isExternalLabDataVisible: boolean;
  public isExternalLabDataVisibleForMobile: boolean;
  private subscribers: Subscription[] = [];
  public selectedSensorType: SensorTypes = SensorTypes.shield;

  public deploymentSummary: MenuItem = {
    title: 'Deployment Summary',
    icon: 'locations-icon',
    route: '/dashboard/deployment-summary',
    visibility:true
  };

  public menu: MenuItem[] = [
    {
      title: 'Dashboard',
      icon: 'dashboard-icon',
      route: '/dashboard',
      visibility:'true'
    },
    {
      title: 'Sensors',
      icon: 'page-icon',
      route: '/sensors',
      visibility:'true'
    },
    {
      title: 'Locations',
      icon: 'locations-icon',
      route: '/locations',
      visibility:'true'
    },
    {
      title: 'Predictive Modeler',
      icon: 'locations-icon',
      route: '/predictive-modeller',
      visibility:'true'
    },
    // {
    //   title: 'History View',
    //   icon: 'history-icon',
    //   route: '/history-view',
    //   display: false
    // },
    // {
    //   title: 'Generated Alerts',
    //   icon: 'alerts-icon',
    //   route: null,
    //   display: false
    // },
    // {
    //   title: 'System Health',
    //   icon: 'first-aid-kit-icon',
    //   route: '/system-health',
    //   display: false
    // },
    {
      title: 'About Ketos',
      icon: 'about-icon',
      route: '/about',
      visibility:'true'
    },
    {
      title: 'Support',
      icon: 'support-icon',
      route: '/support',
      visibility:'true'
    }
  ];

  private dashboardMenuWave: MenuItem[] = [
    {
      title: 'Real Time Data',
      icon: 'real-time-data-icon',
      route: '/dashboard/real-time-data',
      visibility: 'true'
    },
    {
      title: 'Graphical View',
      icon: 'graphical-icon',
      route: '/dashboard/graphical-view',
      visibility: 'true'
    },
    {
      title: 'Alerts',
      icon: 'alerts-icon',
      route: '/dashboard/alerts',
      visibility: 'true'
    },
    {
      title: 'Predictive Maintenance',
      icon: 'maintenance-icon',
      route: '/dashboard/predictive-maintenance',
      visibility: 'true'
    },
    {
      title: 'Sustainability',
      icon: 'sustainability-icon',
      route: '/dashboard/sustainability',
      visibility: 'true'
    },
    {
      title: 'Grid Management',
      icon: 'grid-icon',
      route: '/dashboard/grid-management',
      visibility: 'true'
    },
    {
      title: 'Consumer Behavior',
      icon: 'consumer-icon',
      route: '/dashboard/consumer-behavior',
      visibility: 'true'
    },
    {
      title: 'Resource Efficiency',
      icon: 'resource-icon',
      route: '/dashboard/resource-efficiency',
      visibility:'true'
    },
    {
      title: 'Usage Billing',
      icon: 'reporting-icon',
      route: '/dashboard/usage-billing',
      visibility:'true'
    },
    {
      title: 'Reports',
      icon: 'reporting-icon',
      route: '/dashboard/reporting',
      visibility:'true'
    }
  ];
  private dashboardMenuShield: MenuItem[]=[];

  private dashboardMenuIceberg: MenuItem[] = [
    {
      title: 'Real Time Data',
      icon: 'real-time-data-icon',
      route: '/dashboard/real-time-data',
      visibility:'true'
    },
    {
      title: 'Graphical View',
      icon: 'graphical-icon',
      route: '/dashboard/graphical-view',
      visibility:'true'
    },
    {
      title: 'Data Correlation',
      icon: 'chart-double',
      route: '/dashboard/dispense-rate',
      visibility:'true'
    },
    {
      title: 'Alerts',
      icon: 'alerts-icon',
      route: '/dashboard/alerts',
      visibility:'true'

    },
    {
      title: 'Sensors Health',
      icon: 'sensors-health-icon',
      route: '/dashboard/sensors-health',
      visibility:'true',
      disabled: true
    },
    {
      title: 'Map',
      icon: 'map-icon',
      route: '/dashboard/map',
      visibility:'true'
    },
    {
      title: 'Behavioral Analytics',
      icon: 'consumer-icon',
      route: '/dashboard/behavioral-analytics',
      visibility:'true'
    },
    {
      title: 'Savings',
      icon: 'savings-icon',
      route: '/dashboard/savings',
      visibility:'true',
      //disabled: true
    },
    {
      title: 'Reports',
      icon: 'reporting-icon',
      route: '/dashboard/reporting',
      visibility:'true',
      disabled: true
    }
  ];

  externalMenuItem = {
    title: 'External Lab Data',
    icon: 'lab-icon',
    route: '/dashboard/lab-data',
    visibility:'true'
  };

  public dashboardMenu:any = {
    wave:  this.dashboardMenuWave,
    //shield: this.getShieldMenuItems(),
    iceberg: this.dashboardMenuIceberg
  };

  public dashboardPages;
  defaultLeftNavWidth;
  appPages;
  userData;
  isDashboard = false;
  isExpanded:boolean = false;
  forecastFeatureAvailable:boolean = false;
  userVisibility: boolean;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public  appService: AppService,
    public  appStateService: AppStateService,
    public  authService: AuthService,
    public  dashboardUiStateService: DashboardUiStateService,
    private organizationCustomParamsSettingsCachedService: OrganizationCustomParamsSettingsCachedService,
    public  uiPlatformService: UiPlatformService,
    private userStorageDataService: UserStorageDataService,
    private cdr: ChangeDetectorRef
  ) {
    if(!uiPlatformService.webPlatform){

    this.userStorageDataService.userData.subscribe((user: any) => {
      this.appPages = [];
      this.userVisibility = null;
      this.userData = user;
      this.userVisibility = this.orgSettingsVisible();
      this.appPages = this.getAppPages();
      this.dashboardPages = this.getDashboardPages();
    });

    }
  }



  // element: HTMLElement;

  // toggleActive(event:any){
  //   debugger;
  //   event.preventDefault();
  //   if(this.element !== undefined){
  //     this.element.style.backgroundColor = "white";
  //   }
  //   var target = event.currentTarget;
  //   target.style.backgroundColor = "#00b2ff";
  //   this.element = target;
  // }
  public orgSettingsVisible() {
    const role = this.userData?.roleName.toLocaleLowerCase();
    return ['superadmin', 'orgadmin'].indexOf(role) !== -1;
  }
  ngOnInit() {
    this.organizationCustomParamsSettingsCachedService.getCached(false, true)
        .subscribe((data:OrganizationSettingsInterface) => {
          this.isExternalLabDataVisibleForMobile = data.isExternalLabDataEnabled;
          this.forecastFeatureAvailable = data.isForecastEnabled;
      });
    this.organizationCustomParamsSettingsCachedService.subject
    .subscribe(data => {
      this.isExternalLabDataVisible = !data.isExternalLabDataEnabled;
      this.isExternalLabDataVisibleForMobile = data.isExternalLabDataEnabled;
      this.dashboardPages = this.getDashboardPages();
      this.dashboardMenu.shield = cloneDeep(this.getShieldMenuItems());

    });
    if(this.uiPlatformService.webPlatform){
    this.appStateSub =this.appStateService.getAppState()
    .subscribe(appstate => {
      this.appState = appstate
    })
    //this.organizationCustomParamsSettingsCachedService.getCached(false,true)


    // this.organizationCustomParamsSettingsCachedService.subject
    // .subscribe(data => {
    //   // console.log(data,'data')
    //   this.isExternalLabDataVisible = !data.isExternalLabDataEnabled;
    //   this.dashboardMenu.shield = cloneDeep(this.getShieldMenuItems());
    //   //this.dashboardMenuShield = cloneDeep(this.getShieldMenuItems());
    //   // console.log(this.dashboardMenuShield,'this.dashboardMenuShield',this.isExternalLabDataVisible,'this.isExternalLabDataVisible')
    //   if (this.authService.getUser().roleName === 'SuperAdmin') {
    //     this.dashboardMenuWave.unshift(this.deploymentSummary);
    //     this.dashboardMenuShield.unshift(this.deploymentSummary);
    //     this.dashboardMenuIceberg.unshift(this.deploymentSummary);
    //   }


    // });
    if (this.authService.getUser().roleName === 'SuperAdmin') {
      this.dashboardMenuWave.unshift(this.deploymentSummary);
      this.dashboardMenuShield.unshift(this.deploymentSummary);
      this.dashboardMenuIceberg.unshift(this.deploymentSummary);
    }
    this.subscribers.push(this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map(route => route.firstChild),
      switchMap(route => route.data)
    )
      .subscribe((data) => {
        if (data.hasOwnProperty('showSubmenu')) {
          this.showSubmenu = data.showSubmenu;
        }
      }));

    this.subscribers.push(this.appStateService.getAppState().subscribe((appState: AppState) => {
      this.selectedSensorType = appState.sensorType;
      let menuIndex = this.dashboardMenu[this.selectedSensorType].findIndex((menuItem: MenuItem) => {
        return menuItem.route === this.router.url;
      });

      if (menuIndex === -1) {
        menuIndex = this.menu.findIndex((menuItem: MenuItem) => {
          return menuItem.route === this.router.url;
        });

        // if (this.router.url !== '/dashboard') {
        //   this.showSubmenu = false;
        // }
      }

      // if (menuIndex === -1 && this.router.url !== '/users') {
      //    this.router.navigate([this.dashboardMenu[this.selectedSensorType][0].route],{queryParamsHandling:'preserve'});
      // }
    }));
  }
  }

  ngAfterViewInit() {
    this.isExpanded = localStorage.getItem('isExpanded') === 'true' ? true : false
    if (this.uiPlatformService.webPlatform) {
      const element = document.getElementById('sideMenu');
      const rElement = document.getElementById('rightColumn')
      const navLogo = document.getElementById('logo');
      this.defaultLeftNavWidth = element.clientWidth;
      this.cdr.detectChanges();
      if (this.isExpanded) {
        //this.openMenu()
        const expandedElement = document.getElementById('sideMenu');
        setTimeout(() => {
          rElement.style.marginLeft = (expandedElement.clientWidth - this.defaultLeftNavWidth + 24) + 'px';
          navLogo.style.marginLeft = '65px';
          navLogo.style.marginRight = '65px';
        }, 100)
      }
    }
  }
  
  saveMenuPath(route){
    //console.log(route)
    localStorage.setItem('prevURL',route)
  }
  closeMenu(){
    const rElement = document.getElementById('rightColumn')
    rElement.style.marginLeft="24px";

    const navLogo = document.getElementById('logo');
    navLogo.style.marginLeft = '0px';
    navLogo.style.marginRight = '0px';
  }
  public usersListVisible() {
    const role = this.userData?.roleName.toLocaleLowerCase();
    return ['superadmin', 'orgadmin', 'groupadmin', 'businessuser'].indexOf(role) !== -1;
  }
  getAppPages() {
    return [
      {
        title: 'Profile',
        url: '/profile',
        visibility: true,
        icon: 'person'
      },
      {
        title: 'Manage Users',
        url: '/users',
        visibility: this.usersListVisible(),
        icon: 'people'
      },
      {
        title: 'Unit Settings',
        url: '/unit-settings',
        visibility: true,
        icon: 'briefcase'
      },
      {
        title: 'About',
        url: '/about',
        visibility: true,
        icon: 'bookmark'
      },
      {
        title: 'Support',
        url: '/support',
        visibility: true,
        icon: 'call'
      }
    ];
  }
  getDashboardPages() {
    return [
      {
        title: 'Summary',
        canActivate: [DashboardShieldGuard],
        url: '/dashboard/summary',
        visibility: true,
        icon: 'home'
      },
      {
        title: 'Real Time Data',
        canActivate: [DashboardShieldGuard],
        url: '/dashboard/real-time-data',
        visibility: true,
        icon: 'pulse'
      },
      {
        title: 'Graphical View',
        canActivate: [DashboardShieldGuard],
        url: '/dashboard/graphical-view',
        visibility: true,
        icon: 'stats-chart'
      },
      {
        title: 'Start/Schedule Test',
        canActivate: [DashboardShieldGuard],
        url: '/dashboard/start-schedule-test',
        visibility: true,
        icon: 'play-circle'
      },
      {
        title: 'External Lab Data',
        canActivate: [DashboardShieldGuard],
        url: '/dashboard/lab-data',
        visibility: this.isExternalLabDataVisibleForMobile,
        icon: 'pulse'
      },
      {
        title: 'Data Correlation',
        canActivate: [DashboardShieldGuard],
        url: '/dashboard/data-correlation',
        visibility: true,
        icon: 'stats-chart'
      },
      {
        title: 'Regional Water Safety',
        canActivate: [DashboardShieldGuard],
        url: '/dashboard/water-safety',
        visibility: true,
        icon: 'water'
      },
      {
        title: 'Savings',
        canActivate: [DashboardShieldGuard],
        url: '/dashboard/savings',
        visibility: true,
        icon: 'cash'
      },
      {
        title: 'Reports',
        canActivate: [DashboardShieldGuard],
        url: '/dashboard/reporting',
        visibility: true,
        icon: 'copy'
      },
    ];
  }
  getShieldMenuItems() {
    return [
      {
        title: 'Insights',
        icon: 'insights-icon',
        route: '/dashboard/insights',
        visibility: 'true'
      },
    {
      title: 'Real Time Data',
      icon: 'real-time-data-icon',
      route: '/dashboard/real-time-data',
      visibility:'true'
    },
    {
      title: 'Graphical View',
        icon: 'graphical-icon',
      route: '/dashboard/graphical-view',
      visibility:'true',
      isPremium: true
    },
    {
      title: 'External Lab Data',
        icon: 'lab-icon',
      route: '/dashboard/lab-data',
      visibility: this.isExternalLabDataVisible,
      disabled: this.isExternalLabDataVisible,
    },
    {
      title: 'Data Correlation',
        icon: 'chart-double',
      route: '/dashboard/data-correlation',
      visibility:'true'
    },
    {
      title: 'Alerts',
        icon: 'alerts-icon',
      route: '/dashboard/alerts',
      visibility:'true'
    },
    {
      title: 'Start/Schedule Test',
        icon: 'bold-start-icon',
      route: '/dashboard/start-schedule-test',
      visibility:'true'
    },
    // {
    //   title: 'System Data',
    //   icon: 'system-data-icon',
    //   route: '/dashboard/system-data',
    //   visibility:'true'
    // },
    {
      title: 'Regional Water Safety',
        icon: 'planet-earth-icon',
      route: '/dashboard/water-safety',
      visibility:'true'
    },
    {
      title: 'Savings',
        icon: 'savings-icon',
      route: '/dashboard/savings',
      visibility:'true'
    },
    // {
    //   title: 'Early Warning',
    //   icon: 'maintenance-icon',
    //   route: '/dashboard/early-warning',
    //   disabled: true
    // },
    {
      title: 'Reports',
        icon: 'reporting-icon',
      route: '/dashboard/reporting',
      visibility:'true'
    }
    ]
  }
  
  openMenu() {
    localStorage.setItem('isExpanded', this.isExpanded.toString());
    this.dashboardUiStateService.setLeftMenuExpanded(this.isExpanded);
    const element = document.getElementById('sideMenu');
    const rElementRect = document.getElementById('rightColumn').getBoundingClientRect();
    const rElement = document.getElementById('rightColumn');
    const navLogo = document.getElementById('logo');
  
    let top = 0;
    if (this.isExpanded) {
      rElement.style.marginLeft = (element.clientWidth - this.defaultLeftNavWidth + 24) + 'px';
      navLogo.style.marginLeft = '65px';
      navLogo.style.marginRight = '68px';
    } else {
      rElement.style.marginLeft = '24px';
      navLogo.style.marginLeft = '0px';
      navLogo.style.marginRight = '0px';
    }
  
    if (rElementRect.top) {
      top = Math.abs(rElementRect.top) + element.offsetTop;
    }
  }

  ngOnDestroy() {
    this.subscribers.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    if (this.appStateSub) {
      this.appStateSub.unsubscribe();
      this.appStateSub = null;
    }
  }
}
