/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Water_sources } from '../models/water-_sources';
import { Water_sources_input } from '../models/water-_sources-_input';
import { Sensor_setting_inputs } from '../models/sensor-_setting-_inputs';
import { Sensor_settings } from '../models/sensor-_settings';

/**
 * Water source related operations
 */
@Injectable({
  providedIn: 'root',
})
class WaterSourcesService extends __BaseService {
  static readonly getWaterSourcesPath = '/water_sources';
  static readonly putWaterSourcesPath = '/water_sources';
  static readonly putBulkUpdatesOnWaterSourceSettingsPath = '/water_sources/settings';
  static readonly deleteWaterSourcePath = '/water_sources/{water_source_id}';
  static readonly getWaterSourcesSettingsPath = '/water_sources/{water_source_id}/settings';
  static readonly putWaterSourcesSettingsPath = '/water_sources/{water_source_id}/settings';
  static readonly deleteWaterSourcesSettingsPath = '/water_sources/{water_source_id}/settings';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `WaterSourcesService.GetWaterSourcesParams` containing the following parameters:
   *
   * - `ids`: List of water source ids
   *
   * - `plc_lines`: List of plc lines
   *
   * - `sensor_ids`: List of sensor ids
   *
   * - `organization_ids`: List of organization ids
   *
   * - `water_source_names`: List of water source names
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getWaterSourcesResponse(params: WaterSourcesService.GetWaterSourcesParams): __Observable<__StrictHttpResponse<Water_sources>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    (params.plcLines || []).forEach(val => {if (val != null) __params = __params.append('plc_lines', val.toString())});
    (params.sensorIds || []).forEach(val => {if (val != null) __params = __params.append('sensor_ids', val.toString())});
    (params.organizationIds || []).forEach(val => {if (val != null) __params = __params.append('organization_ids', val.toString())});
    (params.waterSourceNames || []).forEach(val => {if (val != null) __params = __params.append('water_source_names', val.toString())});
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/water_sources`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Water_sources>;
      })
    );
  }
  /**
   * @param params The `WaterSourcesService.GetWaterSourcesParams` containing the following parameters:
   *
   * - `ids`: List of water source ids
   *
   * - `plc_lines`: List of plc lines
   *
   * - `sensor_ids`: List of sensor ids
   *
   * - `organization_ids`: List of organization ids
   *
   * - `water_source_names`: List of water source names
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getWaterSources(params: WaterSourcesService.GetWaterSourcesParams): __Observable<Water_sources> {
    return this.getWaterSourcesResponse(params).pipe(
      __map(_r => _r.body as Water_sources)
    );
  }

  /**
   * @param payload undefined
   */
  putWaterSourcesResponse(payload: Water_sources_input): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/water_sources`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param payload undefined
   */
  putWaterSources(payload: Water_sources_input): __Observable<null> {
    return this.putWaterSourcesResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `WaterSourcesService.PutBulkUpdatesOnWaterSourceSettingsParams` containing the following parameters:
   *
   * - `ids`: List of water source ids
   *
   * - `payload`:
   */
  putBulkUpdatesOnWaterSourceSettingsResponse(params: WaterSourcesService.PutBulkUpdatesOnWaterSourceSettingsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    __body = params.payload;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/water_sources/settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `WaterSourcesService.PutBulkUpdatesOnWaterSourceSettingsParams` containing the following parameters:
   *
   * - `ids`: List of water source ids
   *
   * - `payload`:
   */
  putBulkUpdatesOnWaterSourceSettings(params: WaterSourcesService.PutBulkUpdatesOnWaterSourceSettingsParams): __Observable<null> {
    return this.putBulkUpdatesOnWaterSourceSettingsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param water_source_id undefined
   */
  deleteWaterSourceResponse(waterSourceId: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/water_sources/${encodeURIComponent(String(waterSourceId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param water_source_id undefined
   */
  deleteWaterSource(waterSourceId: number): __Observable<null> {
    return this.deleteWaterSourceResponse(waterSourceId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `WaterSourcesService.GetWaterSourcesSettingsParams` containing the following parameters:
   *
   * - `water_source_id`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getWaterSourcesSettingsResponse(params: WaterSourcesService.GetWaterSourcesSettingsParams): __Observable<__StrictHttpResponse<Sensor_settings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/water_sources/${encodeURIComponent(String(params.waterSourceId))}/settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Sensor_settings>;
      })
    );
  }
  /**
   * @param params The `WaterSourcesService.GetWaterSourcesSettingsParams` containing the following parameters:
   *
   * - `water_source_id`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getWaterSourcesSettings(params: WaterSourcesService.GetWaterSourcesSettingsParams): __Observable<Sensor_settings> {
    return this.getWaterSourcesSettingsResponse(params).pipe(
      __map(_r => _r.body as Sensor_settings)
    );
  }

  /**
   * @param params The `WaterSourcesService.PutWaterSourcesSettingsParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `water_source_id`:
   */
  putWaterSourcesSettingsResponse(params: WaterSourcesService.PutWaterSourcesSettingsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/water_sources/${encodeURIComponent(String(params.waterSourceId))}/settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `WaterSourcesService.PutWaterSourcesSettingsParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `water_source_id`:
   */
  putWaterSourcesSettings(params: WaterSourcesService.PutWaterSourcesSettingsParams): __Observable<null> {
    return this.putWaterSourcesSettingsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `WaterSourcesService.DeleteWaterSourcesSettingsParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `water_source_id`:
   */
  deleteWaterSourcesSettingsResponse(params: WaterSourcesService.DeleteWaterSourcesSettingsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/water_sources/${encodeURIComponent(String(params.waterSourceId))}/settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `WaterSourcesService.DeleteWaterSourcesSettingsParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `water_source_id`:
   */
  deleteWaterSourcesSettings(params: WaterSourcesService.DeleteWaterSourcesSettingsParams): __Observable<null> {
    return this.deleteWaterSourcesSettingsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module WaterSourcesService {

  /**
   * Parameters for getWaterSources
   */
  export interface GetWaterSourcesParams {

    /**
     * List of water source ids
     */
    ids?: Array<number>;

    /**
     * List of plc lines
     */
    plcLines?: Array<number>;

    /**
     * List of sensor ids
     */
    sensorIds?: Array<number>;

    /**
     * List of organization ids
     */
    organizationIds?: Array<number>;

    /**
     * List of water source names
     */
    waterSourceNames?: Array<string>;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for putBulkUpdatesOnWaterSourceSettings
   */
  export interface PutBulkUpdatesOnWaterSourceSettingsParams {

    /**
     * List of water source ids
     */
    ids: Array<number>;
    payload: Sensor_setting_inputs;
  }

  /**
   * Parameters for getWaterSourcesSettings
   */
  export interface GetWaterSourcesSettingsParams {
    waterSourceId: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for putWaterSourcesSettings
   */
  export interface PutWaterSourcesSettingsParams {
    payload: Sensor_setting_inputs;
    waterSourceId: number;
  }

  /**
   * Parameters for deleteWaterSourcesSettings
   */
  export interface DeleteWaterSourcesSettingsParams {
    payload: Sensor_setting_inputs;
    waterSourceId: number;
  }
}

export { WaterSourcesService }
