/*
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */

import {Component, ElementRef, HostListener, Input} from '@angular/core';
import * as cloneDeep from 'lodash/cloneDeep';
import {Router} from '@angular/router';
import {AuthService} from '../../../../services/auth.service';
import {AuthUserResponse} from '../../../../services/user.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent {
  @Input()
  smallHeader: boolean = false;

  public userData: AuthUserResponse;
  public userMenuModel: any = {
    display: false,
    data: []
  };
  public usersMenuDisabled = true;
  public orgSettingsDisabled = true;

  @HostListener('document:click', ['$event']) clickout(event) {
    if (this.eRef.nativeElement.contains(event.target)) {
    } else {
      this.fold();
    }
  }

  constructor(
    private authService: AuthService,
    private router: Router,
    private eRef: ElementRef
  ) {
    this.userData = authService.getUser();
    if (this.userData && typeof this.userData.role_id === 'number') {
      this.usersMenuDisabled = this.userData.role_id > 3 && this.userData.role_id !== 4;
      this.orgSettingsDisabled = this.userData.role_id > 2;
    }
    // @TODO: reimplement
    // let body = this.$window.document.querySelector('body');
    // let handler = (event) => {
    //   if (!this.$element[0].contains(event.target)) {
    //     this.closeNotoficationInfo();
    //   }
    //   this.$scope.$evalAsync();
    // };
    // body.addEventListener('click', handler, true);
  }

  init(data) {
    this.userMenuModel.data = cloneDeep(data);
  }

  expandMenu(event) {
    // event.stopPropagation();
    this.userMenuModel.display = !this.userMenuModel.display;
  }

  fold() {
    this.userMenuModel.display = false;
  }

  logout(event) {
    this.authService.logout();
  }

  openLink(event, state) {
    this.fold();
    this.router.navigate([state]);
  }

  settingsPage(event) {
    console.log('go to settings not implemented');
  }
}
