/*
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */

import { Injectable } from '@angular/core';
import { environment } from '../../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GoogleMapsService {
  private apiLoaded = false;
  private resolve: (value?: unknown) => void;
  private reject: (reason?: any) => void;

  constructor() {
    window['initMap'] = () => {
      if (this.resolve) {
        this.resolve();
      }
    };
  }

  initMap(): Promise<void> {
    if (this.apiLoaded) {
      return Promise.resolve();
    }
    
    return new Promise(async (resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.CONSTANTS.googleMapAPiKey}&callback=initMap`;
      script.async = true;
      script.defer = true;
      script.onerror = (error) => {
        if (this.reject) {
          this.reject(error);
        }
      };

      document.head.appendChild(script);
    }).then(() => {
      this.apiLoaded = true;
    });
  }

  loadMarkerCluster() {
    this.apiLoaded = true;
    const script = document.createElement('script');
    script.src = `https://unpkg.com/@googlemaps/markerclustererplus/dist/index.min.js`;
    document.body.appendChild(script);
  }
}